import gql from "graphql-tag";
import {
  auditDetailFragment,
  auditSummaryFragment,
  rwhDetailFragment,
  RwhResultDetailAnonymous,
} from "./fragments";

export const getAllAssociatedCompanies = gql`
  query getAllAssociatedCompanies($username: String!) {
    getAllAssociatedCompanies(input: { username: $username }) {
      orgName
      orgId
    }
  }
`;

export const getAllEmployee = gql`
  query getAllEmployee($username: String!, $role: String) {
    getAllEmployee(input: { username: $username, role: $role }) {
      indId
      email
      firstName
      lastName
      hasUserAccess
      # orgName
      # orgId
      # title
      # role
      # isMember
      # memberCode
      # phoneNumber
      # disabled
    }
  }
`;

export const getFullUserInfo = gql`
  query GetFullUserInfo($username: String!) {
    getFullUserInfo(input: { username: $username }) {
      # indId
      firstName
      lastName
      orgName
      email
      title
      # orgId
      role
      # isMember
      # memberCode
      phoneNumber
      # disabled
    }
  }
`;
export const getUserCompanyAccess = gql`
  query getUserCompanyAccess($username: [String]) {
    getUserCompanyAccess(input: $username) {
      indId
      companyAccess {
        orgName
        orgId
        access
      }
      programAccess
      pageAccess
    }
  }
`;

// Note this will only give list of individuals without CEOAccess
export const updateUserCompanyAccess = gql`
  mutation updateUserCompanyAccess(
    $userAccessInput: [userAccessInput] # $username: String! # $companyAccess: [companyAccessList]
  ) {
    updateUserCompanyAccess(input: $userAccessInput) {
      indId
      success
      nModified
    }
  }
`;

export const getAllEmployeeWithCeoAccess = gql`
  query getAllEmployeeWithCeoAccess($username: String!, $role: String) {
    getAllEmployeeWithCeoAccess(input: { username: $username, role: $role }) {
      indId
      email
      firstName
      lastName
      orgName
      orgId
      title
      role
      isMember
      memberCode
      phoneNumber
      disabled
    }
  }
`;

export const getDisclaimerSigned = gql`
  query getDisclaimerSigned($username: String) {
    getDisclaimerSigned(input: { username: $username })
  }
`;

export const setDisclaimerSigned = gql`
  mutation setDisclaimerSigned($username: String) {
    setDisclaimerSigned(input: { username: $username })
  }
`;

export const USE3yrStatus = gql`
  query USE3yrStatus(
    $manufacturernamerev: [String]
    $programyear: [Int]
    $programname: [String]
    $filters: AnnualTestTypeFilter
  ) {
    USE3yrStatus(
      input: {
        manufacturernamerev: $manufacturernamerev
        programyear: $programyear
        programname: $programname
        filters: $filters
      }
    ) {
      manufacturernamerev
      programname
      completed
      pending
      passed
      failed
      totalFirst
      allTotalFirst
      completionRate
      passRate
      failRate
      allFailRate
      totalRerate
      allParticipantRerate
      avgAllRerate
      rerateRate
      rerateRateOverCompleted
      allParticipantRerateRate
      ParticipatingYear
      Zone
      Description
    }
  }
`;

// Return High Level Summary of First Sample Test Progress
export const firstSampleSummary = gql`
  query FirstTestSummary(
    $manufacturernamerev: [String]
    $programyear: [Int]
    $programname: [String]
    $filters: AnnualTestTypeFilter
  ) {
    firstTestNumber(
      input: {
        manufacturernamerev: $manufacturernamerev
        programyear: $programyear
        programname: $programname
        filters: $filters
      }
    ) {
      completed
      pending
      passed
      failed
      totalFirst
      allTotalFirst
      completionRate
      passRate
      failRate
      allFailRate
      totalRerate
      allParticipantRerate
      avgAllRerate
      rerateRate
      allParticipantRerateRate
    }
  }
`;

// Return Program Breakdown of the First Sample Test Progress Per Each Location
export const locationAllProgramFirstTestSummary = gql`
  query LocationAllProgramFirstTestSummary(
    $manufacturernamerev: [String]
    $programyear: [Int]
    $programname: [String]
  ) {
    locationAllProgramFirstTestNumber(
      input: {
        manufacturernamerev: $manufacturernamerev
        programyear: $programyear
        programname: $programname
      }
    ) {
      location
      programFirstTestNumber {
        programname
        completed
        pending
        passed
        failed
        totalFirst
        completionRate
        passRate
        failRate
        prgmFailRate
        prgmCompletionRate
        prgmFailRate
      }
    }
  }
`;

// Return Program Breakdown of the First Sample Test Progress
export const allProgramFirstTestSummary = gql`
  query AllProgramFirstTestSummary(
    $manufacturernamerev: [String]
    $programyear: [Int]
    $programname: [String]
  ) {
    allProgramFirstTestNumber(
      input: {
        manufacturernamerev: $manufacturernamerev
        programyear: $programyear
        programname: $programname
      }
    ) {
      programname
      completed
      pending
      passed
      failed
      totalFirst
      completionRate
      passRate
      failRate
      prgmFailRate
      prgmCompletionRate
      prgmFailRate
    }
  }
`;

export const failRateHistory = gql`
  query FailRateHistory(
    $manufacturernamerev: [String]
    $programyear: [Int]
    $programname: [String]
    $filters: [Filter]
  ) {
    failRateHistory(
      input: {
        manufacturernamerev: $manufacturernamerev
        programyear: $programyear
        programname: $programname
        filters: $filters
      }
    ) {
      programname
      programyear
      failed
      failRate
      prgmFailRate
    }
  }
`;

// Return Program Breakdown of the First Sample Failure Status
export const allProgramFirstFailSummary = gql`
  query AllProgramFirstTestSummary(
    $manufacturernamerev: [String]
    $programyear: [Int]
  ) {
    allProgramFirstTestNumber(
      input: {
        manufacturernamerev: $manufacturernamerev
        programyear: $programyear
      }
    ) {
      programname
      failed
      #totalFirst
      #passRate
      failRate
      prgmFailRate
    }
  }
`;

// Return Program Breakdown of the First Sample Test Progress
export const allProgramDecisionSummary = gql`
  query DecisionSummary(
    $manufacturernamerev: [String]
    $programyear: [Int]
    $programname: [String]
  ) {
    decisionSummary(
      input: {
        manufacturernamerev: $manufacturernamerev
        programyear: $programyear
        programname: $programname
      }
    ) {
      programname
      firstTestFail
      finallyPass
      rerated
      obsoleted
      pending
      DTC
    }
  }
`;

export const ratingRatio = gql`
  query RatingRatio(
    $manufacturernamerev: [String]
    $programyear: [Int]
    $programname: [String]
    $getOthers: Boolean
  ) {
    RatingRatio(
      input: {
        manufacturernamerev: $manufacturernamerev
        programyear: $programyear
        programname: $programname
        getOthers: $getOthers
      }
    ) {
      programname
      programyear
      ahritestnumber
      manufacturernamerev
      rating
      ratio
      threshold
    }
  }
`;

// rating ratio filter with mfg name input
export const ratingRatioFilter = gql`
  query RatingFilterQuery($manufacturernamerev: [String]) {
    RatingFilterQuery(input: $manufacturernamerev) {
      programname
      filter {
        key
        keyDisplay
        value
        valueDisplay
      }
    }
  }
`;

// rating ratio filter without mfg name input
// export const ratingRatioFilter = gql`
//   query RatingFilterQuery {
//     # ) #   $filters: [Filter] #   $programname: [String] #   $programyear: [Int] #   $manufacturernamerev: [String] # (
//     RatingFilterQuery {
//       #   ) #   } #     getOthers: true #     filters: $filters #     programname: $programname #     programyear: $programyear #     manufacturernamerev: $manufacturernamerev #       input: { #   (
//       programname
//       filter {
//         key
//         keyDisplay
//         value
//         valueDisplay
//       }
//     }
//   }
// `;

export const LocationFilterQuery = gql`
  query LocationFilterQuery(
    $manufacturernamerev: [String] #   $programyear: [Int] #   $programname: [String] #   $filters: [Filter]
  ) {
    LocationFilterQuery(input: $manufacturernamerev) {
      programname
      filter {
        key
        keyDisplay
        value
        valueDisplay
      }
    }
  }
`;

export const auditResultField = gql`
  query RatingFilterQuery {
    AuditResultFieldQuery {
      programname
      results {
        key
        tested
        rated
        result
        ratio
      }
    }
  }
`;

export const uniqueRatingForRatio = gql`
  query UniqueRatingForRatio(
    $manufacturernamerev: [String]
    $programyear: [Int]
    $programname: [String]
  ) {
    UniqueRatingForRatio(
      input: {
        manufacturernamerev: $manufacturernamerev
        programyear: $programyear
        programname: $programname
      }
    )
  }
`;

export const ratingRatioArr = gql`
  query RatingRatioArr(
    $manufacturernamerev: [String]
    $programyear: [Int]
    $programname: [String]
    $getOthers: Boolean
    $filters: [Filter]
  ) {
    RatingRatioArr(
      input: {
        manufacturernamerev: $manufacturernamerev
        programyear: $programyear
        programname: $programname
        getOthers: $getOthers
        filters: $filters
      }
    ) {
      participant
      rating
      ratio
    }
  }
`;

// Return Years of participation
export const participatingYearQuery = gql`
  query ParticipatingYearQuery(
    $manufacturernamerev: [String]
    $programname: [String]
  ) {
    participatingYear(
      input: {
        manufacturernamerev: $manufacturernamerev
        programname: $programname
      }
    ) {
      programyear
    }
  }
`;

// Return programs of participation
export const participatingProgramQuery = gql`
  query ParticipatingProgramQuery(
    $manufacturernamerev: [String]
    $programyear: [Int]
  ) {
    participatingProgram(
      input: {
        manufacturernamerev: $manufacturernamerev
        programyear: $programyear
      }
    ) {
      programname
    }
  }
`;

// Return Children Company
export const childQuery = gql`
  query ChildQuery($parent: String) {
    participantFromParents(input: { parent: $parent }) {
      manufacturernamerev
    }
  }
`;

// Return All Parent Company (only for AHRI staff)
export const allParentsQuery = gql`
  query allParents($programname: [String]) {
    allParents(input: $programname) {
      parent
    }
  }
`;

// Return All Participants in ACS
export const allParticipantsQuery = gql`
  query allParticipants($programname: [String], $programyear: [Int]) {
    allParticipants(
      input: { programname: $programname, programyear: $programyear }
    ) {
      manufacturernamerev
    }
  }
`;

// Return All Audit Summary Data
export const auditsQuery = gql`
  query AuditsQuery {
    ahriTestsAll {
      ...AuditSummary
    }
  }
  ${auditSummaryFragment}
`;

export const auditManufacturerTestResult = gql`
  query AuditQuery(
    $manufacturerName: [String]
    $programyear: [Int]
    $programname: [String]
    $testresult: Boolean
    $filters: TestTypeFilter
    $ahritestnumber: [String]
  ) {
    ahriTestsWithFilters(
      input: {
        manufacturernamerev: $manufacturerName
        programyear: $programyear
        programname: $programname
        testresult: $testresult
        filters: $filters
        ahritestnumber: $ahritestnumber
      }
    ) {
      ...AuditSummary
    }
  }
  ${auditSummaryFragment}
`;

export const AhriTestsWithDecisions = gql`
  query AhriTestsWithDecisions(
    $manufacturerName: [String]
    $programyear: [Int]
    $programname: String
    $testresult: Boolean
    $filters: DecisionEnum
  ) {
    AhriTestsWithDecisions(
      input: {
        manufacturernamerev: $manufacturerName
        programyear: $programyear
        programname: $programname
        testresult: $testresult
        filters: $filters
      }
    ) {
      ...AuditSummary
    }
  }
  ${auditSummaryFragment}
`;
// export const auditManufacturerTestResultNoFilter = gql`
//   query AuditQuery(
//     $manufacturerName: [String]!
//     $programyear: [Int]
//     $programname: [String]
//     $testresult: Boolean
//   ) {
//     ahriTests(
//       input: {
//         manufacturernamerev: $manufacturerName
//         programyear: $programyear
//         programname: $programname
//         testresult: $testresult
//       }
//     ) {
//       ...AuditSummary
//     }
//   }
//   ${auditSummaryFragment}
// `;

// Return Audits after Filter
export const auditQueryManufacturer = gql`
  query AuditQuery(
    $manufacturerName: [String]!
    $programyear: [Int]
    $programname: [String]
    $testresult: Boolean
    $dateFilters: [DateFilter]
    $dateFiltersOR: [DateFilter]
    $stringFilter: [Filter]
    $numericFilter: [NumericFilter]
    $booleanFilter: [BooleanFilter]
    $rangeFilter: [GteLteFilter]
    $onlySentToLab: Boolean
    $onlyWithReferenceId: Boolean
  ) {
    ahriTests(
      input: {
        manufacturernamerev: $manufacturerName
        programyear: $programyear
        programname: $programname
        testresult: $testresult
        dateFilters: $dateFilters
        dateFiltersOR: $dateFiltersOR
        stringFilter: $stringFilter
        numericFilter: $numericFilter
        booleanFilter: $booleanFilter
        rangeFilter: $rangeFilter
        onlySentToLab: $onlySentToLab 
        onlyWithReferenceId: $onlyWithReferenceId
        
      }
    ) {
      ...AuditSummary
    }
  }
  ${auditSummaryFragment}
`;

// // Return Audits after filter and testresult
// // Use in Dashboard-test summary
// export const auditQueryManufacturerTestResultNoFilter = gql`
//   query AuditQuery(
//     $manufacturerName: [String]!
//     $programyear: [Int]
//     $programname: [String]
//     $testresult: Boolean
//   ) {
//     ahriTests(
//       input: {
//         manufacturernamerev: $manufacturerName
//         programyear: $programyear
//         programname: $programname
//         testresult: $testresult
//       }
//     ) {
//       ...AuditSummary
//     }
//   }
//   ${auditSummaryFragment}
// `;

// export const auditQueryManufacturerTestResult = gql`
//   query AuditQuery(
//     $manufacturerName: [String]!
//     $programyear: [Int]
//     $programname: [String]
//     $testresult: Boolean
//     $filters: AnnualTestTypeFilter
//   ) {
//     ahriTestsWithFilters(
//       input: {
//         manufacturernamerev: $manufacturerName
//         programyear: $programyear
//         programname: $programname
//         testresult: $testresult
//         filters: $filters
//       }
//     ) {
//       ...AuditSummary
//     }
//   }
//   ${auditSummaryFragment}
// `;

// const auditQueryTestNumber = gql`
//   query AuditQuery($ahritestnumber: String!) {
//     ahriTest(input: { ahritestnumber: $ahritestnumber }) {
//       ...AuditDetail
//     }
//   }
//   ${auditSummaryFragment}
// `;

// Return Audit Details for an individual audit
export const auditProcessDetail = gql`
  query AuditQuery($ahritestnumber: String!) {
    ahriTest(input: { ahritestnumber: $ahritestnumber }) {
      ...AuditDetail
    }
  }
  ${auditDetailFragment}
`;

// Return RWH Test Results
export const RWHResult = gql`
  query RWHResult($ahritestnumber: String!) {
    RWHResult(input: { ahritestnumber: $ahritestnumber }) {
      ...RwhResultDetail
    }
  }
  ${rwhDetailFragment}
`;

export const ResultReportType = gql`
  query ResultReportType($ahritestnumber: String!) {
    ResultReportType(input: { ahritestnumber: $ahritestnumber }) {
      name
      tested
      rated
      result
      ratio
    }
  }
`;

export const SelectionInfo = gql`
  query SelectionInfo($ahritestnumber: String!) {
    SelectionInfo(input: { ahritestnumber: $ahritestnumber }) {
      key
      keyDisplay
      value
    }
  }
`;

// Return RWH Ratios for all Participants
export const RWHResultAll = gql`
  query RWHResultAll($programname: [String], $programyear: [Int]) {
    RWHResultAll(
      input: { programname: $programname, programyear: $programyear }
    ) {
      ...RwhResultDetailAnonymous
    }
  }
  ${RwhResultDetailAnonymous}
`;

export const predictionList = gql`
  query PredictionListQuery {
    predictionlist {
      programname
      prediction
      enforcementSimulation
    }
  }
`;

export const usacSeerPrediction = gql`
  query usacSeerPrediction($ahritestnumber: String!) {
    usacSeerPrediction(input: { ahritestnumber: $ahritestnumber }) {
      prediction {
        x
        density
      }
      recommendedTicks
      message
      request_id
      keyDisplay
      claimed
      tested
    }
  }
`;

export const usacSeerEnforcePrediction = gql`
  query usacSeerEnforcePrediction($ahritestnumber: String!) {
    usacSeerEnforcePrediction(input: { ahritestnumber: $ahritestnumber }) {
      prediction {
        x
        density
      }
      recommendedTicks
      message
      request_id
      keyDisplay
      mean
    }
  }
`;

export const ushpSeerPrediction = gql`
  query ushpSeerPrediction($ahritestnumber: String!) {
    ushpSeerPrediction(input: { ahritestnumber: $ahritestnumber }) {
      prediction {
        x
        density
      }
      keyDisplay
      recommendedTicks
      message
      request_id
      claimed
      tested
    }
  }
`;

export const ushpSeerEnforcePrediction = gql`
  query ushpSeerEnforcePrediction($ahritestnumber: String!) {
    ushpSeerEnforcePrediction(input: { ahritestnumber: $ahritestnumber }) {
      prediction {
        x
        density
      }
      keyDisplay
      recommendedTicks
      message
      request_id
      claimed
      tested
      mean
    }
  }
`;

export const ushpHspfPrediction = gql`
  query ushpHspfPrediction($ahritestnumber: String!) {
    ushpHspfPrediction(input: { ahritestnumber: $ahritestnumber }) {
      prediction {
        x
        density
      }
      keyDisplay
      recommendedTicks
      message
      request_id
      claimed
      tested
    }
  }
`;

export const ushpHspfEnforcePrediction = gql`
  query ushpHspfEnforcePrediction($ahritestnumber: String!) {
    ushpHspfEnforcePrediction(input: { ahritestnumber: $ahritestnumber }) {
      prediction {
        x
        density
      }
      keyDisplay
      fed_min
      recommendedTicks
      message
      request_id
      claimed
      tested
      mean
    }
  }
`;

export const uleIeerPrediction = gql`
  query uleIeerPrediction($ahritestnumber: String!) {
    uleIeerPrediction(input: { ahritestnumber: $ahritestnumber }) {
      prediction {
        x
        density
      }
      keyDisplay
      fed_min
      recommendedTicks
      message
      request_id
      claimed
      tested
    }
  }
`;

export const uleIeerEnforcePrediction = gql`
  query uleIeerEnforcePrediction($ahritestnumber: String!) {
    uleIeerEnforcePrediction(input: { ahritestnumber: $ahritestnumber }) {
      prediction {
        x
        density
      }
      keyDisplay
      fed_min
      recommendedTicks
      message
      request_id
      claimed
      tested
      mean
    }
  }
`;

export const rwhUefPrediction = gql`
  query rwhUefPrediction($ahritestnumber: String!) {
    rwhUefPrediction(input: { ahritestnumber: $ahritestnumber }) {
      prediction {
        x
        density
      }
      keyDisplay
      fed_min
      recommendedTicks
      message
      request_id
      claimed
      tested
    }
  }
`;

export const rwhUefEnforcePrediction = gql`
  query rwhUefenforcePrediction($ahritestnumber: String!) {
    rwhUefEnforcePrediction(input: { ahritestnumber: $ahritestnumber }) {
      prediction {
        x
        density
      }
      keyDisplay
      fed_min
      recommendedTicks
      message
      request_id
      claimed
      tested
      mean
    }
  }
`;

export const rfrnAfuePrediction = gql`
  query rfrnAfuePrediction($ahritestnumber: String!) {
    rfrnAfuePrediction(input: { ahritestnumber: $ahritestnumber }) {
      prediction {
        x
        density
      }
      keyDisplay
      fed_min
      recommendedTicks
      message
      request_id
      claimed
      tested
    }
  }
`;

export const rfrnAfueEnforcePrediction = gql`
  query rfrnAfueEnforcePrediction($ahritestnumber: String!) {
    rfrnAfueEnforcePrediction(input: { ahritestnumber: $ahritestnumber }) {
      prediction {
        x
        density
      }
      keyDisplay
      fed_min
      recommendedTicks
      message
      request_id
      claimed
      tested
      mean
    }
  }
`;

export const rblrAfuePrediction = gql`
  query rblrAfuePrediction($ahritestnumber: String!) {
    rblrAfuePrediction(input: { ahritestnumber: $ahritestnumber }) {
      prediction {
        x
        density
      }
      keyDisplay
      fed_min
      recommendedTicks
      message
      request_id
      claimed
      tested
    }
  }
`;

export const rblrAfueEnforcePrediction = gql`
  query rblrAfueEnforcePrediction($ahritestnumber: String!) {
    rblrAfueEnforcePrediction(input: { ahritestnumber: $ahritestnumber }) {
      prediction {
        x
        density
      }
      keyDisplay
      fed_min
      recommendedTicks
      message
      request_id
      claimed
      tested
      mean
    }
  }
`;

export const setNotification = gql`
  mutation setNotification(
    $id: Int
    $message: String!
    $participants: [String]
    $expiration: Date!
  ) {
    setNotification(
      input: {
        id: $id
        message: $message
        participants: $participants
        expiration: $expiration
      }
    )
    {
      success
      id
      message
      participants
      expiration
  }
}
`;


export const deleteNotification = gql`
  mutation deleteNotification(
    $id: Int!
    ){
    deleteNotification(input: $id) 
  }
`;



export const getNotification = gql`
query getNotification (
  $manufacturernamerev: [String]
) {
  getNotification(
    input:  $manufacturernamerev
  ) {
      id
      message
      participants
      expiration
  }
}`;

export const getPublicNotification = gql`
query getPublicNotification{
  getPublicNotification {
    id
    message 
    participants
    expiration
  }
 
}`;

export const allParticipants = gql`
query  allParticipants (
  $programname: [String]
  $programyear: [Int]
) {
  allParticipants(
    input: {
        programname: $programname
        programyear: $programyear
    }
  ) {
     manufacturernamerev
  }
}`;

export const getDirectoryFilerItems = gql`
  query getDirectoryFilerItems {
    getDirectoryFilerItems {
      programname
      nextgenVwName
      programId
      filter {
        key
        keyDisplay
        LookupTypeId
        dataType
        imputeRule
        searchRule
        value
        valueDisplay
      }
    }
  }
`;

export const getFieldNames = gql`
  query getFieldName($programname: String!, $columnType: [SqlDataType]) {
    getFieldName(
      input: { programname: $programname, columnType: $columnType }
    ) {
      fieldName
      dataType
    }
  }
`;

export const getRecords = gql`
  query getRecord(
    $programname: String!
    $manufacturernamerev: [String]
    $referencenumber: [Int]
    $fieldName: [String]
  ) {
    getRecord(
      input: {
        programname: $programname
        manufacturernamerev: $manufacturernamerev
        referencenumber: $referencenumber
        fieldName: $fieldName
      }
    )
  }
`;

export const getRecordsFromNextGen = gql`
  query getRecordFromNextGenRenamed(
    $programname: String!
    $manufacturernamerev: [String]
    $referencenumber: [Int]
    $fieldName: [String]
  ) {
    getRecordFromNextGenRenamed(
      input: {
        programname: $programname
        manufacturernamerev: $manufacturernamerev
        referencenumber: $referencenumber
        fieldName: $fieldName
      }
    )
  }
`;

export const getPageRecords = gql`
  query getPageRecord(
    $programname: String!
    $manufacturernamerev: [String]
    $referencenumber: [Int]
    $fieldName: [String]
    $bmgId: [Int]
    $cursor: Int
    $limit: Int!
    $filter: [RecordFilter]
  ) {
    getPageRecord(
      input: {
        programname: $programname
        manufacturernamerev: $manufacturernamerev
        referencenumber: $referencenumber
        fieldName: $fieldName
        bmgId: $bmgId
        cursor: $cursor
        filter: $filter
        limit: $limit
      }
    ) {
      cursor
      record
    }
  }
`;

export const getPageRecordPlotData = gql`
  query getPageRecordPlotData(
    $programname: String!
    $manufacturernamerev: [String]
    $referencenumber: [Int]
    $fieldName: [String]
    $bmgId: [Int]
    $cursor: Int
    $limit: Int!
    $filter: [RecordFilter]
    $categoryFieldName: String
  ) {
    getPageRecordPlotData(
      input: {
        programname: $programname
        manufacturernamerev: $manufacturernamerev
        referencenumber: $referencenumber
        fieldName: $fieldName
        bmgId: $bmgId
        cursor: $cursor
        limit: $limit
        filter: $filter
        categoryFieldName: $categoryFieldName
      }
    ) {
      cursor
      record
    }
  }
`;

export const getRecordSingleVarACS = gql`
  query getRecordSingleVarACS(
    $programname: String!
    $manufacturernamerev: [String]
    $referencenumber: [Int]
    $fieldName: String!
    $bmgId: [Int]
    $filter: [RecordFilter]
  ) {
    getRecordSingleVarACS(
      input: {
        programname: $programname
        manufacturernamerev: $manufacturernamerev
        referencenumber: $referencenumber
        fieldName: $fieldName
        bmgId: $bmgId
        filter: $filter
      }
    ) {
      pass
      fail
      refnoPass
      testnoPass
      refnoFail
      testnoFail
    }
  }
`;

export const getPageRecordsSingleVar = gql`
  query singleVarRecordPaginated(
    $programname: String!
    $manufacturernamerev: [String]
    $referencenumber: [Int]
    $fieldName: String!
    $bmgId: [Int]
    $cursor: Int
    $limit: Int!
    $filter: [RecordFilter]
  ) {
    getPageRecordSingleVar(
      input: {
        programname: $programname
        manufacturernamerev: $manufacturernamerev
        referencenumber: $referencenumber
        fieldName: $fieldName
        bmgId: $bmgId
        cursor: $cursor
        limit: $limit
        filter: $filter
      }
    ) {
      cursor
      record
    }
  }
`;

export const getPageRecordsSingleVarOthers = gql`
  query singleVarRecordPaginatedOthers(
    $programname: String!
    $manufacturernamerev: [String]
    $referencenumber: [Int]
    $fieldName: String!
    $bmgId: [Int]
    $limit: Int!
    $filter: [RecordFilter]
  ) {
    getPageRecordSingleVarOthers(
      input: {
        programname: $programname
        manufacturernamerev: $manufacturernamerev
        referencenumber: $referencenumber
        fieldName: $fieldName
        bmgId: $bmgId
        limit: $limit
        filter: $filter
      }
    ) {
      cursor
      record
    }
  }
`;



export const sendEmail = gql`
  mutation sendEmail($to: String!, $subject: String!, $text: String) {
    sendEmail(input: { to: $to, subject: $subject, text: $text }) {
      status
      msg
      message_id
    }
  }
`;

export const getPageRecordRange = gql`
  query getPageRecordRange(
    $programname: String!
    $manufacturernamerev: [String]
    $referencenumber: [Int]
    # $fieldName: String!
    $bmgId: [Int]
    $cursor: Int
    $limit: Int!
    $filter: [RecordFilter]
  ) {
    getPageRecordRange(
      input: {
        programname: $programname
        manufacturernamerev: $manufacturernamerev
        referencenumber: $referencenumber
        # fieldName: $fieldName
        bmgId: $bmgId
        cursor: $cursor
        limit: $limit
        filter: $filter
      }
    ) {
      cursor
      record
    }
  }
`;

export const getAwardEligibility = gql`
  query getAwardEligibility($manufacturernamerev: [String]) {
    getAwardEligibility(input: $manufacturernamerev) {
      company
      program
      program_short
      period
      year
      hash
    }
  }
`;
export const getCompanyRelationship = gql`
query getCompanyRelationship
(
     $companyName: String!
     $netforumId: String
  ) {
    getCompanyRelationship(
      input: {
          companyName: $companyName
        netforumId: $netforumId
      }
    ) 
  }
`;

export const getCompanyHierarchy = gql`
query getCompanyHierarchy
(
    $companyName: String!
) {
  getCompanyHierarchy(
    input: {
      companyName: $companyName
    }
  )
}
`
export const getCompanyHierarchyStructured = gql`
query getCompanyHierarchyStructured
(
    $companyName: String!
) {
  getCompanyHierarchyStructured(
    input: {
      companyName: $companyName
    }
  )
}
`

export const getDirectoryDownloadLink = gql`
query {
  getDirectoryDownloadLink {
    program
    downloadable_url
    uploaded_date
    data_type
    company_name
  }
}
`