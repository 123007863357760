import {
  SET_SELECTED_TAB,
  CLEAR_SELECTED_TAB,
  SET_SELECTED_INDEX, // this is in Analytics Menu
  CLEAR_SELECTED_INDEX,
  // GET_NEWSVALUE,
  SET_NEWSVALUE,
  SET_MARGIN_VALUE,
  SET_NOTI_HEIGHT,
  SET_PAGE_LOADING,
  SET_CERT_DB,
  SET_PERF_AWARD,
  SET_SCATTER_DATA,
  SET_FAIL_DATA,
  SET_TOP_HEIGHT,
} from '../actions/types';

const initialState = {
  failData :{},
  loading: true,
  selectedTab: 0,
  selectedIndex: 0,
  newsAvailable : false,
  marginAvailable : false,
  notiheight: 0,
  topHeight: false,
  certDB: {},
  perfAward: {},
  scatterData:{
    variableLeft:{
      x:'',
      y:'',
      z:''
    },
    variableRight:{
      x:'',
      y:'',
      z:''
    },
    displayControl : {
      displayChecked:false,
      opacityValue:100,
      pointSize: 6
    },
  },
};

interface Action {
  type: string;
  payload: string | boolean | object | any;
}

export default (state = initialState, action: Action) => {

  switch (action.type) {
    case SET_CERT_DB: {
      return { ...state, certDB: { ...state.certDB,  [action.payload.key]: action.payload.value } };
    }
    case SET_PERF_AWARD: {
      return { ...state, perfAward: { ...state.perfAward,  [action.payload.key]: action.payload.value } };
    }
    case SET_FAIL_DATA: {
      return {
        ...state,
        failData: action.payload,
      };
    }
    case SET_SCATTER_DATA: {
      return {
        ...state,
        scatterData: action.payload,
      };
    }
    case SET_TOP_HEIGHT: {
      return {
        ...state,
        topHeight: action.payload,
      };
    }
    case SET_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: action.payload,
      };
    }
    case SET_NEWSVALUE: {
      return {
        ...state,
        newsAvailable: action.payload,
      };
    }
    case SET_MARGIN_VALUE: {
      return {
        ...state,
        marginAvailable : action.payload,
      };
    }
    case SET_NOTI_HEIGHT: {
      return {
        ...state,
        notiheight : action.payload,
      };
    }
    case CLEAR_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: null,
      };
    }
    case SET_SELECTED_INDEX: {
      return {
        ...state,
        selectedIndex: action.payload,
      };
    }
    case CLEAR_SELECTED_INDEX: {
      return {
        ...state,
        selectedIndex: null,
      };
    }
    case SET_PAGE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      }
    }
    default:
      return state;
  }
};