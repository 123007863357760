// import axios from "axios";
// import {
//   ApolloClient,
//   ApolloLink,
//   HttpLink,
//   InMemoryCache,
// } from "apollo-boost";
import { client } from './client';
import {
  participatingYearQuery,
  participatingProgramQuery,
  childQuery,
  allParentsQuery,
  allParticipantsQuery,
  auditsQuery,
  auditQueryManufacturer,
  auditProcessDetail,
  getUserCompanyAccess,
} from './queries';
// import { isLoggedIn, getAccessToken } from "./auth";

// // import config from "config";
// const endpointURL = "http://192.168.1.187:9000/graphql";

// // make authorization first by preparing header
// const authLink = new ApolloLink((operation, forward) => {
//   if (isLoggedIn()) {
//     // request.headers['authorization'] = 'Bearer ' + getAccessToken();
//     operation.setContext({
//       headers: {
//         authorization: "Bearer " + getAccessToken(),
//       },
//     });
//   }
//   return forward(operation);
// });

// // chaining authorization and normal HttpLink
// // header is made then HttpLink is made
// const client = new ApolloClient({
//   link: ApolloLink.from([authLink, new HttpLink({ uri: endpointURL })]),
//   cache: new InMemoryCache(),
// });

export async function childParticipant(parent) {
  const {
    data: {
      participantFromParents: { manufacturernamerev },
    },
  } = await client.query({
    query: childQuery,
    variables: { parent: parent },
  });
  return manufacturernamerev;
}

export async function loadYears(manufacturernamerev, programname) {
  const {
    data: {
      participatingYear: { programyear },
    },
  } = await client.query({
    query: participatingYearQuery,
    variables: {
      manufacturernamerev: manufacturernamerev,
      programname: programname,
    },
  });
  return programyear.sort(function (a, b) {
    return b - a;
  });
}

export async function loadPrograms(manufacturerName, programyear) {
  const {
    data: {
      participatingProgram: { programname },
    },
  } = await client.query({
    query: participatingProgramQuery,
    variables: { manufacturernamerev: manufacturerName, programyear },
  });
  return programname;
}

export async function loadAllParents(programname) {
  const {
    data: {
      allParents: { parent },
    },
  } = await client.query({
    query: allParentsQuery,
    variables: { programname },
  });

  return parent;
}

export async function loadAllParticipants() {
  const {
    data: { allParticipants },
  } = await client.query({ query: allParticipantsQuery });
  return allParticipants;
}

export async function loadAllAudits() {
  const {
    data: { ahriTestsAll },
  } = await client.query({ query: auditsQuery, fetchPolicy: 'no-cache' });
  return ahriTestsAll;
}

export async function loadAudits(manufacturerName, programyear, programname) {
  try {
    const {
      data: { ahriTests },
    } = await client.query({
      query: auditQueryManufacturer,
      variables: { manufacturerName, programyear, programname },
    });
    console.log(manufacturerName, programyear, programname);
    return ahriTests;
  } catch (error) {
    console.error(error);
  }
}

export async function loadAudit(ahritestnumber) {
  const {
    data: { ahriTest },
  } = await client.query({
    query: auditProcessDetail,
    variables: { ahritestnumber },
  });
  // console.log(ahriTest);
  return ahriTest;
}

export async function loadUserCompanyAccess(email) {
  const {
    data,
    // : {
    //   allParents: { parent },
    // },
  } = await client.query({
    query: getUserCompanyAccess,
    variables: {
      username: email,
    },
    fetchPolicy: 'network-only',
  });

  return data;
}
