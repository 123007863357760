import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ReactComponent as SVGCertifiedLogo } from '../../../assets/img/certLogoTaglineSM2optColor.svg';

export const suspenseLoading = (
  <Grid container direction='column' justifyContent='center' alignItems='center'>
    <Grid item style={{ marginTop: '2em' }}>
      {/* AHRI_tilde_blackwhite.svg */}
      <Typography variant='h5' color='initial'>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <SVGCertifiedLogo
        />{' '}
      </Typography>
    </Grid>
    <Grid item style={{ marginTop: '2em' }}>
      <Typography variant='h5' color='initial'>
        <br />
        Loading analytics components... Please wait. <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Typography>
    </Grid>
  </Grid>
);
