import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './infoBarStyles.css';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import {
  getNotification,
  getPublicNotification,
} from '../../../graphql/queries';
import { connect } from 'react-redux';
import {
  setMarginValue,
  setPageLoading,
  setNotiHeight,
  setTopHeight
} from '../../../actions/pageActions';

// parse markdown and convert the pased into React elements 
import { marked } from "marked";
import parse from 'html-react-parser'


const InfoBar = ({
  appBarPosition,
  appBarStyle,
  toolBarStyle,
  buttonSize,
  onClickFun,
  buttonStyle,
  buttonText,
  buttonVariant,
  buttonOutline,
  topBar,
  isAuthenticated,
  company,
  topHeight,
  setMarginValue,
  setNotiHeight,
  setTopHeight,
  setPageLoading,
  pageLoading,
}) => {
  const theme = useTheme();
  const ref = useRef();
  const matchesMD = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  const matchesXS = useMediaQuery(theme.breakpoints.down('sm'));

  const [newsAvailability, setNewsAvailability] = useState(false);

  const { data: publicNotification, loading: publicNotiLoading } = useQuery(
    getPublicNotification,
    {
      skip: isAuthenticated,
      onCompleted: (data) => {
        if (data.publicNotification?.getPublicNotification.length > 0) {
          setMarginValue(false);
          setNewsAvailability(true);
          setPageLoading(false);
        } else {
          setMarginValue(true);
          setNewsAvailability(false);
        }
      },
    }
  );

  const { data: authNotification, loading: userSpecificNotiLoading } = useQuery(
    getNotification,
    {
      skip: !isAuthenticated,
      variables: {
        manufacturernamerev: company && Array.isArray(company) ? company : [], // or  we can assign company name from redux directly for limited data access to our users.
      },
      onCompleted: (data) => {
        if (data.authNotification?.getNotification.length > 0) {
          setMarginValue(false);
          setNewsAvailability(true);
          setPageLoading(false);
        } else {
          setMarginValue(true);
          setNewsAvailability(false);
        }
      },
    }
  );

  useEffect(() => {
    if (publicNotification?.getPublicNotification.length === 0) {
      setNewsAvailability(false);
      setMarginValue(true);
    }
    if (
      publicNotification?.getPublicNotification.length == 0 &&
      authNotification?.getNotification.length == 0
    ) {
      setNewsAvailability(false);
      setMarginValue(true);
    }

    if (authNotification?.getNotification.length > 0) {
      setNewsAvailability(true);
      setMarginValue(false);
    }
    if (publicNotification?.getPublicNotification.length > 0) {
      setNewsAvailability(true);
      setMarginValue(false);
    }
  });

  if (!publicNotiLoading && !userSpecificNotiLoading) {
    setPageLoading(false);
  }

  useEffect(() => {
    if (ref.current && ref.current.clientHeight) {

      setNotiHeight(!!ref.current.clientHeight ? ref.current.clientHeight : 0);
    }
  });

  const closeButton =() => {
    setTopHeight(true)
    onClickFun()
  }
 

  return (
    <Fragment>
      {!pageLoading &&
        newsAvailability &&
        (publicNotification?.getPublicNotification.length > 0 ||
          authNotification?.getNotification.length > 0) && (
          <AppBar
            ref={ref}
            position={appBarPosition}
            style={appBarStyle}
            elevation={0}
          >
            <Toolbar style={toolBarStyle}>
              <Grid
                container
                direction={matchesSM ? 'column' : 'row'}
                justifyContent='center'
                alignItems='center'
              >
                {topBar && (
                  <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                    {publicNotification && !isAuthenticated ? (
                      <div
                        className='textAnimi'
                      >
                        <Carousel
                          autoPlay={true}
                          infiniteLoop={true}
                          showArrows={false}
                          showIndicators={false}
                          dynamicHeight={false}
                          interval={3000}
                          showThumbs={false}
                          showStatus={false}
                        >
                          {publicNotification.getPublicNotification.map((v) => (
                            <div key={v.id}>
                              <p>

                                  {// Option 1: using `dangerousSetInnerHTML`
                                        // <div dangerouslySetInnerHTML={{__html: marked(v.message)}}></div>
                                        <div>
                                        <div>{parse(marked(v.message))}</div>
                                      <style jsx>{`
                                        a {
                                          text-decoration: underline dotted;
                                          color: white;
                                          font-weight: 600;
                                        }
                                        `}</style>
                                      </div>}
                                      
                                {/* </a> */}
                              </p>
                            </div>
                          ))}
                        </Carousel>
                      </div>
                    ) : (
                      <div>
                        {' '}
                        {authNotification && company && (
                          <div
                            className='textAnimi'
                          >
                            <Carousel
                              autoPlay={true}
                              infiniteLoop={true}
                              showArrows={false}
                              showIndicators={false}
                              dynamicHeight={false}
                              interval={3000}
                              showThumbs={false}
                              showStatus={false}
                            >
                              {authNotification.getNotification.map((v) => (
                                <div key={v.id}>
                                  <p>
                                      {
                                        // Option 1: using `dangerousSetInnerHTML`
                                        // <div dangerouslySetInnerHTML={{__html: marked(v.message)}}></div>
                                          <div>
                                            <div>{parse(marked(v.message))}</div>
                                          <style jsx>{`
                                            a {
                                              text-decoration: underline dotted;
                                              color: white;
                                              font-weight: 600;
                                            }
                                            `}</style>
                                          </div>
                                        }
                                    {/* </a> */}
                                  </p>
                                </div>
                              ))}
                            </Carousel>
                          </div>
                        )}
                      </div>
                    )}
                  </Grid>
                )}

                {topBar && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    xl={2}
                    // alignItems='center'
                    style={{
                      textAlign: matchesSM ? 'center' : 'right',
                    }}
                  >
                    {!matchesMD ? (
                      // `X`
                      <Typography align='center' style={{ color: 'white' }}>
                        <IconButton
                          aria-label='close'
                          color='inherit'
                          size='large'
                          style={{ padding: 0, backgroundColor: '#222' }}
                        >
                          <CloseIcon onClick={closeButton} />
                        </IconButton>
                      </Typography>
                    ) : (
                      <Button
                        color='inherit'
                        size={buttonSize}
                        variant={buttonOutline}
                        onClick={onClickFun}
                        style={buttonStyle}
                      >
                        <Typography
                          variant={buttonVariant}
                          className='textAnimi'
                        >
                          {buttonText}
                        </Typography>
                      </Button>
                      // </div>
                    )}
                  </Grid>
                )}
              </Grid>
            </Toolbar>
          </AppBar>
        )}
    </Fragment>
  );
};

const mapStatetoProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    filters: state.filter.vizTest,
    company: state.auth.user && state.auth.user.company,
    newsAvailable: state.page.newsAvailable,
    marginAvailable: state.page.marginAvailable,
    pageLoading: state.page.loading,
    topHeight:state.page.topHeight,
  };
};

export default connect(mapStatetoProps, {
  setMarginValue,
  setPageLoading,
  setNotiHeight,
  setTopHeight,
})(InfoBar);