// import * as React from 'react';
// import * as ReactDOM from 'react-dom';
// import App from './App';
// import { BrowserRouter as Router } from 'react-router-dom'; // because useLocation needs to be inside..
// // require('dotenv').config()
// import { createRoot } from 'react-dom/client';


// const root = createRoot(document.getElementById('root')!);

// root.render(
//   <Router>
//     <App />
//   </Router>
// );


import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router} from "react-router-dom"; // because useLocation needs to be inside..
// require('dotenv').config()

ReactDOM.render(
  // <React.StrictMode>
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
  // </React.StrictMode>,
);
