// Round 1
// load AuthContext

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

export const PrivateRouter = ({
  component: Component,
  isAuthenticated,
  loading,
  user,
  path,
  pageName,
  pageAccess,
  ...rest
}) => {
  // console.log("company state accessed by PrivateRouter", user && user.company)
  // console.log("atempted component?", Component)
  // console.log('path?', path);
  // console.log("rest props?", rest)
  const whenPageAccess =
    !pageName ||
    pageAccess === null ||
    pageAccess === undefined ||
    (Array.isArray(pageAccess) && pageAccess.length === 0) ||
    (Array.isArray(pageAccess) && pageAccess.includes(pageName));
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !loading ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { whereTo: path },
            }}
          ></Redirect>
        ) : user &&
          user.company &&
          Array.isArray(user.company) &&
          user.company.length === 0 &&
          path !== '/logout' ? ( // TODO: avoid hardcoded approach
          path === '/logout' ? (
            <Redirect to='/logout'></Redirect>
          ) : (
            <Redirect to='/Restricted'></Redirect>
          )
        ) : // (
        //   <Component {...props}></Component>
        // )
        whenPageAccess ? (
          // Check if user has permissions
          // Redirect to permission error page
          <Component {...props}></Component>
        ) : (
          <Redirect to='/ContentRestricted'></Redirect>
        )
      }
    ></Route>
  );
};

// Load user from here
const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
  pageAccess: state.auth.pageAccess,
});
export default connect(mapStatetoProps, {})(PrivateRouter);
