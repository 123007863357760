
// import theme from "../../theme/Theme";
import { styled } from "@mui/system";
import FacebookIcon from '@mui/icons-material/Facebook';
import { BsTwitterX } from 'react-icons/bs';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import  { Grid } from '@mui/material';
import { ahriBlue, ahriGrey } from "../../theme/Theme";

export const MainFooter = styled('footer')(({ theme }) => ({
  backgroundColor: ahriBlue,
  // width: '99.7%',
  zIndex: 1300,
    position: 'relative',
    paddingLeft: 20,
    paddingRight: 20,
    minHeight: "17rem",
    // marginTop:'0.8rem',
    paddingTop: '1.7rem',
    [theme.breakpoints.down('sm') || theme.breakpoints.down('xs')]: {
      marginTop: '0rem',
    },
    [theme.breakpoints.down('sm') || theme.breakpoints.down('xs')]: {
      paddingTop: '5rem',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '7rem',
    },
}));
// export const MainContainer = styled('div')(({ theme }) => ({
//   // position: "absolute",
// }));
export const Linkconnect = styled(Grid)(({ theme }) => ({
  color: 'white',
  fontFamily: 'Arial',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  textDecoration: 'none',
}));
export const Alink = styled('a')(({ theme }) => ({
  color: 'white',
  fontFamily: 'Arial',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  textDecoration: 'none',
}));
export const AlignItemsAndJustifyContent = styled('div')(({ theme }) => ({
  // width: 500,
  // height: 80,
  minHeight: '8rem',
  [theme.breakpoints.down('lg')]: {
    minHeight: '1.8rem',
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: '1.8rem',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // backgroundColor: "pink",
}));
export const Adornment = styled('img')(({ theme }) => ({
  width: '9rem',
  [theme.breakpoints.down('lg')]: {
    height: '2rem',
  },
  [theme.breakpoints.down('lg')]: {
    height: '2rem',
  },
  [theme.breakpoints.down('sm')]: {
    height: '2rem',
  },
  left: '50%',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    alignItems: 'left',
  },
  verticalAlign: 'bottom',
  [theme.breakpoints.down('lg')]: {
    width: '8rem',
  },
  [theme.breakpoints.down('sm')]: {
    width: '7rem',
  },
}));
export const AdornmentCertified = styled('img')(({ theme }) => ({
  height: '6rem',
  [theme.breakpoints.down('lg')]: {
    height: '2rem',
  },
  [theme.breakpoints.down('lg')]: {
    height: '2rem',
  },
  [theme.breakpoints.down('sm')]: {
    height: '2rem',
  },
  left: '50%',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    alignItems: 'left',
  },
  verticalAlign: 'bottom',
  [theme.breakpoints.down('lg')]: {
    width: '8rem',
  },
  [theme.breakpoints.down('sm')]: {
    width: '7rem',
  },
}));
export const GridItem = styled('div')(({ theme }) => ({
  margin: '1rem',
}));

export const Facebook = styled(FacebookIcon)(({ theme }) => ({
  height: '2rem',
  width: '2rem',
  color: 'white',
  [theme.breakpoints.down('lg')]: {
    height: '2.5rem',
    width: '2.5rem',
  },
}));

export const YouTube = styled(YouTubeIcon)(({ theme }) => ({
  height: '2rem',
  width: '2rem',
  color: 'white',
  [theme.breakpoints.down('lg')]: {
    height: '2.5rem',
    width: '2.5rem',
  },
}));
export const Instagram = styled(InstagramIcon)(({ theme }) => ({
  height: '2rem',
  width: '2rem',
  color: 'white',
  [theme.breakpoints.down('lg')]: {
    height: '2.5rem',
    width: '2.5rem',
  },
}));
export const TwitterIcon = styled(BsTwitterX)(({ theme }) => ({
  marginTop: 4,
  height: '1.5rem',
  width: '1.5rem',
  color: 'white',
  [theme.breakpoints.down('lg')]: {
    height: '2.0rem',
    width: '2.0rem',
  },
}));
export const SocialGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  minHeight: 80,
  [theme.breakpoints.down('lg')]: {
    minHeight: 100,
  },
  // alignItems: "center",
  // position: "absolute",
  // marginTop: "-5rem",
  // right: "1.5em",
  // [theme.breakpoints.down("xs")]: {
  //   right: "0.6em",
  // },
}));
export const AdTextContainer = styled('div')(({ theme }) => ({}));
export const AdText = styled('a')(({ theme }) => ({
  color: 'white',
  fontFamily: 'Arial',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  textDecoration: 'none',
  paddingRight: '15px',
  paddingLeft: '15px',
  marginTop: 15,
  marginBottom: 15,
}));



// export const useStyles = makeStyles((theme) => ({
//   footer: {
//     backgroundColor: theme.palette.common.blue,
//     // width: '99.7%',
//     zIndex: 1300,
//     position: 'relative',
//     paddingLeft: 20,
//     paddingRight: 20,
//     // minHeight: "10rem",
//     marginTop:'0.8rem',
//     [theme.breakpoints.down('sm') || theme.breakpoints.down('xs')]: {
//       marginTop: '0rem',
//     },

//   },
//   mainContainer: {
//     // position: "absolute",
//   },
//   link: {
//     color: 'white',
//     fontFamily: 'Arial',
//     fontSize: '0.75rem',
//     fontWeight: 'bold',
//     textDecoration: 'none',
//   },
//   alignItemsAndJustifyContent: {
//     // width: 500,
//     // height: 80,
//     minHeight: '8rem',
//     [theme.breakpoints.down('lg')]: {
//       minHeight: '1.8rem',
//     },
//     [theme.breakpoints.down('sm')]: {
//       minHeight: '1.8rem',
//     },
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     // backgroundColor: "pink",
//   },
//   adornment: {
//     width: '9rem',
//     [theme.breakpoints.down('lg')]: {
//       height: '2rem',
//     },
//     [theme.breakpoints.down('lg')]: {
//       height: '2rem',
//     },
//     [theme.breakpoints.down('sm')]: {
//       height: '2rem',
//     },
//     left: '50%',
//     alignItems: 'center',
//     [theme.breakpoints.down('lg')]: {
//       alignItems: 'left',
//     },
//     verticalAlign: 'bottom',
//     [theme.breakpoints.down('lg')]: {
//       width: '8rem',
//     },
//     [theme.breakpoints.down('sm')]: {
//       width: '7rem',
//     },
//   },
//   adornmentCertified: {
//     height: '6rem',
//     [theme.breakpoints.down('lg')]: {
//       height: '2rem',
//     },
//     [theme.breakpoints.down('lg')]: {
//       height: '2rem',
//     },
//     [theme.breakpoints.down('sm')]: {
//       height: '2rem',
//     },
//     left: '50%',
//     alignItems: 'center',
//     [theme.breakpoints.down('lg')]: {
//       alignItems: 'left',
//     },
//     verticalAlign: 'bottom',
//     [theme.breakpoints.down('lg')]: {
//       width: '8rem',
//     },
//     [theme.breakpoints.down('sm')]: {
//       width: '7rem',
//     },
//   },
//   gridItem: {
//     margin: '1rem',
//   },
//   icon: {
//     height: '2rem',
//     width: '2rem',
//     color: 'white',
//     [theme.breakpoints.down('lg')]: {
//       height: '2.5rem',
//       width: '2.5rem',
//     },
//   },
//   twitterIcon: {
// marginTop: 4,
//     height: '1.5rem',
//     width: '1.5rem',
//     color: 'white',
//     [theme.breakpoints.down('lg')]: {
//       height: '2.0rem',
//       width: '2.0rem',
//     },
//   },
//   socialContainer: {
//     display: 'flex',
//     minHeight: 80,
//     [theme.breakpoints.down('lg')]: {
//       minHeight: 100,
//     },
//     // alignItems: "center",
//     // position: "absolute",
//     // marginTop: "-5rem",
//     // right: "1.5em",
//     // [theme.breakpoints.down("xs")]: {
//     //   right: "0.6em",
//     // },
//   },
//   adTextContainer: {},
//   adText: {
//     color: 'white',
//     fontFamily: 'Arial',
//     fontSize: '1.5rem',
//     fontWeight: 'bold',
//     textDecoration: 'none',
//     paddingRight: '15px',
//     paddingLeft: '15px',
//     marginTop: 15,
//     marginBottom: 15,
//   },
// }));