import React, { useState } from "react";

import { connect } from "react-redux";

import { DivDisclaimer, DisclaimerTitle, DisclaimerContent, SendButton } from "./disclaimerMakeStyles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";

// apollo
import { useMutation, useApolloClient } from "@apollo/client";
import { setDisclaimerSigned } from "../../../graphql/queries";
import gql from "graphql-tag";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="xs" />;

const Disclaimer = (props) => {
  const { username, setDisclaimerOpen } = props;
  let [showDisclaimer, toggleDisclaimer] = useState(true);
  const client = useApolloClient();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  const matchesXS = useMediaQuery(theme.breakpoints.down('sm'));

  const [
    setSigned,
    // result
  ] = useMutation(setDisclaimerSigned);
  // console.log('result', result);

  const query = gql`
    query UserAgreedDisclaimer {
      UserAgreedDisclaimer {
        agreed
      }
    }
  `;

  // const readQuery = gql`
  //   {
  //     UserAgreedDisclaimer @client
  //   }
  // `;

  // const { loading, data: UserAgreedDisclaimer } = useQuery(readQuery);

  const closeDisclaimer = (e) => {
    e.preventDefault();
    toggleDisclaimer(false);
    setDisclaimerOpen ? setDisclaimerOpen(false) : toggleDisclaimer(false);
    username &&
      setSigned({
        variables: {
          username: username,
        },
      });
    // username &&
    //   client.writeQuery({
    //     setDisclaimerSigned,
    //     input: {
    //       username: {
    //         username: username,
    //       },
    //     },
    //   });
    client.writeQuery({
      // { data: { threeYearView: !checked } }
      query,
      data: {
        UserAgreedDisclaimer: {
          agreed: true,
        },
      },
    });
  };

  return (
    <Dialog
      style={{
        zIndex: 1500,
        color: theme.palette.common.blue,
      }}
      open={
        setDisclaimerOpen ? true : showDisclaimer
        // !loading &&
        // isAuthenticated &&
        // UserAgreedDisclaimer &&
        // UserAgreedDisclaimer.UserAgreedDisclaimer &&
        // UserAgreedDisclaimer.UserAgreedDisclaimer.agreed
        //   ? false
        //   : showDisclaimer && !signed
      }
      fullScreen={matchesMD}
      fullWidth={true}
      maxWidth={"lg"}
      //onClose={() => toggleDisclaimer(false)}
      PaperProps={{
        style: {
          paddingTop: matchesXS ? "1em" : "2em",
          paddingBottom: matchesXS ? "1em" : "2em",
          paddingLeft: matchesXS
            ? 0
            : matchesSM
              ? 0
              : matchesMD
                ? "4em"
                : "4em",
          paddingRight: matchesXS
            ? 0
            : matchesSM
              ? 0
              : matchesMD
                ? "4em"
                : "4em",
        },
      }}
    >
      <DialogContent>
        <DivDisclaimer>
          <DisclaimerTitle
            align="center"
            variant="h4"
            component={"span"}
          >
            TERMS OF USE
          </DisclaimerTitle>
          <Paper
            elevation={0}
            variant="outlined"
            style={{
              maxWidth: 900,
              maxHeight: 600,
              overflow: "auto",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <List>
              <DisclaimerContent
                component={"span"}
                align={matchesSM ? "center" : undefined}
          
              >
                <p>
                  In consideration for viewing and using the information contained
                  in the Analytics App, you agree to be bound by these Terms of
                  Use in their entity, without modification.
                </p>
                <p>
                  <strong>User Responsibility and Input</strong>
                  <p>
                    {" "}
                    The disclosure of personal information is covered under{" "}
                    <a
                      href="https://ahrinet.org/about-us/privacy-policy"
                      style={{
                        textDecoration: "none",
                        ...theme.typography.learnButton,
                        fontWeight: 400,
                        // display: 'flex',
                        // alignItems: 'center',
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      AHRI’s Privacy Policy
                    </a>
                  </p>
                  <p>
                    <strong>Users are prohibited from:</strong>
                  </p>

                  <ul>
                    <li>
                      Leasing, selling, copying, sublicensing, transferring,
                      or assigning any information, intellectual property,
                      goods, or services provided on this site
                    </li>
                    <li>Using the site for any illegal purpose</li>
                    <li>
                      Altering, modifying, adapting, reverse engineering,
                      decompiling, disassembling, or hacking AHRI's website
                    </li>
                    <li>
                      Using or exporting the company's information, products,
                      or services in violation of U.S. export laws and regulations
                    </li>
                    <li>
                      Violating anyone else's legal rights (i.e., privacy rights)
                      or any laws (i.e., copyright laws) in the User's jurisdiction
                    </li>
                    <li>
                      Using the Analytics App or AHRI's services to transmit content
                      that could be deemed unlawful, threatening, harassing, racist,
                      abusive, libelous, pornographic, vulgar, defamatory, obscene,
                      indecent, or otherwise inappropriate, including any messages
                      constituting or encouraging criminal conduct
                    </li>
                    <li>
                      Breaching, or attempting to breach, the Analytics App's security systems
                    </li>
                    <li>
                      Enabling third parties to violate the Terms of Use.{" "}
                    </li>
                  </ul>
                </p>
                <Divider variant="middle" />
                <p>
                  <b>Term, Termination, and Survival</b>
                </p>
                <p>
                  The term shall be effective as of the date (the “Effective Date”)
                  the User accepts the terms herein or first accesses, downloads or
                  uses any of the services or information (collectively, the “Services”)
                  on the site and shall remain in effect for so long as the User uses or
                  accesses any of the Services (the “Term”). Upon termination of the Term,
                  the User shall no longer be permitted to use or access the Services. <br />
                  The terms herein that contemplate obligation after the Term, include but are not limited to the following:

                </p>
                <p>
                  <strong>Confidential Information</strong>
                </p>
                <p>
                  The information contained on the Analytics App is Confidential
                  Information owned by AHRI. “Confidential Information” means any
                  technical or business information furnished by a party (as disclosing party)
                  to the other party (as receiving party) in connection with the Services
                  to be performed hereunder, regardless of whether such information
                  is specifically designated as confidential and regardless of
                  whether such information is in written, oral, electronic or
                  other form. Confidential Information may include, without
                  limitation, trade secrets, know-how, inventions, technical
                  data or specifications, testing methods, business or financial
                  information, research and development activities, product and
                  marketing plans, and customer and Contractor information.
                  Confidential Information includes all AHRI information which
                  is not within the public domain and which AHRI has a
                  proprietary interest or legal duty to protect.
                </p>
                <p>
                  Confidential information does not include information that was:
                  (a) in the public domain prior to the time of its disclosure
                  under this Agreement; (b) publicly known at the time of the
                  disclosure or becomes publicly known through no wrongful act
                  or failure of the receiving party; (c) independently developed
                  by the receiving party without use of the Confidential Information;
                  or (d) subsequently disclosed to the receiving party or its affiliates
                  on a non-confidential basis by a third party not having a confidential
                  relationship with the disclosing party and which rightfully
                  acquired such information.{" "}
                </p>
                <p>
                  Use and Non-disclosure of Confidential Information. User agrees that it
                  shall (a) maintain all Confidential Information in strict confidence;
                  (b) use all Confidential Information solely for business purposes; and
                  (c) reproduce the Confidential Information only to the extent
                  necessary to perform its obligations under this Agreement,
                  with all such reproductions being considered Confidential
                  Information. User agrees to not disclose Confidential
                  Information to third parties who (1) are not employed by
                  the USER, the User’s employer or AHRI; (2) have not been
                  assigned a login to view and use the confidential information;
                  and (3) are not subject to these Terms of Use.
                </p>
                <p>
                  <strong>NOTICE for AHRI Analytics App</strong>
                </p>
                <p>
                  AHRI member companies are advised to limit the number of
                  employees provided access to AHRI’s Analytics App due
                  to the potentially sensitive information contained therein.
                  Members must promptly notify AHRI when they no longer employ
                  an individual authorized to access the Analytics App.
                </p>
                <p>
                  Individuals must not attempt to access the Analytics App
                  using credentials provided by a company by which they are
                  no longer employed. Any attempt by an individual to access
                  the Analytics App using credentials provided by a former
                  employer could subject the individual - and their new employer -
                  to legal liability.
                </p>
                <p>
                  <strong>Disclaimers</strong>
                </p>
                <p>
                  AHRI has made every attempt to ensure the accuracy and reliability
                  of the information provided on this Analytics App. However, the
                  information is developed by using approximations, 'as is',
                  without warranty of any kind. No warranties, promises and/or
                  representations of any kind, expressed or implied, are given
                  as to the nature, standard, accuracy or otherwise of the
                  information provided in this application nor to the suitability
                  or otherwise of the information to your particular circumstances.
                </p>
                <p>
                  <strong>DISCLAIMER for AHRI Predictive Analysis Tool</strong>
                </p>
                <p>
                  AHRI's Predictive Analysis tools are designed to estimate the
                  likelihood that equipment is in compliance with Department
                  of Energy (DOE) efficiency regulations. AHRI’s predictions
                  are based on the limited data and information provided to
                  AHRI as well as functions and assumptions built into the
                  predictive models. Accordingly, while these estimates
                  are meant to aid members in evaluating their equipment,
                  it is not meant to be dispositive as to the existence or
                  non-existence of compliance with any applicable regulations.
                  Members should seek independent evaluation in determination
                  of compliance with any applicable regulations.
                </p>
                <p>
                  <strong>Limitation of Liability</strong>
                </p>
                <p>
                  IN NO EVENT SHALL AHRI OR ITS DIRECTORS, EMPLOYEES,
                  OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INCIDENTAL,
                  CONSEQUENTIAL, INDIRECT, SPECIAL, OR EXEMPLARY DAMAGES OF ANY NATURE,
                  INCLUDING, BUT NOT LIMITED TO LOSS OF PROFITS, DATA,
                  BUSINESS OR GOODWILL, EVEN IF AHRI HAS BEEN ADVISED
                  OF THE POSSIBILITY OF SUCH DAMAGES OR FOR ANY DIRECT
                  DAMAGES ABOVE THE AMOUNT OF PAYMENT FOR WORK ACTUALLY
                  PERFORMED BY CONTRACTOR BY USING THE ANALYTICS APP,
                  YOU AGREE THAT THE LAWS OF THE COMMONWEALTH OF VIRGINIA,
                  WITHOUT REGARD TO PRINCIPLES OF CONFLICT OF LAWS,
                  WILL GOVERN THESE TERMS OF USE AND ANY DISPUTE
                  ARISING BETWEEN YOU AND AHRI.
                </p>
                <p>
                  <strong>Intellectual Property</strong>
                </p>
                <p>
                  The Analytics App, the application, website, and content
                  (including without limitation the text, computer code,
                  artwork, photographs, and images) are sole property of AHRI.
                  Copyright licenses may be requested by contacting AHRI.{" "}
                </p>
                <p>
                  <strong>Changes to these Terms of Use</strong>
                </p>
                <p>
                  AHRI reserves the right to modify these Terms of Use at any time.
                  AHRI does so by posting the updated terms to this website.
                  Your decision to continue using the Analytics App after
                  changes have been made constitutes your acceptance of the new Terms of Use.
                </p>
                <p>
                  <strong>Contact AHRI</strong>
                </p>
                <p>
                  If you have any further questions about the AHRI Analytics
                  App, please contact{" "}
                  <a
                    style={{
                      textDecoration: "none",
                      ...theme.typography.learnButton,
                      fontWeight: 400,
                      // display: 'flex',
                      // alignItems: 'center',
                    }}
                    href="mailto:certanalytics@ahrinet.org"
                  >
                    us
                  </a>
                  .
                </p>
              </DisclaimerContent>
            </List>
          </Paper>
          <Typography
            align="center"
            variant="body1"
            component={"span"}
          >
            <br />
            TERMS OF USE will reappear after 24 hours upon your agreement.
          </Typography>
          <SendButton
            style={{ marginTop: 30 }}
            variant="contained"
            onClick={(e) => closeDisclaimer(e)}
          >
            I Agree
          </SendButton>
        </DivDisclaimer>
      </DialogContent>
    </Dialog>
  );
};

const mapStatetoProps = (state) => ({
  username: state.auth.user && state.auth.user.username,
  isAuthenticated: state.auth.isAuthenticated,
  selectedTab: state.page.selectedTab,
  selectedIndex: state.page.selectedIndex,
});

export default connect(mapStatetoProps, {})(withWidth()(Disclaimer));
