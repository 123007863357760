import gql from 'graphql-tag';

export const auditDetailFragment = gql`
  fragment AuditDetail on AhriTestEach {
    _id
    ahriaction
    ahritestnumber
    bmgrerated
    cancelledreason
    CEO_firstPass
    CEO_firstTest
    CEO_firstTestComplete
    createdby
    FirstTestFail
    FirstTestObsolete
    FirstTestPass
    FirstTestPendingMDF
    FirstTestRerate
    FirstTestAnnual
    FurtherAdditionalTestFail
    FurtherAdditionalTestObsolete
    FurtherAdditionalTestPass
    FurtherAdditionalTestPendingMDF
    FurtherAdditionalTestRerate
    FurtherAdditionalTests
    involuntary_rerate
    isAnnualTest
    iscancelled
    isChallengeTest
    isDiscretTest
    isEStarTest
    isFinalTest
    isFirstDefective
    isFirstRetest
    isFirstTest
    isFourthTest
    isInoperable
    isLastTest
    isPenaltyTest
    isQualTest
    isRetest
    isSecondDefective
    isSecondRetest
    isSecondTest
    isTestComplete
    isTestCompleteApproved
    isTestDone
    isTestReported
    isTestValid
    isThirdRetest
    isThirdTest
    labandlablocation
    labcode
    labid
    locationid
    maintestid
    manufacturername
    manufacturernamerev
    manufacturertype
    manufacturertypeid
    mdfdatereceived
    mdfdatesent
    mdfdecision
    modelrerated
    needRetest
    netforumcompanyid_afterReview
    parent
    physicalselectiondate
    previoustestid
    programid
    programname
    programyear
    relatedtestid
    retestNumber_round
    sampledeliverydate
    scheduledtestdate
    SecondTest
    SecondTestFail
    SecondTestObsolete
    SecondTestPass
    SecondTestPendingMDF
    SecondTestRerate
    selectionsenttoparticipant
    senttolab
    statusid
    testcancelled
    testclarifiertypeid
    testdate
    testedoemahrirefnumber
    testNumber_round
    testNumber_singleDigit
    testNumberNumeric
    testreport
    testreportdate
    testresult
    teststatus
    testtypeid
    estestresult
    isbmglocked
    ismodellocked
    modelnumber
    testlocation
    ises
    dir_islocked
    dir_snapshotcreatedby
    dir_snapshotcreateddate
    dir_snapshotid
    dir_oemname
    dir_ariprogramid
    dir_modelstatusid
    dir_oemid
    dir_createddate
    dir_referencenumber
    dir_createduserid
    dir_createdusername
    dir_modifiedusername
    dir_isactive
    dir_modifieddate
    dir_modelnumber
    bmgid
    dir_bmgid
    nameplatemodelnumber
    esapproach
  }
`;

export const auditSummaryFragment = gql`
  fragment AuditSummary on AhriTest {
    _id
    ahritestnumber
    programname
    testresult
    parent
    manufacturernamerev
    isTestComplete
    isTestCompleteApproved
    isTestDone
    isTestReported
    isTestValid
    labandlablocation
    Tested
    Reported
    physicalselectiondate
    sampledeliverydate
    scheduledtestdate
    testdate
    testreportdate
    facilitylocation
    selected_model_combination
    is_pre_delivery_stage
    testedoemahrirefnumber
    senttolab
    mdfdatesent
    mdfdatereceived
    selectionsenttoparticipant
    wrkbk_aprvd
    isSentToLab
    isSentToParticipant
    FirstTestAnnual
    isPenaltyTest
  }
`;

export const rwhDetailFragment = gql`
  fragment RwhResultDetail on RwhResult {
    _id
    ahritestnumber
    manufacturernamerev
    input
    input_measured
    input_ratio
    input_ahritestresult
    ratedvolume
    laboratorymeasuredvolume
    firsthourrating
    firsthourrating_measured
    firsthourrating_ratio
    firsthourrating_ahritestresult
    maxgpm
    maxgpm_measured
    maxgpm_ratio
    maxgpm_ahritestresult
    ef
    ef_measured
    ef_ratio
    ef_ahritestresult
    drawpattern
    drawpattern_measured
    uedmaxgpm
    uedmaxgpm_measured
    uedmaxgpm_ratio
    uedmaxgpm_ahritestresult
    uedfirsthourrating
    uedfirsthourrating_measured
    uedfirsthourrating_ratio
    uedfirsthourrating_ahritestresult
    uef
    uef_measured
    uef_ratio
    uef_ahritestresult
    recoveryefficiency
    recoveryefficiency_measured
    uedrecoveryefficiency
    uedrecoveryefficiency_measured
    testresult
    estestresult
  }
`;

export const RwhResultDetailAnonymous = gql`
  fragment RwhResultDetailAnonymous on RwhRatioResult {
    input_ratio
    firsthourrating_ratio
    maxgpm_ratio
    ef_ratio
    uedmaxgpm_ratio
    uedfirsthourrating_ratio
    uef_ratio
    testresult
  }
`;
// Ratio Set
// testresult
//     estestresult
//     input_ratio
//     firsthourrating_ratio
//     maxgpm_ratio
//     ef_ratio
//     uedmaxgpm_ratio
//     uedfirsthourrating_ratio
//     uef_ratio
