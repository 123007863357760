import React from 'react';

const tracking = () => {
  return (
    <div style={{ margin: '0', padding: '0' }}>
      <embed
        src='http://www.marinetraffic.com'
        width='1435rem'
        height='700rem'
      ></embed>
    </div>
  );
};
export default tracking;
