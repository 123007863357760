import gql from 'graphql-tag';

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    ahriTests(input: ahriTestsInput): [AhriTest]
  }

  input ahriTestsInput {
    manufacturernamerev: [String]
    programname: [String]
    programyear: [Int]
    parent: String
    testresult: Boolean
  }

  # Each Test and Its Results
  # TODO: Append Test Results
  type AhriTest {
    _id: ID!
    ahritestnumber: String
    programname: String
    testresult: Boolean
    parent: String
    manufacturernamerev: String
    isTestComplete: Boolean
    isTestCompleteApproved: Boolean
    isTestDone: Boolean
    isTestValid: Boolean
  }
  extend type Launch {
    isInCart: Boolean!
  }

  extend type Mutation {
    addOrRemoveFromCart(id: ID!): [ID!]!
  }
`;
