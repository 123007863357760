import { createStore, applyMiddleware } from 'redux';
import { Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

import thunk from 'redux-thunk';
import rootReducer from './reducers';

const initialState = {};

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];

// NOTE: when the redux-persist is not used this store variable is exported
// const store = createStore(
//   rootReducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
//   // applyMiddleware(...middleware)
// );
// export default store;

export default () => {
  let store: Store = createStore(
    persistedReducer,
    // rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
  // console.log(store);
  let persistor = persistStore(store);
  return { store, persistor };
};
