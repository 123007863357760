import { combineReducers } from 'redux';
import auditReducer from './auditReducer';
import alertReducer from './alertReducer';
import authReducer from './authReducer';
import pageReducer from './pageReducer';
import filterReducer from './filterReducer';

export default combineReducers({
  audit: auditReducer,
  alerts: alertReducer,
  auth: authReducer,
  page: pageReducer,
  filter: filterReducer,
});
