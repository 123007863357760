import React, { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { useTheme } from '@mui/material/styles';

const FilterBar = ({ selectedParent, selectedProgram, selectedYear, hideYear }) => {
  // console.log('FilterBar, ', selectedParent, selectedProgram, selectedYear)
  const theme = useTheme();
  return (
    <Fragment>
      <div>
        <Grid container direction='row' justifyContent='center'>
          <Grid item direction='row' lg={10} style={{ textAlign: 'center' }}>
            <Typography
              variant='body1'
              style={{
                color: theme.palette.common.white,
                flexDirection: 'row',
              }}
            >
              {/* {`now displaying data for:     `} */}
              {` ${selectedParent}`}
              {'   -   '} {selectedProgram} 
              {!!selectedYear && !hideYear ? `   -    ${selectedYear}` : ''}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default FilterBar;
