import { ApolloCache } from 'apollo-cache';
import { Resolvers } from 'apollo-client';

type ResolverFn = (
  parent: any,
  args: any,
  { cache }: { cache: ApolloCache<any> }
) => any;

interface ResolverMap {
  [field: string]: ResolverFn;
}

interface AppResolvers extends Resolvers {
  // We will update this with our app's resolvers later
}

export const resolvers = {
  Mutation: {
    updateLoading: (
      _root: any,
      variables: any,
      { cache, getCacheKey }: { cache: any; getCacheKey: any }
    ) => {
      // const id = getCacheKey({ __typename: 'TodoItem', id: variables.id })
      //   const fragment = gql`
      //     fragment completeTodo on TodoItem {
      //       completed
      //     }
      //   `;
      //   const todo = cache.readFragment({ fragment, id });
      //   const data = { ...todo, completed: !todo.completed };
      //   cache.writeData({ id, data });
      return null;
    },
  },
};
