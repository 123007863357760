import React, { useEffect, useState, useMemo, Fragment } from 'react';
// joyride
import { Tooltip } from '../guides/Tooltip';
import Joyride, { STATUS } from 'react-joyride';
import { defaultOptions } from '../guides/defaultOptions';

const HeaderGuide = ({ ceoAccessTest }) => {
  const [run, setRun] = useState(true);
  // const showBeaconAgain = () => {
  //   setRun(false);
  //   setTimeout(() => {
  //     setRun(true);
  //   }, 1);
  // };

  const headerToured = window.localStorage.getItem('headerTour');
  // window.localStorage.removeItem('headerTour');
  useEffect(() => {
    if (headerToured && parseInt(headerToured) >= 3) {
      // this will make the first 4
      setRun(false);
    }
  }, [headerToured]);

  const handleCallback = (state) => {
    const options = [STATUS.FINISHED, STATUS.SKIPPED];
    // console.log('joyride status', state);
    // console.log('state', state);
    // console.log('headerToured', headerToured);
    if (options.includes(state.status) && state.index == state.size - 1) {
      // console.log('I hit the last', state.status);
      if (
        state.type === 'tour:end' &&
        (!headerToured || parseInt(headerToured) < 4)
      ) {
        window.localStorage.setItem(
          'headerTour',
          headerToured ? parseInt(headerToured) + 1 : 1
        );
      }
      setTimeout(() => {
        setRun(false);
      }, 1);
    }
    // if (state.action === 'reset') {
    //   showBeaconAgain();
    // }
  };

  const steps = useMemo(
    () => [
      {
        title: 'Please Place Mouse Pointer',
        content: (
          <Fragment>
            <img
              src={require('../../../assets/animations/menuBox.gif')}
              alt='menu foldown example...'
            />
            <br></br>
            You can browse <em>different pages</em> in{' '}
            <strong>Dashboard</strong> or in <strong>Analytic Tools</strong>
            <br />{' '}
          </Fragment>
        ),
        target: '#scrollable-auto-tab-dashboardTab',
      },
      {
        title: ceoAccessTest
          ? 'Configure Employee Access'
          : 'Check Your Profile',
        content: (
          <Fragment>
            You can check your profile in the AHRI membership system. <br />{' '}
            <br></br>
            {ceoAccessTest && (
              <Fragment>
                <img
                  src={require('../../../assets/animations/userSetting2.gif')}
                  alt='menu foldown example...'
                />
                <br />
                <br />
                Also, you can assign app access to your employee at{' '}
                <strong>USER ACCESS SETTING</strong> submenu.
              </Fragment>
            )}
          </Fragment>
        ),
        placement: 'center',
        target: '#scrollable-auto-tab-accountTab',
      },
      {
        title: 'Thick Bar = Click',
        content: (
          <Fragment>
            You can click a <em>bar</em> to see more information.
            <br />
            <br />
            <Fragment>
              <img
                src={require('../../../assets/animations/bar_click_yeah.gif')}
                alt='menu foldown example...'
              />
              <br />
              <br />
              For example, you can narrow down to audit performance testing
              records or AHRI NextGen Directory records.
            </Fragment>
          </Fragment>
        ),
        placement: 'center',
        target: '#scrollable-auto-tab-accountTab',
      },
      // {
      //   title: 'Popover Menu',
      //   content: (
      //     <Fragment>
      //       This is a filter menu where you can choose your company, a
      //       certification program, and/or a program year. <br />
      //       Analytic Tool require specific filter conditions to generate output.
      //     </Fragment>
      //   ),
      //   target: '#floatFilterButton',
      // },
      // ...(uniqueServiceRoutes.includes(location.pathname)
      //   ? [
      //       {
      //         title: 'Popover Menu',
      //         content: (
      //           <Fragment>
      //             This is a filter menu where you can choose your company, a
      //             certification program, and/or a program year. <br />
      //             Analytic Tool require specific filter conditions to generate
      //             output.
      //           </Fragment>
      //         ),
      //         target: '#floatFilterButton',
      //       },
      //     ]
      //   : []),
    ],
    [ceoAccessTest]
  );

  return (
    <Joyride
      steps={steps}
      run={run}
      continuous={true}
      callback={handleCallback}
      showSkipButton={true}
      tooltipComponent={Tooltip}
      styles={{ options: { ...defaultOptions, zIndex: 1390 } }}
    />
  );
};

export default HeaderGuide;
