import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const usePageTracking = () => {
  const location = useLocation();
  // console.log('GA Tracking No', process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_NO)
  // console.log('process env', process.env.NODE_ENV)
  const [initialized, setInitialized] = useState(false);
  // console.log('window href', window.location.href)
  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_NO);
      // console.log("ga initialized!")
    }
    setInitialized(true);
  }, []);

  // useEffect(() => {
  //   if(indId){
  //     if (window.location.href.includes("localhost")) {
  //       ReactGA.set({ userId: indId });
  //       console.log("ga set userId", indId)
  //     }
  //   }
  // }, [indId]);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
      // console.log("sending location info", location.pathname + location.search)
    }
  }, [initialized, location]);
};

export default usePageTracking;

// consider when this custom hook is used within components that access states
// export default connect(mapStatetoProps, {
//   loadUser,
// })(withWidth()(usePageTracking));
