import { createTheme, TypographyStyle} from '@mui/material/styles';




declare module '@mui/material/styles' {
  interface CommonColors {
    blue: string;
    orange: string;
    grey: string;
    white: string;
    pass: string;
    fail: string;
    pending: string;
  }
}



export const ahriBlue = '#0B72B9';
export const ahriOrange = '#ffaf60'; //"#EB8B00"; //"#FFBA60";
export const ahriGrey = '#868686';
export const ahriWhite = '#ffffff';
export const ahriRed = '#C70039';
export const ahriGreen = '#5b8c85';
export const ahriBlueGrey = '#6a8caf';
export const ahriMaroon = '#903749';

export const pass = '#07689F';
export const fail = '#FF7E67';
export const pending = '#928a97';
export const gridColor = '#e0e0e0';
export const failLine = '#6a8caf'; //'#6c5b7b';
export const prgmFailLine = '#ffaf60'; //#EA5455';

export const alertColorGetter = (alert: string) => {
  switch (alert) {
    case 'primary':
      return ahriBlue;
    case 'secondary':
      return ahriOrange;
    case 'success':
      return ahriGreen;
    case 'danger':
      return ahriRed;
    case 'warning':
      return ahriMaroon;
    case 'info':
      return ahriBlueGrey;
    case 'light':
      return ahriBlueGrey;
    default:
      return '';
  }
};




declare module '@mui/material/styles' {
  export interface CommonColors {
    black: string;
    white: string;
    blue: string;
    orange: string;
    grey: string;
    // white: string;
    pass: string;
    fail: string;
    pending: string;
  }
}


declare module '@mui/material/styles/createTheme' {
  export interface Theme {
    noDecoLink?: NoDecoLink;
  }
  export interface DeprecatedThemeOptions {
    noDecoLink?: NoDecoLink;
  }
}

interface NoDecoLink {
  textDecoration: string;
  color: string;
}

declare module '@mui/material/styles/createTypography' {
  export interface Theme {
    noDecoLink?: NoDecoLink;
  }
  export interface DeprecatedThemeOptions {
    noDecoLink?: NoDecoLink;
  }
  export type CustomVariant =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline' // up to this point, defined in vanilla Variant
    | 'root' // newly added
    | 'tab'
    | 'h2Orange'
    | 'body1'
    | 'toolTipText'
    | 'subtitle1'
    | 'subtitle2'
    | 'learnButton'
    | 'cardHeader'
    | 'sendRequest'
    | 'DashboardComponent'
    | 'chart';

  export interface Typography
    extends Record<CustomVariant, TypographyStyle>,
      FontStyle,
      TypographyUtils {}

  export interface TypographyOptions
    extends Partial<
      Record<CustomVariant, TypographyStyleOptions> & FontStyleOptions
    > {}
}

export const colorMap = {
  common: {
    ahriBlue: ahriBlue,
    ahriOrange: ahriOrange,
    ahriGrey: ahriGrey,
    ahriWhite: ahriWhite,
    pass: pass,
    fail: fail,
    pending: pending,
  },
  progressBar: {
    // overrides preset
    pass: pass,
    fail: fail,
    pending: pending,
    'Test Passed[%]': pass,
    'Test Failed[%]': fail,
    'Test Pending[%]': pending,
  },
  programProgressBar: {
    // overrides preset
    'Test Passed': pass,
    'Test Failed': fail,
    'Test Pending': pending,
    'Test Passed[%]': pass,
    'Test Failed[%]': fail,
    'Test Pending[%]': pending,
  },
  decisionBar: {
    // overrides preset
    'Pass after Retests': pass,
    Rerated: ahriGreen,
    Obsoleted: ahriRed,
    'Decision Pending': pending,
    'DTC Testing (USE Program)': ahriBlueGrey,
  },
  failRateHistoryLine: {
    // overrides preset
    'Test Failed': fail,
    'Test Pending': pending,
  },
};


const theme = createTheme({
  // noDecoLink: {
  //   textDecoration: 'none',
  //   color: 'inherit',
  // },
  palette: {
    common: {
      blue: ahriBlue,
      orange: ahriOrange,
      grey: ahriGrey,
      white: ahriWhite,
      pass: pass,
      fail: fail,
      pending: pending,
    },
    primary: {
      main: `${ahriBlue}`,
    },
    info: {
      main: `${ahriBlue}`,
    },
    secondary: {
      main: `${ahriOrange}`,
    },
  },
  typography: {
    root: { marginBottom: '5em' },
    tab: {
      fontFamily: 'Raleway',
      textTransform: 'none',
      fontWeight: 700,
      fontSize: '1.0rem',
    },
    h1: {
      fontFamily: 'Raleway',
      fontWeight: 700,
      fontSize: '2.3rem',
      color: `${ahriBlue}`,
      lineHeight: 1.5,
    },
    h2: {
      fontFamily: 'Raleway',
      fontWeight: 700,
      fontSize: '2.3rem',
      color: `${ahriBlue}`,
      lineHeight: 1.5,
    },
    h2Orange: {
      fontFamily: 'Raleway',
      fontWeight: 700,
      fontSize: '2.3rem',
      color: `${ahriOrange}`,
      lineHeight: 1.5,
    },
    h3: {
      fontFamily: 'Raleway',
      fontSize: '1.9rem',
      color: `${ahriBlue}`,
    },
    h4: {
      fontFamily: 'Raleway',
      fontSize: '1.55rem',
      color: `${ahriBlue}`,
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Raleway',
      fontSize: '1.15rem',
      color: `${ahriBlue}`,
      fontWeight: 500,
    },
    body1: {
      fontSize: '1.10rem',
      color: ahriGrey,
      weight: 200,
    },
    body2: {
      fontSize: '0.9rem',
      color: ahriBlue,
      weight: 300,
    },
    toolTipText: {
      fontFamily: 'Titillium Web',
      fontSize: '0.9rem',
      textTransform: 'none',
      lineHeight: 1.1,
    },
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: 300,
      color: `${ahriGrey}`,
    },
    subtitle2: {
      fontSize: '1.25rem',
      fontWeight: 300,
      color: `${ahriWhite}`,
    },
    learnButton: {
      borderColor: `${ahriBlue}`,
      color: `${ahriBlue}`,
      borderWidth: 3,
      textTransition: 'none',
      borderRadius: 7,
      // fontSize :'0.9rem',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
    },
    cardHeader: {
      // borderColor: `${ahriBlue}`,
      color: ahriWhite,
      fontSize: '1.25rem',
      // borderWidth: 3,
      // textTransition: "none",
      // borderRadius: 7,
      fontFamily: 'Roboto',
      fontWeight: 400,
    },
    sendRequest: {
      fontFamily: 'Titillium Web',
      fontSize: '0.9rem',
      textTransform: 'none',
      lineHeight: 1.1,
    },
    DashboardComponent: {
      fontFamily: 'Titillium Web',
      fontSize: '0.9rem',
      textTransform: 'none',
      // lineHeight: 1.1,
    },
    chart: {
      fontFamily: 'Raleway',
      color: `${ahriGrey}`,
      fontSize: '1.0rem',
      textTransform: 'none',
      lineHeight: 1.1,
    },
  },
  // overrides: {
  //   MuiCheckbox: {
  //     root: {
  //       color: ahriBlue,
  //     },
  //   },
  //   MuiInputLabel: {
  //     root: {
  //       color: ahriBlue,
  //       fontSize: '1rem',
  //     },
  //   },
  //   MuiInput: {
  //     root: {
  //       color: ahriGrey,
  //       weight: 300,
  //     },
  //     underline: {
  //       '&:before': {
  //         borderBottom: `2px solid ${ahriBlue}`,
  //       },
  //       '&:hover:not($disabled):not($focused):not($error):before': {
  //         borderBottom: `2px solid ${ahriBlue}`,
  //       },
  //     },
  //   },
  //   MuiFormControlLabel: {
  //     label: {
  //       color: ahriBlue,
  //       fontWeight: 400,
  //     },
  //     labelPlacementStart: {
  //       marginLeft: 0,
  //     },
  //   },
  //   MuiPaper: {
  //     elevation2: {
  //       boxShadow: 'none',
  //     },
  //   },
  //   MuiCardHeader: {
  //     action: {
  //       marginTop: 0,
  //       marginBottom: 0,
  //       // marginRight: 0,
  //       // marginLeft: 0,
  //     },
  //   },
  // },
});


export default theme;
