import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

// import AuditContext from "../../context/audit/auditContext";
import { connect } from 'react-redux';
import { MainContainer } from './disclaimerMakeStyles';

import Disclaimer from './Disclaimer';
import { loadUser } from '../../../actions/authActions';
import { setSelectedTab, setSelectedIndex } from '../../../actions/pageActions';

// import { useTheme } from "@mui/material/styles";
import Grid from '@mui/material/Grid';
// import useMediaQuery from "@mui/material/useMediaQuery";

// apollo
import { useQuery } from '@apollo/client';
import { getDisclaimerSigned } from '../../../graphql/queries';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="xs" />;

// import gql from 'graphql-tag';

const DisclaimerBase = (props) => {
  const { isAuthenticated, token, loadUser, username } = props;

  useEffect(() => {
    if (token) {
      loadUser();
    }
  }, [token, isAuthenticated, loadUser]);

  // const { data: filterData } = useQuery(readQuery);

  const { data: disclaimerSigned, loading } = useQuery(getDisclaimerSigned, {
    skip: !isAuthenticated,
    variables: { username: username },
  });

  const signed = disclaimerSigned && disclaimerSigned.getDisclaimerSigned;
  return (
    <MainContainer container direction='column'>
      {isAuthenticated && !loading && signed !== undefined && !signed && (
        <Disclaimer signed={signed} />
      )}
    </MainContainer>
  );
};

DisclaimerBase.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  token: PropTypes.string,
  loadUser: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  username: state.auth.user && state.auth.user.username,
  isAuthenticated: state.auth.isAuthenticated,
  token: state.auth.token,
  selectedTab: state.page.selectedTab,
  selectedIndex: state.page.selectedIndex,
});

export default connect(mapStatetoProps, {
  loadUser,
  setSelectedTab,
  setSelectedIndex,
})(withWidth()(DisclaimerBase));
