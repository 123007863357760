import {
  GET_ALLPARENTS,
  SET_PARENT,
  GET_PARTICIPANT,
  SET_PARTICIPANT,
  FILTER_ERROR,
  SET_LOADING,
  GET_YEAR,
  SET_YEAR,
  GET_PROGRAM,
  SET_PROGRAM,
} from "../actions/types";

import { Filter } from "./stateInterfaces";

// years to date
const d: Date = new Date();
const month: number = d.getMonth();
const yr =
  month > 2 ? d.getFullYear().toString() : (d.getFullYear() - 1).toString();

const list = [];
for (var i = 2013; i <= ((d.getMonth() > 8) ? d.getFullYear()+1 : d.getFullYear()); i++) {
  list.push(i);
}

// decending order
const years = list
  .sort(function (a, b) {
    return b - a;
  })
  .map((year) => year.toString());

// Needs rework to make strict typing
type Filters = {
  loading: boolean;  
  allParents: string[] | null; // this used to work
  error: any | null;
  // } & {
  [index: string]: Filter | boolean | string[] | null | string; // this is bad typing
};

// const asMyObj = <T extends MyObj<keyof T>>(myObj: T) => myObj;
// type MyObj<K extends keyof any> = {
//   [P in K]: Filter | boolean | string[] | null; //{

//   // allParents: string[] | null;
//   // error: any | null;
//   // };
// };
// const typedInitialState = asMyObj({
//   drilldown: {
//     // loading: true,
//     // allParents: null,
//     selectedParent: null,
//     participants: null,
//     selectedParticipant: null,
//     error: null,
//     years: years,
//     selectedYear: yr,
//     programs: null,
//     selectedProgram: null,
//   },
//   dashboard: {
//     // loading: true,
//     // allParents: null,
//     selectedParent: null,
//     participants: null,
//     selectedParticipant: null,
//     error: null,
//     years: years,
//     selectedYear: yr,
//     programs: null,
//     selectedProgram: null,
//   },
//   loading: true,
//   allParents: null,
//   // participants: null,
//   error: null,
//   // years: list.sort(function (a, b) {
//   //   return b - a;
//   // }),
// });

interface Action {
  index: string;
  type: string;
  payload: Payload;
}

interface SectionFilter {
  selectedParent: string | null;
  participants: string[];
  selectedParticipant: string;
  error: string;
  years: number[];
  selectedYear: number[];
  programs: string[];
  selectedProgram: string;
}

interface Payload {
  parent: string;
  token: string;
  company: string[];
  participants: string[];
}

const initialState: Filters = {
  drilldown: {
    // loading: true,
    // allParents: null,
    selectedParent: null,
    participants: null,
    selectedParticipant: null,
    error: null,
    years: years,
    selectedYear: yr,
    programs: null,
    selectedProgram: null,

  },
    drilldowntwo: {
    // loading: true,
    // allParents: null,
    selectedParent: null,
    participants: null,
    selectedParticipant: null,
    error: null,
    years: years,
    selectedYear: yr,
    programs: null,
    selectedProgram: null,
  },
  dashboard: {
    // loading: true,
    // allParents: null,
    selectedParent: null,
    participants: null,
    selectedParticipant: null,
    error: null,
    years: years,
    selectedYear: yr,
    programs: null,
    selectedProgram: null,
  },
  vizTest: {
    // loading: true,
    // allParents: null,
    selectedParent: null,
    participants: null,
    selectedParticipant: null,
    error: null,
    years: years,
    selectedYear: yr,
    programs: null,
    selectedProgram: null,
  },
  loading: false,
  allParents: null,
  // participants: null,
  error: null,
  // years: list.sort(function (a, b) {
  //   return b - a;
  // }),
};

const filterReducer = (state: Filters = initialState, action: Action) => {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: true,
        // [action.index]: {
        //   ...state[action.index],
        // },
      };
    }

    case GET_ALLPARENTS: {
      return {
        ...state,
        loading: false,
        allParents: action.payload,
      };
    }
    case SET_PARENT: {
      return {
        ...state,
        loading: false,
        [action.index]: {
          ...(state[action.index] as Filter),
          selectedParent: action.payload.parent,
          participants: action.payload.participants,
        },
      };
    }
    case GET_PARTICIPANT: {
      return {
        ...state,
        loading: false,
        [action.index]: {
          ...(state[action.index] as Filter),
          participants: action.payload,
        },
      };
    }
    case SET_PARTICIPANT: {
      return {
        ...state,
        [action.index]: {
          ...(state[action.index] as Filter),
          loading: false,
          selectedParticipant: action.payload,
        },
      };
    }
    case FILTER_ERROR: {
      return {
        ...state,
        loading: false,
        [action.index]: {
          ...(state[action.index] as Filter),
          error: action.payload,
        },
      };
    }
    case GET_YEAR: {
      return {
        ...state,
        loading: false,
        [action.index]: {
          ...(state[action.index] as Filter),
          years: action.payload,
        },
      };
    }
    case SET_YEAR: {
      return {
        ...state,
        loading: false,
        [action.index]: {
          ...(state[action.index] as Filter),
          selectedYear: action.payload,
        },
      };
    }
    case GET_PROGRAM: {
      return {
        ...state,
        loading: false,
        [action.index]: {
          ...(state[action.index] as Filter),
          programs: action.payload,
        },
      };
    }
    case SET_PROGRAM: {
      return {
        ...state,
        loading: false,
        [action.index]: {
          ...(state[action.index] as Filter),
          selectedProgram: action.payload,
        },
      };
    }
    
     default:
      return state;
  }


};

export default filterReducer;
