import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { logout, loadUser } from '../../../actions/authActions';
import { setSelectedTab, setSelectedIndex } from '../../../actions/pageActions';
// Material UI
import Grid from '@mui/material/Grid';

import { Box } from '@mui/material';

// Own Style
import CertifiedLogo from '../../../assets/img/certLogoTaglineSm2_whiteT.svg';
import footerLogo from '../../../assets/AHRILOGO_NAMETAGcmykVECTOR-REVERSE_resized.svg';
import footerLogoSimple from '../../../assets/AHRI_tilde_white.svg';
import { AdornmentCertified} from './footerMakeStyles';
import { useTheme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';

//styled components
 import { MainFooter, SocialGrid, Linkconnect, AlignItemsAndJustifyContent, Adornment, YouTube, Instagram,Facebook, TwitterIcon,GridItem, AdText, Alink  } from './footerMakeStyles';

const Footer = (props) => {
  const {
    // title,
    isAuthenticated,
    // user,
    // logout,
    // loadUser,
    // value,
    // selectedTab,
    // selectedIndex,
    // setSelectedTab,
    // setSelectedIndex,
    // ...props
    // width,
  } = props;


  function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
      keys.reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
      }, null) || 'xs'
    );
  }
  const width = useWidth();
 
  return (
    <MainFooter>
      {(width === 'xl' || width === 'lg' || width === 'md') && (
          <Box sx={{ display: { md: 'block', sm: 'none' } }}>
            <Grid
              container
              justifyContent='center'
              spacing={2}
            >
              <GridItem>
                {/* <Grid container direction='column' spacing={2}> */}
                  <Linkconnect component={Link} to='/'>
                    Home
                  </Linkconnect>
                {/* </Grid> */}
              </GridItem>

              {isAuthenticated && (
                <GridItem>
                  <Grid
                    container
                    direction='column'
                    spacing={2}
                    alignItems='center'
                  >
                    <Linkconnect
                      item
                      component={Link}
                      to='/dashboard'
                  
                    >
                      Dashboard
                    </Linkconnect>
                    <Linkconnect
                      item
                      component={Link}
                      to='/testresults'
                    
                    >
                      Failure Analysis
                    </Linkconnect>
                    <Linkconnect
                      item
                      component={Link}
                      to='/selectionInfo'
                   
                    >
                      Selection Information
                    </Linkconnect>
                    <Linkconnect
                      item
                      component={Link}
                      to='/decisions'
                    
                    >
                      Rerate/Failure Decisions
                    </Linkconnect>
                    <Linkconnect
                      item
                      component={Link}
                      to='/performanceAnalysis'
                      
                    >
                      Performance Analysis
                    </Linkconnect>
                  </Grid>
                </GridItem>
              )}
              {isAuthenticated && (
                <GridItem>
                  <Grid
                    container
                    direction='column'
                    spacing={2}
                    alignItems='center'
                  >
                    <Linkconnect
                      item
                      component={Link}
                      to='/analytictools'
                     
                    >
                    Analytic Tools
                    </Linkconnect>
                    <Linkconnect
                      item
                      component={Link}
                      to='/certDB'            
                    >
                     Audit Test DB
                    </Linkconnect>
                    <Linkconnect
                      item
                      component={Link}
                      to='/scatter'                  
                    >
                      Data Analysis/Screening
                    </Linkconnect>
                  </Grid>
                </GridItem>
              )}
              {isAuthenticated ? (
                <GridItem>
                  <Grid
                    container
                    direction='column'
                    spacing={2}
                    alignItems='center'
                  >
                    <Linkconnect
                      item
                      component={Link}
                      to='/logout'
                    >
                      Logout
                    </Linkconnect>
                  </Grid>
                </GridItem>
              ) : (
                <GridItem>
                  <Grid
                    container
                    direction='column'
                    spacing={2}
                    alignItems='center'
                  >
                    <Linkconnect
                      item
                      component={Link}
                      to='/login'
                    >
                      Login
                    </Linkconnect>
                  </Grid>
                </GridItem>
              )}

              <GridItem>
                <Grid
                  container
                  direction='column'
                  spacing={2}
                  alignItems='center'
                >
                  <Linkconnect
                    item
                    component={Link}
                    to='/aboutanalytics'
                   
                  >
                    About AHRI Analytics
                  </Linkconnect>
                </Grid>
              </GridItem>
              <GridItem>
                <Grid
                  container
                  direction='column'
                  spacing={2}
                  alignItems='center'
                >
                  <Linkconnect
                    item
 
                    to='https://ahrinet.org/about-us/privacy-policy'
                  >
                    <a
                      href='https://ahrinet.org/about-us/privacy-policy'
                    
                      style={{
                        textDecoration: 'none',
                        color: 'white',
                        // ...theme.typography.learnButton,
                        // fontWeight: 400,
                        // display: 'flex',
                        // alignItems: 'center',
                      }}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      AHRI’s Privacy Policy
                    </a>
                  </Linkconnect>
                </Grid>
              </GridItem>

              {isAuthenticated && (
                <GridItem>
                  <Grid
                    container
                    direction='column'
                    spacing={2}
                    alignItems='center'
                  >
                    <Linkconnect
                      item
                      component={Link}
                      to='/account'
                    >
                      My Account
                    </Linkconnect>
                  </Grid>
                </GridItem>
              )}
            </Grid>
          </Box>
        )}

      {width === 'xl' || width === 'lg' ? (
        <AlignItemsAndJustifyContent>
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
          >
            <Grid item>
              <Grid container direction='row' justifyContent='flex-end'>
                <Grid item>
                  <Alink
                    href='https://www.ahrinet.org/'
                    style={{
                      textDecoration: 'none',
                      // ...theme.typography.learnButton,
                      // fontWeight: 400,
                      // display: 'flex',
                      // alignItems: 'center',
                    }}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Adornment
                      style={{ marginRight: 50 }}
                      alt='AHRI Logo in Footer'
                      src={footerLogo}
                    ></Adornment>
                  </Alink>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction='row' justifyContent='flex-end'>
                <Grid item>
                  <Alink
                    href='https://www.ahridirectory.org/'
                    style={{
                      textDecoration: 'none',
                      // ...theme.typography.learnButton,
                      // fontWeight: 400,
                      // display: 'flex',
                      // alignItems: 'center',
                    }}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <AdornmentCertified
                      alt='AHRI Certified Logo in Footer'
                      src={CertifiedLogo}
                    ></AdornmentCertified>
                  </Alink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AlignItemsAndJustifyContent>
      ) : (
        <AlignItemsAndJustifyContent>
          <AdText>Follow </AdText>
          <Adornment
            alt='AHRI Logo in Footer'
            src={footerLogoSimple}
          ></Adornment>
          <AdText>on</AdText>
        </AlignItemsAndJustifyContent>
      )}
      <SocialGrid
        container
        spacing={1}
        justifyContent='center'
        // justify='flex-end'
      >
        <Grid
          item
          component={'a'}
          href='https://www.facebook.com/AHRIconnect/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Facebook></Facebook>
        </Grid>
        <Grid
          item
          component={'a'}
          href='https://www.instagram.com/ahriconnect/?hl=en>'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Instagram></Instagram>
        </Grid>

        <Grid
          item
          component={'a'}
          href='https://twitter.com/AHRIConnect?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Eprofile%3AAHRIconnect&ref_url=http%3A%2F%2Fahrinet.org%2FHome'
          target='_blank'
          rel='noopener noreferrer'
        >
          <TwitterIcon/>
        </Grid>
        <Grid
          item
          component={'a'}
          href='https://www.youtube.com/user/AHRIcommunications'
          target='_blank'
          rel='noopener noreferrer'
        >
          <YouTube></YouTube>
        </Grid>
      </SocialGrid>
    </MainFooter>
  );
};

Footer.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

const mapStatetoProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    selectedTab: state.page.selectedTab,
    selectedIndex: state.page.selectedIndex,
  };
};
export default connect(mapStatetoProps, {
  logout,
  loadUser,
  setSelectedTab,
  setSelectedIndex,
})(Footer);