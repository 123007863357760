// for same functions in switch under different cases:
// case A:
// case B: { ... }

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_PENDING,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  isAuthenticated: false,
  loading: true,
  user: null,
  isStaff: false,
  logout: null,
  loginPending: false,
  programAccess: null,
  pageAccess: null,
};

interface Action {
  type: string;
  payload: Payload;
}
interface Payload {
  token: string;
  company: string[];
  programAccess: null | string[]
  pageAccess: null | string[]
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case LOGIN_PENDING:
      {
        return {
          ...state,
          loginPending: true,
        };
      }
    case LOGIN_SUCCESS:
      {
        localStorage.setItem("token", action.payload.token);
        return {
          ...state,
          ...action.payload,
          isAuthenticated: true,
          loading: true,
          logout: false,
          loginPending: false,
        };
      }
    case REGISTER_SUCCESS: {
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
        logout: false,
      };
    }
    case LOGOUT: {
      localStorage.removeItem("token");
      // console.log("Token Removed");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        programAccess: null,
        pageAccess: null,
        error: action.payload,
        logout: true,
        loginPending: false,
      };
    }
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case REGISTER_FAIL: {
      localStorage.removeItem("token");
      // console.log("Token Removed");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: action.payload,
      };
    }
    case USER_LOADED: {
      const isStaff =
        Array.isArray(action.payload.company) && action.payload.company.includes("Air-Conditioning, Heating, and Refrigeration Institute") 
        // &&  action.payload.company.length === 1; // due to User Access Setting
      return {
        ...state,
        token: localStorage.token,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
        isStaff: isStaff,
        loginPending: false,
        programAccess: action.payload.programAccess,
        pageAccess: action.payload.pageAccess
      };
    }
    case CLEAR_ERRORS: {
      return {
        ...state,
        error: null,
        loginPending: false,
      };
    }
    default:
      return state;
  }
};
