import theme from '../../theme/Theme';
// import { grey } from '@mui/material/colors';
import { styled } from '@mui/system';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { Accordion } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import { AccordionDetails } from '@mui/material';
import { ListItem } from '@mui/material';
import { Button } from '@mui/material';
import { Paper } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

export const TabRoot = styled('div')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));
export const TabScrollTop = styled('div')(({ theme }) => ({
  zIndex: 99999,
  position: 'relative',
}));
export const TabTransition = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
}));
export const TabContainer = styled(Tabs)(({ theme }) => ({
  // marginLeft: 'auto',
  // border:'2px solid red',
  marginLeft: '3rem',
}));
export const TabBox = styled(Tab)(({ theme }) => ({
  ...theme.typography.tab,
  minWidth: 10,
  marginLeft: '20px',
   opacity: 0.9,
  color: 'white',
  // '&:hover': {
  //   opacity: 1,
  // },
}));
// export const Button = styled('div')(({ theme }) => ({
//   ...theme.typography.tab,
//   borderRadius: '50px',
//   marginLeft: '0.5rem',
//   marginRight: '0rem',
//   justifyContent: 'right',
//   fontWeight: 400,
//   height: '45px',
//   color: 'white',
//   '&:hover': {
//     backgroundColor: theme.palette.primary.light,
//   },
// }));
export const Menu = styled(Paper)(({ theme }) => ({
  paper: {
    backgroundColor: theme.palette.common.blue,
    color: 'white',
    borderRadius: '0px',
    zIndex: 1310,
    // '&:selected': {
    //   color:'orange'
    // }
  },
}));
// export const PaperMenu = styled(Paper)(({ theme }) => ({
//   root :{
//   backgroundColor: theme.palette.common.blue,
//   color: 'white',
//   borderRadius: '0px',
//   zIndex: 1310,
// }
// }));
export const MenuItem = styled('div')(({ theme }) => ({
  ...theme.typography.tab,
  // opacity: 1.0,
  color: 'white',
  // '&:hover': {
  //   opacity: 1,
  // },
}));
export const DrawerIcon = styled(MenuIcon)(({ theme }) => ({
  height: '40px',
  width: '40px',
  color: 'white',
}));
export const DrawerIconContainer = styled(IconButton)(({ theme }) => ({
  marginLeft: 'auto',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));
export const SwipeableDrawerStyled = styled(SwipeableDrawer)(({ theme }) => ({
  paper: { backgroundColor: theme.palette.common.blue },
}));
export const DrawerMargin = styled('div')(({ theme }) => ({
  marginTop: 65,
}));
export const DrawerItem = styled('div')(({ theme }) => ({
  ...theme.typography.tab,
  color: 'white',
  opacity: 0.7,
}));
export const LinkExpansionSummaryText = styled(Link)(({ theme }) => ({
  ...theme.typography.tab,
  color: 'white',
  opacity: 0.7,
  textDecoration: 'none',
}));
// export const ListItemDrawerItemSelected = styled(ListItem)(({ theme }) => ({
//   selected: {
//   opacity: 1.0,
//   '& .MuiListItemText-root': {
//     opacity: 1.0,
//   },
// }
// }));
export const ListItemDrawerItemSelected = styled(ListItem)(({ theme }) => ({
  height: '3rem',

  // '.ListItem.Mui-selected': {
  // opacity: 1.0,
  // },

  // selected: {
  //   opacity: 1.0,
  //   '& .MuiListItemText-root': {
  //     opacity: 1.0,
  //   },
  // },
  // root: {
  //   ...theme.typography.tab,
  //   opacity: 0.7,
  //   color: 'white',
  //   '&:hover': {
  //     opacity: 1,
  //   },
  // },
}));

export const DrawItemSendRequest = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.orange,
}));
export const Appbar = styled('div')(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
}));
export const Logo = styled('img')(({ theme }) => ({
  height: '2.5rem',
}));
export const LogoContainer = styled(Button)(({ theme }) => ({
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));
export const AccordionExpansion = styled(Accordion)(({ theme }) => ({
  root: {
    color: 'white',
    backgroundColor: theme.palette.common.blue,
    borderBottom: '1px solid rgba(0,0,0,0.12)',
    '&.Mui-expanded': {
      margin: 0,
      borderBottom: 0,
    },
    '&::before': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    zIndex: 1310,
  },
}));
export const AccordionExpansionDetails = styled(AccordionDetails)(
  ({ theme }) => ({
    root: {
      padding: 0,
      // backgroundColor: theme.palette.primary.light,
      zIndex: 3000,
    },
  })
);
export const AccordionExpansionSummary = styled(AccordionSummary)(
  ({ theme }) => ({
    root: {
      padding: '0 24px 0 16px',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.08)',
      },
    },
  })
);
export const SmallButton = styled('div')(({ theme }) => ({
  margin: theme.spacing(0),
}));
export const DisclaimerButton = styled(Button)(({ theme }) => ({
  background: '#C15050',
  opacity: 0.8,
  borderRadius: 3,
  border: 0,
  color: 'white',
  height: 22,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  '&$disabled': {
    background: 'rgba(0, 0, 0, 0.12)',
    color: 'white',
    boxShadow: 'none',
  },
  '&:hover': {
    background: '#FE6B8B',
  },
}));

export const AppBar = styled('div')(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
}));

export const expansionSummaryText = {
  ...theme.typography.tab,
  color: 'white',
  opacity: 0.7,

  textDecoration: 'none',
};

export const AccordianSX = {
  color: 'white',
  backgroundColor: theme.palette.common.blue,
  borderBottom: '1px solid rgba(0,0,0,0.12)',
  '&.Mui-expanded': {
    margin: 0,
    borderBottom: 0,
  },
  '&::before': {
    backgroundColor: 'rgba(0,0,0,0)',
  },
  zIndex: 1310,
};

export const AccordianSummarySX = {
  padding: '0 24px 0 16px',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.08)',
  },
  backgroundColor: (props) =>
    props.selectedTab === 'analyticsTab' ? 'rgba(0,0,0,0.14)' : 'inherit',
};

export const menuItemSX = {
  ...theme.typography.tab,
  opacity: 0.7,
  color: 'white',
  '&:hover': {
    opacity: 1,
  },
};

export const drawerItemSelected = {
  opacity: 1.0,
  '& .MuiListItemText-root': {
    opacity: 1.0,
  },
};

// Main Styling

export const MainRoot = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));
export const ToolbarMargin = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
  [theme.breakpoints.down('xl')]: { minHeight: 26 },
  [theme.breakpoints.down('md')]: { minHeight: 26 },
  [theme.breakpoints.down('xs')]: { minHeight: 16 },
}));
export const MainLogo = styled('div')(({ theme }) => ({
  height: '2.5rem',
}));
export const MainLogoContainer = styled('div')(({ theme }) => ({
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

// export const useTabStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     width: '100%',
//     backgroundColor: theme.palette.background.paper,
//     position: 'fixed',
//     bottom: theme.spacing(2),
//     right: theme.spacing(2),
//   },
//   scrollTop: {
//     zIndex: 99999,
//     position: 'relative',
//   },
//   transition: {
//     marginLeft: 'auto',
//   },
//   tabContainer: {
//     marginLeft: 'auto',
//   },
//   tab: {
//     ...theme.typography.tab, // move style that can be shared globally
//     minWidth: 10, // this will shrinken the distance between tab labels
//     marginLeft: '20px', // this is useful to make constant spacing
//     opacity: 0.7,
//     color: 'white',
//     '&:hover': {
//       opacity: 1,
//     },
//   },
//   button: {
//     ...theme.typography.tab,
//     borderRadius: '50px',
//     // borderTop: '0.rem',
//     marginLeft: '0.5rem',
//     [theme.breakpoints.down('lg')]: {
//       marginLeft: '2rem',
//     },
//     marginRight: '0rem',
//     justifyContent: 'right',
//     fontWeight: 400,
//     height: '45px',
//     [theme.breakpoints.down('md')]: { height: '40px' },
//     [theme.breakpoints.down('xs')]: { height: '30px' },
//     color: 'white',
//     // marginLeft: 'auto',
//     '&:hover': {
//       backgroundColor: theme.palette.primary.light,
//     },
//   },
//   menu: {
//     backgroundColor: theme.palette.common.blue,
//     color: 'white',
//     borderRadius: '0px',
//     zIndex: 1310,
//   },
//   menuItem: {
//     ...theme.typography.tab,
//     opacity: 0.7,
//     color: 'white',
//     '&:hover': {
//       opacity: 1,
//     },
//   },
//   drawerIcon: {
//     height: '40px',
//     [theme.breakpoints.down('sm')]: { height: '30px' },
//     width: '40px',
//     [theme.breakpoints.down('sm')]: { width: '30px' },

//     color: 'white',
//   },
//   drawerIconContainer: {
//     marginLeft: 'auto',
//     '&:hover': {
//       backgroundColor: 'transparent',
//     },
//   },
//   drawer: {
//     backgroundColor: theme.palette.common.blue,
//   },
//   drawerMargin: {
//     marginTop: 65,
//   },
//   drawerItem: {
//     ...theme.typography.tab,
//     color: 'white',
//     opacity: 0.7,
//   },
//   expansionSummaryText: {
//     ...theme.typography.tab,
//     color: 'white',
//     opacity: 0.7,

//     textDecoration: 'none',
//   },
//   drawerItemSelected: {
//     opacity: 1.0,
//     '& .MuiListItemText-root': {
//       opacity: 1.0,
//     },
//   },
//   drawItemSendRequest: {
//     backgroundColor: theme.palette.common.orange,
//   },
//   appbar: {
//     zIndex: theme.zIndex.modal + 1,
//   },
//   // toolbarMargin: {
//   //   ...theme.mixins.toolbar,
//   //   // marginBottom: '1em',
//   //   [theme.breakpoints.down('xl')]: { minHeight: 0 },
//   //   [theme.breakpoints.down('md')]: { minHeight: 0 },
//   //   [theme.breakpoints.down('xs')]: { minHeight: 0 },
//   // },
//   logo: {
//     height: '2.5rem', // when AHRI is used
//     [theme.breakpoints.down('md')]: { height: '2.0rem' }, // when AHRI is used
//     [theme.breakpoints.down('xs')]: { height: '1.7rem' }, // when AHRI is used
//     // height: '5rem',
//     // [theme.breakpoints.down('md')]: { height: '4.0rem' },
//     // [theme.breakpoints.down('xs')]: { height: '3.4rem' },
//   },
//   logoContainer: {
//     padding: 0,
//     // marginRight: 'auto',
//     '&:hover': {
//       backgroundColor: 'transparent',
//     },
//   },
//   expansion: {
//     color: 'white',
//     backgroundColor: theme.palette.common.blue,

//     borderBottom: '1px solid rgba(0,0,0,0.12)',
//     '&.Mui-expanded': {
//       margin: 0,
//       borderBottom: 0,
//     },
//     '&::before': {
//       backgroundColor: 'rgba(0,0,0,0)',
//     },
//     zIndex: 1310,
//   },
//   expansionDetails: {
//     padding: 0,
//     backgroundColor: theme.palette.primary.light,
//     zIndex: 3000,
//   },
//   expansionSummary: {
//     padding: '0 24px 0 16px',
//     '&:hover': {
//       backgroundColor: 'rgba(0,0,0,0.08)',
//     },
//     backgroundColor: (props) =>
//       props.selectedTab === 'analyticsTab' ? 'rgba(0,0,0,0.14)' : 'inherit',
//   },
//   smallButton: {
//     margin: theme.spacing(0),
//   },
//   disclaimerButton: {
//     // '&.MuiButton-root': {
//     // },
//     // '&.MuiButton-outlined': {
//     //   // border: '3px solid rgba(0,0,0,0.23)',
//     //   color: '#222',
//     //   border: 3,
//     // },
//     background: '#C15050', //linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
//     opacity: 0.8,
//     borderRadius: 3,
//     border: 0,
//     color: 'white',
//     height: 22,
//     // padding: '0 30px',
//     boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
//     // $disabled is a reference to the local disabled
//     // rule within the same style sheet.
//     // By using &, we increase the specificity.
//     '&$disabled': {
//       background: 'rgba(0, 0, 0, 0.12)',
//       color: 'white',
//       boxShadow: 'none',
//     },
//     '&:hover': {
//       background: '#FE6B8B',
//     },
//   },
// }));

// export const useStyles = makeStyles(() => ({
//   root: {
//     position: 'fixed',
//     bottom: theme.spacing(2),
//     right: theme.spacing(2),
//   },
//   toolbarMargin: {
//     ...theme.mixins.toolbar,
//     // marginBottom: '1em',
//     [theme.breakpoints.down('xl')]: { minHeight: 26 },
//     [theme.breakpoints.down('md')]: { minHeight: 26 },
//     [theme.breakpoints.down('xs')]: { minHeight: 16 },
//   },
//   logo: {
//     height: '2.5rem',
//     [theme.breakpoints.down('md')]: { height: '2.0rem' },
//     [theme.breakpoints.down('xs')]: { height: '1.7rem' },
//   },
//   logoContainer: {
//     padding: 0,
//     // marginRight: 'auto',
//     '&:hover': {
//       backgroundColor: 'transparent',
//     },
//   },
// }));
