
import background from '../../../assets/img/certLogoTaglineSm2.svg';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from "@mui/system";

export  const Backgroundd = styled('div')(({ theme }) => ({
  backgroundImage: `url(${background})`,
  backgroundPosition: 'center',
  backgroundSize: '60%', // 'center' for real image // 'contain' for full size
  backgroundRepeat: 'no-repeat',
  height: '50em',
  marginTop: '0em',
  paddingBottom: '10em',
  [theme.breakpoints.down('lg')]: {
    backgroundImage: `url(${background})`,
  },
}));

export const EstimateButton = styled(Button)(({ theme }) => ({
  ...theme.typography.estimate,
  borderRadius: 50,
  height: 80,
  width: 205,
  backgroundColor: theme.palette.common.orange,
  fontSize: '1.5rem',
  marginRight: '5em',
  marginLeft: '2em',
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  },
  [theme.breakpoints.down('lg')]: {
    marginLeft: 0,
    marginRight: 0,
  },
}));

export const LearnButton = styled(Button)(({ theme }) => ({
  ...theme.typography.learnButton,
  fontSize: '0.7rem',
  height: 35,
  padding: 5,
  [theme.breakpoints.down('lg')]: {
    marginBottom: '2em',
  },
}));

export const Message = styled(TextField)(({ theme }) => ({
  '&.MuiFormControl-root': {
    border: `2px solid ${theme.palette.common.blue}`,
    borderRadius: 10,
  },
  marginTop: '0em',
  borderRadius: 5,
}));


export const SendButton = styled(Button)(({ theme }) => ({
  ...theme.typography.estimate,
  color: 'white',
  height: 45,
  width: 245,
  fontSize: '1rem',
  backgroundColor: theme.palette.common.blue,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  [theme.breakpoints.down('md')]: {
    height: 40,
    width: 225,
  },
}));




// export const useStyles = makeStyles((theme) => ({
//   background: {
//     backgroundImage: `url(${background})`,
//     backgroundPosition: 'center',
//     backgroundSize: '60%', // 'center' for real image // 'contain' for full size
//     backgroundRepeat: 'no-repeat',
//     height: '50em',
//     marginTop: '0em',
//     paddingBottom: '10em',
//     [theme.breakpoints.down('lg')]: {
//       backgroundImage: `url(${background})`,
//     },
//   },
//   estimateButton: {
//     ...theme.typography.estimate,
//     borderRadius: 50,
//     height: 80,
//     width: 205,
//     backgroundColor: theme.palette.common.orange,
//     fontSize: '1.5rem',
//     marginRight: '5em',
//     marginLeft: '2em',
//     '&:hover': {
//       backgroundColor: theme.palette.secondary.light,
//     },
//     [theme.breakpoints.down('lg')]: {
//       marginLeft: 0,
//       marginRight: 0,
//     },
//   },
//   learnButton: {
//     ...theme.typography.learnButton,
//     fontSize: '0.7rem',
//     height: 35,
//     padding: 5,
//     [theme.breakpoints.down('lg')]: {
//       marginBottom: '2em',
//     },
//   },
//   message: {
//     '&.MuiFormControl-root': {
//       border: `2px solid ${theme.palette.common.blue}`,
//       borderRadius: 10,
//     },
//     marginTop: '0em',
//     borderRadius: 5,
//   },
//   sendButton: {
//     ...theme.typography.estimate,
//     color: 'white',
//     height: 45,
//     width: 245,
//     fontSize: '1rem',
//     backgroundColor: theme.palette.common.blue,
//     '&:hover': {
//       backgroundColor: theme.palette.primary.light,
//     },
//     [theme.breakpoints.down('md')]: {
//       height: 40,
//       width: 225,
//     },
//   },
// }));
