// create a functional component with argument alert that will be destructured?
// if alert is not null then <div className={`alert alert-${alert.type}}
// use a FontAwesome icon i.fas.fa-info-circle with message {alert.msg}
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Snackbar from '@mui/material/Snackbar';

import {

  alertColorGetter,

} from '../theme/Theme';


const Alert = ({ alerts }) => {

  return (
    alerts.length !== 0 &&
    alerts.map((alert) => (
      <React.Fragment>
        <Snackbar
          open={!!alert}
          ContentProps={{
            style: {
              fontFamily: 'Raleway',
              backgroundColor: alertColorGetter(alert.type),
            },
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={
            alert && alert.msg
              ? alert.msg.message
                ? alert.msg.message.toUpperCase() // typical REST error
                : alert.msg.toUpperCase() // graphql error
              : 'Server Not Responding. Please Contact Us for Support.'
          }
          autoHideDuration={4000}
          // onClose={() => setUser({ ...user, alert: false })}
        />
      </React.Fragment>

    ))
  );
};

Alert.propTypes = {
  alerts: PropTypes.array,
};

const mapStatetoProps = (state) => ({
  alerts: state.alerts,
});

export default connect(mapStatetoProps, {})(Alert);
