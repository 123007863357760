import {
  InMemoryCache,
  // NormalizedCacheObject, // need for persisting cache
} from '@apollo/client';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getPageRecord: {
          keyArgs: (args, context) => {
            // console.log('args, context', args, context);
            // console.log('args selected', args.input.fieldName);

            const keyArg_ =
              args.input &&
              args.input &&
              args.input.filter &&
              Array.isArray(args.input.filter) &
                (args.input.filter.filter((each) => each.key).length > 0)
                ? [args.input.programname]
                    .concat([args.input.fieldName])
                    .concat(args.input.manufacturernamerev)
                    .concat(
                      args.input.filter.map((each) => each.value.join('|'))
                    )
                    .join('|')
                : args.input &&
                  [args.input.programname]
                    .concat([args.input.fieldName])
                    .concat(args.input.manufacturernamerev)
                    .join('|');
            return keyArg_;
          },
          merge(existing, incoming, { readField }) {
            //const record = {};
            const record = existing ? { ...existing.record } : {};
            incoming &&
              incoming.record &&
              incoming.record.forEach((each) => {
                record[readField('ReferenceId', each)] = each;
              });
            // console.log('record in merge', record);
            return {
              cursor: incoming && incoming.cursor,
              record,
            };
          },

          read(existing) {
            if (existing) {
              return {
                cursor: existing.cursor,
                record: Object.values(existing.record),
              };
            }
          },
        },
        getPageRecordSingleVar: {
          keyArgs: (args, context) => {
            // console.log('args, context', args, context);
            // console.log(
            //   'args condition',
            //   args.input &&
            //     args.input.filter &&
            //     Array.isArray(args.input.filter) &
            //       (args.input.filter.length > 0)
            // );
            // console.log('args selected', args.input.fieldName);
            const keyArg_ =
              args.input &&
              args.input.filter &&
              Array.isArray(args.input.filter) &
                (args.input.filter.filter((each) => each.key).length > 0)
                ? [args.input.programname]
                    .concat([args.input.fieldName])
                    .concat(args.input.manufacturernamerev)
                    .concat(
                      args.input.filter.map((each) => each.value.join('|'))
                    )
                    .join('|')
                : args.input &&
                  [args.input.programname]
                    .concat([args.input.fieldName])
                    .concat(args.input.manufacturernamerev)
                    .join('|');
            return keyArg_;
          },
          merge(existing, incoming, { readField }) {
            const record = existing && existing.record ? existing.record : [];
            const merged =
              incoming && incoming.record
                ? [...record, ...incoming.record]
                : record;
            return {
              cursor:
                incoming && incoming.cursor
                  ? incoming.cursor
                  : existing && existing.cursor,
              record: merged,
            };
          },

          read(existing) {
            if (existing) {
              return existing;
              // {
              //   cursor: existing.cursor,
              //   record: existing.record,
              // };
            }
          },
        },
      },
    },
  },
});

export default cache;
