import { ApolloProvider } from "@apollo/client";
import * as React from "react";
import {
  // BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

// import { SnackbarProvider } from 'notistack';

// Graphql import
import { client } from "./graphql/client";
// import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { suspenseLoading } from "./components/layout/suspense/suspenseLoading";
// import Navbar from "./components/layout/Navbar";
// import Dashboard from './components/pages/dashboard/Dashboard';
// import TestProgress from './components/pages/dashboard/TestProgress';
// import TestResults from './components/pages/dashboard/TestResults';
// import PerformanceAnalysis from './components/pages/dashboard/PerformanceAnalysis';
// import CertTestDB from './components/pages/certTestDB/CertTestDB';
// import Register from './components/auth/Register';
// import Login from './components/auth/Login';
// import Logout from './components/auth/Logout';
// import AboutAnalytics from './components/pages/about/AboutAnalytics';
// import AboutNewCert from './components/pages/about/AboutNewCert';
// import Apologies from './components/pages/Apologies/Apologies';
// import noPermission from './components/pages/Apologies/noPermission';
// import MyAccount from './components/pages/myAccount/MyAccount';
// import Audit from './components/audits/Audit';
import Alert from "./components/layout/Alert";
// import AboutCert from "./components/pages/about/AboutCert";
// import AboutAhri from './components/pages/about/AboutAhri';
import ContactUs from "./components/pages/contactUs/ContactUs";
// import Home from './components/pages/Home';

// import { Test } from './components/pages/d3Test/test';
// import SingleVarAnalysis from './components/pages/singleVarAnalysis/SingleVarAnalysis';
// import ChartTest from './components/pages/charttest/ChartTest';

// import LandingPage from './components/pages/landingPage/LandingPage';

import setAuthToken from "./utils/setAuthToken";
import PrivateRoute from "./components/routing/PrivateRouter";
import ScrollIntoView from "./utils/ScrollIntoView";

// Redux import
import { Provider } from "react-redux";
// import store from "./store"; // when redux-persist is not used
import configureStore from "./store";
import { PersistGate } from "redux-persist/integration/react";

// Google Analytics Hook
// import usePageTracking from "./components/googleAnalytics/usePageTracking"

// import "./App.css";
// import "materialize-css/dist/css/materialize.min.css";
// import M from "materialize-css/dist/js/materialize.min.js";

import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
// import { ThemeProvider as ThemeProviderV5 } from "@mui/material/styles";
// import Header from "./components/layout/header/Header";
// import theme from "./components/theme/Theme";
import Footer from "./components/layout/footer/Footer";
import CookieBar from "./components/layout/cookieBar/cookieBar";
import DisclaimerBase from "./components/layout/disclaimer/DisclaimerBase";
// import QuickNotice from './components/layout/disclaimer/QuickNotice';


import Header from "./components/layout/header/Header";
import theme from "./components/theme/Theme";

// import PerformanceAward from './components/pages/Performace/PerformanceAward';
import tracking from "./components/pages/Tracking/tracking";

//remove system and add styles to revert
// declare module '@mui/system/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }


if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const LandingPage = React.lazy(
  () => import("./components/pages/landingPage/LandingPage")
);
const Slider = React.lazy(() => import("./components/auth/Slider"));

const NotFound = React.lazy(() => import("./components/pages/NotFound"));

const Login = React.lazy(() => import("./components/auth/Login"));

const Logout = React.lazy(() => import("./components/auth/Logout"));
const AboutAnalytics = React.lazy(
  () => import("./components/pages/about/AboutAnalytics")
);

const Apologies = React.lazy(
  () => import("./components/pages/Apologies/Apologies")
);

// const Tracking = React.lazy(
//   () => import('./components/pages/Tracking/tracking')
// );
const noPermission = React.lazy(
  () => import("./components/pages/Apologies/noPermission")
);
const noPageAccess = React.lazy(
  () => import("./components/pages/Apologies/noPageAccess")
);
const MyAccount = React.lazy(
  () => import("./components/pages/myAccount/MyAccount")
);
const Audit = React.lazy(() => import("./components/audits/Audit"));

const TestProgress = React.lazy(
  () => import("./components/pages/dashboard/TestProgress")
);
const TestResults = React.lazy(
  () => import("./components/pages/dashboard/TestResults")
);
const PostFailureDecisions = React.lazy(
  () => import("./components/pages/dashboard/PostFailureDecisions")
);
const SelectionInformation = React.lazy(
  () => import("./components/pages/dashboard/SelectionInformation")
);
const PerformanceAnalysis = React.lazy(
  () => import("./components/pages/dashboard/PerformanceAnalysis")
);

const CertTestDB = React.lazy(
  () => import("./components/pages/certTestDB/CertTestDB")
);

const SingleVarAnalysis = React.lazy(
  () => import("./components/pages/singleVarAnalysis/SingleVarAnalysis")
);

const ChartTest = React.lazy(
  () => import("./components/pages/charttest/ScatterPlot")
);

const DownloadCenter = React.lazy(
  () => import('./components/pages/downloadCenter/DownloadCenter')
)

const App = (): JSX.Element => {
  // const title = "AHRI Analytics App";
  // useEffect(() => {
  //   // Init Materialize JS
  //   M.AutoInit();
  // }, []);
  const { store, persistor } = configureStore();
  const purgeState = async (): Promise<void> => {
    await persistor.purge();
  };
  purgeState();
  // usePageTracking();
  // process.env.NODE_ENV !== 'development' && ReactGA.initialize('G-1ZRB6DTELD');
  // process.env.NODE_ENV !== 'development' && ReactGA.pageview(window.location.pageName + window.location.search);
  // // global state regarding loading
  // const client = useApolloClient();
  // const [loading, setLoading] = React.useState({});

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                {/* <SnackbarProvider maxSnack={3}> */}
                <ScrollIntoView>
                  <div className="App">
                    <Header title="AHRI Analytics App" />

                    <div className="container">
                      <Alert />
                      <DisclaimerBase />

                      {/* <QuickNotice
                        title={'Maintenance Notice'}
                        msg={
                          <p>
                            AHRI Network is currently under <em>maintenance</em>.
                            As a result, several functionalities in this App are
                            not available now. This App will continue to display
                            this message until we finish the maintenance. <br />
                            Thanks for your understanding.
                          </p>
                        }
                      /> */}
                      <React.Suspense fallback={suspenseLoading}>
                        <Switch>
                          <Route exact path="/" component={LandingPage}></Route>
                          {/* <PrivateRoute
                            exact
                            path='/dashboard'
                            component={Dashboard}
                          ></PrivateRoute> */}
                          <Route
                            exact
                            path="/aboutanalytics"
                            component={AboutAnalytics}
                          ></Route>
                          <Route
                            exact
                            path="/Restricted"
                            component={noPermission}
                          ></Route>
                          <Route
                            exact
                            path="/ContentRestricted"
                            component={noPageAccess}
                          ></Route>
                          {/* <Route
                          exact
                          path='/aboutnewcert'
                          component={AboutNewCert}
                        ></Route> */}
                          {/* <Route exact path='/aboutcert' component={AboutCert}></Route> */}
                          {/* <Route
                          exact
                          path='/aboutahri'
                          component={AboutAhri}
                        ></Route> */}
                          {/* <Route
                          exact
                          path='/register'
                          component={Register}
                        ></Route> */}
                          <Route exact path="/login" component={Login}></Route>
                          <Route exact path="/Slider" component={Slider}></Route>

                          {/* <PrivateRoute exact path='/' component={Home}></PrivateRoute> */}
                          <PrivateRoute
                            exact
                            path="/certdb"
                            pageName="Audit Test DB"
                            component={CertTestDB}
                          ></PrivateRoute>

                          <PrivateRoute
                            exact
                            path="/logout"
                            component={Logout}
                          ></PrivateRoute>
                          <PrivateRoute
                            exact
                            path="/Apologies"
                            component={Apologies}
                          ></PrivateRoute>

                          <PrivateRoute
                            exact
                            path="/Tracking"
                            component={tracking}
                          ></PrivateRoute>
                          <PrivateRoute
                            exact
                            path="/audit/:ahritestnumber"
                            pageName="Test Report"
                            component={Audit}
                          ></PrivateRoute>
                          <PrivateRoute
                            exact
                            path="/account"
                            component={MyAccount}
                          ></PrivateRoute>
                          {/* <PrivateRoute
                          exact
                          path='/Restricted'
                          component={noPermission}
                        ></PrivateRoute> */}

                          <PrivateRoute
                            exact
                            path="/dashboard"
                            pageName="Dashboard"
                            component={TestProgress}
                          ></PrivateRoute>

                          <PrivateRoute
                            exact
                            path="/testresults"
                            pageName="Test Failure Rate Analysis"
                            component={TestResults}
                          ></PrivateRoute>
                          <PrivateRoute
                            exact
                            path="/decisions"
                            pageName="Rerates/Failure Decisions"
                            component={PostFailureDecisions}
                          ></PrivateRoute>
                          <PrivateRoute
                            exact
                            path="/selectionInfo"
                            pageName="Selection Information"
                            component={SelectionInformation}
                          ></PrivateRoute>

                          <PrivateRoute
                            exact
                            path="/performanceAnalysis"
                            pageName="Performance/Risk Analysis"
                            component={PerformanceAnalysis}
                          ></PrivateRoute>

                          <PrivateRoute
                            exact
                            path="/analytictools"
                            pageName="Analytic Tools"
                            component={SingleVarAnalysis}
                          ></PrivateRoute>
                          <PrivateRoute
                            exact
                            path="/scatter"
                            pageName="Data Analysis/Screening"
                            component={ChartTest}
                          ></PrivateRoute>
                          <PrivateRoute
                            exact
                            path='/downloads'
                            pageName='Download Center'
                            component={DownloadCenter}
                          ></PrivateRoute>
                          {/* <PrivateRoute
                            exact
                            path='/PerformanceAward'
                            component={PerformanceAward}
                          ></PrivateRoute> */}
                          {/* <PrivateRoute
                            exact
                            path='/submit'
                            component={Submit}
                          ></PrivateRoute> */}

                          {/* <Route
                          exact
                          path="/d3"
                          component={Test}
                        ></Route> */}
                          <Route
                            exact
                            path="/contactus"
                            component={ContactUs}
                          ></Route>
                          <Route component={NotFound} />
                        </Switch>
                      </React.Suspense>
                      <CookieBar />
                      <Footer />
                    </div>
                  </div>
                </ScrollIntoView>
                {/* </SnackbarProvider> */}
              </PersistGate>
            </Provider>
          </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
