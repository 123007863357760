import React, { useState } from 'react';
import { connect } from 'react-redux';
// import { loadUser } from "../../../actions/authActions";
import axios from 'axios';


// import SendRequest from "../../layout/sendRequest/SendRequest";

// Material UI

import { useTheme } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// import ButtonArrow from "./ui/ButtonArrow";

// Material Icons
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
//styled components
import { Backgroundd,SendButton,LearnButton, Message, EstimateButton } from './contactUsMakeStyles';

const ContactUs = (props) => {
  // const { isAuthenticated } = props;
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  const matchesXS = useMediaQuery(theme.breakpoints.down('sm'));

  const [state, setState] = useState({
    name: '',
    company: '',
    email: '',
    emailHelper: '',
    phone: '',
    phoneHelper: '',
    participant: false,
    message: '',
    open: false,
    loading: false,
    alert: { open: false, color: '' },
    alertMessage: '',
  });

  const handleChange = (event) => {
    setState({ ...state, participant: event.target.checked });
  };

  const onChange = (event) => {
    let valid;

    switch (event.target.id) {
      case 'email':
        valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          event.target.value
        );

        if (!valid && state.email.length !== 0) {
          // setState({ ...state, });
          setState({
            ...state,
            email: event.target.value,
            emailHelper: 'Invalid email',
          });
        } else {
          setState({ ...state, email: event.target.value, emailHelper: '' });
        }
        break;
      case 'phone':
        valid = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
          event.target.value
        );

        if (!valid && event.target.value.length !== 0) {
          setState({
            ...state,
            phone: event.target.value,
            phoneHelper: 'Invalid phone',
          });
        } else {
          setState({ ...state, phone: event.target.value, phoneHelper: '' });
        }
        break;
      default:
        break;
    }
  };

  const onConfirm = () => {
    setState({ ...state, loading: true });

    axios
      .get(
        'https://us-central1-ahrianalyticspage.cloudfunctions.net/sendMail',
        {
          params: {
            email: state.email,
            name: state.name,
            company: state.company,
            phone: state.phone,
            message: state.message,
            isParticipant: state.participant,
          },
        }
      )
      .then((res) => {
        setState({
          ...state,
          name: '',
          company: '',
          email: '',
          phone: '',
          participant: false,
          message: '',
          open: false,
          loading: false,
          alert: { open: true, color: '#15b7b9' },
          alertMessage: 'Message sent successfully!',
        });
      })
      .catch((err) => {
        setState({
          ...state,
          open: false,
          loading: false,
          alert: { open: true, color: '#e84a5f' },
          alertMessage: 'Something went wrong! Please try again.',
        });
      });
  };

  const buttonContents = (
    <React.Fragment>
      Send Message
      <SendIcon style={{ marginLeft: '0.5rem' }} />
    </React.Fragment>
  );

  const dialogueContent = (
    <Dialog
      style={{ zIndex: 1302 }}
      open={state.open}
      fullScreen={matchesSM}
      onClose={() =>
        setState({
          ...state,
          open: false,
        })
      }
      PaperProps={{
        style: {
          paddingTop: matchesXS ? '1em' : '5em',
          paddingBottom: matchesXS ? '1em' : '5em',
          paddingLeft: matchesXS
            ? 0
            : matchesSM
            ? 0
            : matchesMD
            ? '15em'
            : '25em',
          paddingRight: matchesXS
            ? 0
            : matchesSM
            ? 0
            : matchesMD
            ? '15em'
            : '25em',
        },
      }}
    >
      <DialogContent>
        <Grid container direction='column'>
          <Grid item>
            <Typography align='center' variant='h4' gutterBottom>
              Confirm Message
            </Typography>
          </Grid>
          <Grid item style={{ marginBottom: '0.5em' }}>
            <TextField
              label='Name'
              id='name'
              fullWidth
              required
              value={state.name}
              onChange={(event) =>
                setState({ ...state, name: event.target.value })
              }
            />
          </Grid>
          <Grid item style={{ marginBottom: '0.5em' }}>
            <TextField
              label='Company'
              id='company'
              required
              fullWidth
              value={state.company}
              onChange={(event) =>
                setState({ ...state, company: event.target.value })
              }
            />
          </Grid>
          <Grid item style={{ marginBottom: '0.5em' }}>
            <TextField
              label='Email'
              error={state.emailHelper.length !== 0}
              helperText={state.emailHelper}
              id='email'
              fullWidth
              required
              value={state.email}
              onChange={onChange}
            />
          </Grid>
          <Grid item style={{ marginBottom: '0.5em' }}>
            <TextField
              label='Phone'
              helperText={state.phoneHelper}
              error={state.phoneHelper.length !== 0}
              id='phone'
              fullWidth
              value={state.phone}
              onChange={onChange}
            />
          </Grid>
          <Grid item style={{ marginBottom: '0.5em' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.participant}
                  onChange={handleChange}
                  name='checked'
                  color='primary'
                />
              }
              label={
                <Typography
                  align={matchesMD ? 'center' : undefined}
                  variant='body1'
                  style={{ color: theme.palette.common.blue }}
                >
                  AHRI Member or Participant
                </Typography>
              }
            />
          </Grid>
        </Grid>
        <Grid item style={{ width: matchesSM ? '100%' : '20em' }}>
          <Message
            InputProps={{ disableUnderline: true }}
            value={state.message}
            multiline
            fullWidth
            rows={10}
            id='message'
            onChange={(event) =>
              setState({ ...state, message: event.target.value })
            }
          />
        </Grid>
        <Grid
          item
          container
          direction={matchesSM ? 'column' : 'row'}
          style={{ marginTop: '2em' }}
          alignItems='center'
        >
          <Grid item>
            <Button
              style={{ fontWeight: 300 }}
              color='primary'
              // open={state.open}
              onClick={() => setState({ ...state, open: false })}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <SendButton
              disabled={
                state.name.length === 0 ||
                state.message.length === 0 ||
                state.phoneHelper.length !== 0 ||
                state.emailHelper.length !== 0
              }
              variant='contained'
              onClick={onConfirm}
            >
              {state.loading ? (
                <CircularProgress color='white' size={30} />
              ) : (
                buttonContents
              )}
            </SendButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  return (
    <div>
      <Grid container direction='row'>
        <Grid
          item
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          style={{
            marginBottom: matchesMD ? '5em' : 0,
            marginTop: matchesSM ? '1em' : matchesMD ? '3em' : 0,
          }}
          lg={4}
          xl={3}
        >
          <Grid item>
            <Grid container direction='column'>
              <Grid item style={{ marginTop: '-0.9em' }}>
                <Typography
                  align={matchesMD ? 'center' : undefined}
                  variant='h2'
                  style={{ lineHeight: 1 }}
                >
                  Contact Us
                </Typography>
                <Typography
                  align={matchesMD ? 'center' : undefined}
                  variant='body1'
                  style={{ color: theme.palette.common.blue }}
                >
                  We are here to help!
                </Typography>
              </Grid>
              <Grid item container style={{ marginTop: '0.3em' }}>
                <Grid item>
                  <EmailIcon color='primary' />
                </Grid>
                <Grid item>
                  <Typography
                    variant='body1'
                    style={{
                      color: theme.palette.common.blue,
                      fontSize: '1rem',
                    }}
                  >
                    <a
                      href='mailto:ahrianalytics@ahrinet.org'
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      ahrianalytics@ahrinet.org
                    </a>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ marginBottom: '0.3em' }}>
                <TextField
                  label='Name'
                  id='name'
                  fullWidth
                  required
                  value={state.name}
                  onChange={(event) =>
                    setState({ ...state, name: event.target.value })
                  }
                />
              </Grid>
              <Grid item style={{ marginBottom: '0.3em' }}>
                <TextField
                  label='Company'
                  id='company'
                  required
                  fullWidth
                  value={state.company}
                  onChange={(event) =>
                    setState({ ...state, company: event.target.value })
                  }
                />
              </Grid>
              <Grid item style={{ marginBottom: '0.3em' }}>
                <TextField
                  label='Email'
                  error={state.emailHelper.length !== 0}
                  helperText={state.emailHelper}
                  id='email'
                  fullWidth
                  required
                  value={state.email}
                  onChange={onChange}
                />
              </Grid>
              <Grid item style={{ marginBottom: '0.3em' }}>
                <TextField
                  label='Phone'
                  helperText={state.phoneHelper}
                  error={state.phoneHelper.length !== 0}
                  id='phone'
                  fullWidth
                  value={state.phone}
                  onChange={onChange}
                />
              </Grid>
              <Grid item style={{ marginBottom: '0.3em' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.participant}
                      onChange={handleChange}
                      name='checked'
                      color='primary'
                    />
                  }
                  label={
                    <Typography
                      align={matchesMD ? 'center' : undefined}
                      variant='body1'
                      style={{ color: theme.palette.common.blue }}
                    >
                      AHRI Member or Participant
                    </Typography>
                  }
                />
              </Grid>

              <Grid item container direction='column' style={{ width: '20em' }}>
                <Grid item style={{ width: matchesSM ? '100%' : '20em' }}>
                  <Message
                    InputProps={{ disableUnderline: true }}
                    value={state.message}
                    multiline
                    fullWidth
                    rows={10}
                    id='message'
                    onChange={(event) =>
                      setState({ ...state, message: event.target.value })
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent='center'
                style={{ marginTop: '1em', marginBottom: '1em' }}
              >
                <Grid item>
                  <SendButton
                    disabled={
                      state.name.length === 0 ||
                      state.message.length === 0 ||
                      state.phoneHelper.length !== 0 ||
                      state.emailHelper.length !== 0
                    }
                    variant='contained'
                    onClick={() => setState({ ...state, open: true })}
                  >
                    {buttonContents}
                  </SendButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {dialogueContent}
        <Snackbar
          open={state.alert.open}
          ContentProps={{
            style: {
              backgroundColor: state.alert.color,
            },
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={state.alertMessage}
          autoHideDuration={4000}
          onClose={() => setState({ ...state, alert: false })}
        />
        <Backgroundd
          item
          container
          direction={matchesMD ? 'column' : 'row'}
          alignItems='center'
          justifyContent={matchesMD ? 'center' : undefined}
          lg={8}
          xl={9}
        >
          <Grid
            item
            style={{
              marginLeft: matchesMD ? 0 : '3em',
              textAlign: matchesMD ? 'center' : 'inherit',
            }}
          >
            {/* {isAuthenticated && (
              <Grid item>
                <SendRequest />
              </Grid>
            )} */}
          </Grid>
          </Backgroundd>
        </Grid>  
    </div>
  );
};

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  token: state.auth.token,
  selectedTab: state.page.selectedTab,
  selectedIndex: state.page.selectedIndex,
});

export default connect(mapStatetoProps, {})(ContactUs);
