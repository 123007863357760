import {
  SET_SELECTED_TAB,
  CLEAR_SELECTED_TAB,
  SET_SELECTED_INDEX, // this is in Analytics Menu
  CLEAR_SELECTED_INDEX,
  // GET_NEWSVALUE,
  SET_NEWSVALUE,
  SET_MARGIN_VALUE,
  SET_NOTI_HEIGHT,
  SET_PAGE_LOADING,
  SET_CERT_DB,
  SET_PERF_AWARD,
  SET_SCATTER_DATA,
  SET_FAIL_DATA,
  SET_TOP_HEIGHT,
} from './types';

export const setFailData = (failData: object ) => {
  return { type: SET_FAIL_DATA, payload: failData };
};
export const setCertDB = (dbValue: object ) => {
  return { type: SET_CERT_DB, payload: dbValue };
};
export const setPerfAward = (awardValue: object ) => {
  return { type: SET_PERF_AWARD, payload: awardValue };
};
export const setScatterData = (data: object ) => {
  return { type: SET_SCATTER_DATA, payload: data };
};

export const setNewsValue = (newsValue: boolean ) => {
  return { type: SET_NEWSVALUE, payload: newsValue };
};

export const setMarginValue = (marginValue: boolean ) => {
  return { type: SET_MARGIN_VALUE, payload: marginValue };
};

export const setNotiHeight = (notiheight: number ) => {
  return { type: SET_NOTI_HEIGHT, payload: notiheight };
};
export const setTopHeight = (topHeight: boolean ) => {
  return { type: SET_TOP_HEIGHT, payload: topHeight };
};

export const setSelectedTab = (pageValue: string | number) => {
  return { type: SET_SELECTED_TAB, payload: pageValue };
};

export const clearSelectedTab = (pageValue: string | number) => {
  return { type: CLEAR_SELECTED_TAB, payload: pageValue };
};

export const setSelectedIndex = (menuIndex: string | number) => {
  return { type: SET_SELECTED_INDEX, payload: menuIndex };
};

export const clearSelectedIndex = (menuIndex: string | number) => {
  return { type: CLEAR_SELECTED_INDEX, payload: menuIndex };
};

export const setPageLoading = (loading: boolean ) => {
  return { type: SET_PAGE_LOADING, payload: loading };
};
