import {
  SEARCH_AUDITS,
  SET_LOADING,
  CLEAR_AUDITS,
  GET_AUDIT,
  AUDIT_ERROR,
  // GET_ANALYTICS,
} from '../actions/types';

const initialState = {
  audits: [],
  audit: {},
  // analytics: [],
  loading: false,
};

interface Action {
  type: string;
  payload: string;
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case SEARCH_AUDITS:
      return {
        ...state,
        audits: action.payload,
        loading: false,
      };
    case CLEAR_AUDITS:
      return {
        ...state,
        audits: [],
        loading: false,
      };
    case SET_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_AUDIT:
      return {
        ...state,
        audit: action.payload,
        loading: false,
      };
    case AUDIT_ERROR:
      console.error(action.payload);
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
