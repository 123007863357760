// Round 1
// make a boilerplate by referencing audit context
import { SET_ALERT, REMOVE_ALERT } from '../actions/types';

interface Alert {
  id: string;
  payload: string;
}

// this can be more specific by being
// OneAction | TwoAction
interface Action {
  type: string;
  payload: string;
}

const initialState: Alert[] = [];

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_ALERT:
      return [...state, action.payload];
    // return {
    //   ...state,
    //   alert: action.payload,
    // };
    case REMOVE_ALERT:
      return state.filter((alert) => alert.id !== action.payload);
    // return {
    //   ...state,
    //   alert: null,
    // };
    default:
      return state;
  }
};
