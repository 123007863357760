export const SEARCH_AUDITS = 'SEARCH_AUDITS';
export const GET_AUDIT = 'GET_AUDIT';
export const CLEAR_AUDITS = 'CLEAR_AUDITS';
export const GET_ANALYTICS = 'GET_ANALYTICS';
export const SET_LOADING = 'SET_LOADING';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const AUDIT_ERROR = 'AUDIT_ERROR';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const CLEAR_SELECTED_TAB = 'CLEAR_SELECTED_TAB';
export const SET_SELECTED_INDEX = 'SET_SELECTED_INDEX';
export const CLEAR_SELECTED_INDEX = 'CLEAR_SELECTED_INDEX';

export const GET_ALLPARENTS = 'GET_PARENTS';
export const SET_PARENT = 'SET_PARENT';
export const GET_PARTICIPANT = 'GET_PARTICIPANT';
export const SET_PARTICIPANT = 'SET_PARTICIPANT';
export const FILTER_ERROR = 'FILTER_ERROR';
export const GET_YEAR = 'GET_YEAR';
export const SET_YEAR = 'SET_YEAR';
export const GET_PROGRAM = 'GET_PROGRAM';
export const SET_PROGRAM = 'SET_PROGRAM';
export const RESET_LOADING = 'RESET_LOADING';
export const GET_NEWSVALUE = 'GET_NEWSVALUE';
export const SET_NEWSVALUE = 'SET_NEWSVALUE';
export const SET_MARGIN_VALUE = 'SET_MARGIN_VALUE' ;
export const SET_NOTI_HEIGHT = 'SET_NOTI_HEIGHT' ;
export const SET_PAGE_LOADING = 'SET_PAGE_LOADING';
export const SET_CERT_DB = 'SET_CERT_DB';
export const SET_PERF_AWARD = 'SET_PERF_AWARD';
export const SET_SCATTER_DATA = 'SET_SCATTER_DATA';
export const SET_FAIL_DATA = 'SET_FAIL_DATA';
export const SET_TOP_HEIGHT = 'SET_TOP_HEIGHT';