
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// import certBackground from '../../../assets/lines-in-motion/line-in-motion.svg';
// import certBackground from '../../../assets/img/certLogoTaglineSm2.svg';
import certBackground from '../../../assets/img/certLogo_notag_sm2.svg';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';


export const DivDisclaimer = styled('div')(({ theme }) => ({
  display: 'flex',
    flexDirection: 'column',
    textAlign: 'justify',
    alignItems: 'center',
}));

export const DisclaimerTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '1em',
}));

export const DisclaimerContent = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
    fontFamily: 'Noto Serif',
    martinTop: '1rem',
    marginBottom: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
}));

export const SendButton = styled(Button)(({ theme }) => ({
  ...theme.typography.estimate,
    color: 'white',
    height: 45,
    width: 245,
    fontSize: '1rem',
    backgroundColor: theme.palette.common.blue,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.down('md')]: {
      height: 40,
      width: 225,
    },
}));

export const MainContainer = styled(Grid)(({ theme }) => ({
  // marginTop: "5em",
  [theme.breakpoints.down('lg')]: {
    // marginTop: '3em',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '2em',
  },
}));





// export const useStyles = makeStyles((theme) => ({

//   disclaimer: {
//     display: 'flex',
//     flexDirection: 'column',
//     textAlign: 'justify',
//     alignItems: 'center',
//   },
//   disclaimerTitle: { marginBottom: '1em' },
//   disclaimerContent: {
//     textAlign: 'left',
//     fontFamily: 'Noto Serif',
//     martinTop: '1rem',
//     marginBottom: '1rem',
//     marginLeft: '1rem',
//     marginRight: '1rem',
//   },
//   sendButton: {
//     ...theme.typography.estimate,
//     color: 'white',
//     height: 45,
//     width: 245,
//     fontSize: '1rem',
//     backgroundColor: theme.palette.common.blue,
//     '&:hover': {
//       backgroundColor: theme.palette.primary.light,
//     },
//     [theme.breakpoints.down('md')]: {
//       height: 40,
//       width: 225,
//     },
//   },
//   mainContainer: {
//     // marginTop: "5em",
//     [theme.breakpoints.down('lg')]: {
//       // marginTop: '3em',
//     },
//     [theme.breakpoints.down('sm')]: {
//       marginTop: '2em',
//     },
//   },
//   certifiedLogo: {
//     // position: 'absolute',
//     marginTop: '0rem',
//     marginRight: '1rem',
//     height: '8rem',
//     [theme.breakpoints.down('lg')]: { height: '8.0rem' },
//     [theme.breakpoints.down('sm')]: {
//       marginTop: '0.0rem',
//       marginRight: '0rem',
//       // paddingRight: '-2rem',
//       marginBottom: '1rem',
//       height: '5rem',
//       width: '12.0rem',
//     },
//   },
//   logo: {
//     height: '15rem',
//     [theme.breakpoints.down('lg')]: { height: '10.0rem' },
//     [theme.breakpoints.down('sm')]: { height: '6.0rem' },
//   },
//   animation: {
//     maxWidth: '40em',
//     minWidth: '10em',
//     marginTop: '2em',
//     // marginLeft: "10%",
//     '&.MuiGrid-item': {
//       [theme.breakpoints.down('lg')]: {
//         maxWidth: '30em',
//       },
//       [theme.breakpoints.down('md')]: {
//         maxWidth: '30em',
//         minWidth: '30em',
//       },
//       [theme.breakpoints.down('sm')]: {
//         marginTop: '-10%',
//         minWidth: '10em',
//         zIndex: 0,
//         // maxWidth: "30em",
//       },
//     },
//   },
//   certDbAnimation: {
//     '&.MuiGrid-item': {
//       // marginTop: "-10%",
//       maxWidth: '150%',
//       minWidth: '35em',
//       marginLeft: '-10%',
//       [theme.breakpoints.down('md')]: {
//         // marginTop: "-5%",
//         marginLeft: '-10%',
//         marginRight: '-10%',
//         maxWidth: '30em',
//         minWidth: '25em',
//       },
//       [theme.breakpoints.down('sm')]: {
//         marginTop: '-10%',
//         marginLeft: '-10%',
//         marginRight: '-10%',
//         maxWidth: '30em',
//         minWidth: '20em',
//       },
//     },
//     maxWidth: '150%',
//   },
//   dataDrillAdAnimation: {
//     '&.MuiGrid-item': {
//       marginTop: '-10%',
//       maxWidth: '150%',
//       minWidth: '35em',
//       marginLeft: '-10%',
//       [theme.breakpoints.down('md')]: {
//         // marginTop: "-5%",
//         marginLeft: '-10%',
//         marginRight: '-10%',
//         maxWidth: '30em',
//         minWidth: '25em',
//       },
//       [theme.breakpoints.down('sm')]: {
//         marginTop: '-10%',
//         marginLeft: 0,
//         marginRight: 0,
//         maxWidth: '30em',
//         minWidth: '7em',
//       },
//     },
//     maxWidth: '150%',
//   },
//   predictiveAnimation: {
//     '&.MuiGrid-item': {
//       // marginTop: "-10%",
//       maxWidth: '20rem',
//       minWidth: '15rem',
//       // marginLeft: "-10%",
//       [theme.breakpoints.down('md')]: {
//         // marginTop: "-5%",
//         // marginLeft: "-10%",
//         // marginRight: "-10%",
//         maxWidth: '15rem',
//         minWidth: '10rem',
//       },
//       [theme.breakpoints.down('sm')]: {
//         marginTop: '2rem',
//         // marginLeft: "-10%",
//         // marginRight: "-10%",
//         maxWidth: '15rem',
//         minWidth: '10rem',
//       },
//     },
//     // maxWidth: "150%",
//   },
//   dashAdButton: {
//     '&.MuiButton-contained': {
//       backgroundColor: theme.palette.common.blue,
//       color: 'white',
//       '&:hover': {
//         backgroundColor: theme.palette.primary.light,
//       },
//     },
//     ...theme.typography.estimate,
//     borderRadius: 50,
//     height: 45,
//     width: 160,
//     marginRight: 40,
//     zIndex: 1,
//     // maxWidth: "30em",
//   },
//   analyticsAdButton: {
//     '&.MuiButton-contained': {
//       backgroundColor: theme.palette.common.blue,
//       color: 'white',
//       marginTop: '1rem',
//       '&:hover': {
//         backgroundColor: theme.palette.primary.light,
//       },
//     },
//     ...theme.typography.estimate,
//     borderRadius: 50,
//     height: 45,
//     width: 160,
//     marginRight: 40,
//   },
//   buttonContainer: {
//     marginTop: '1em',
//   },
//   learnButton: {
//     '&.MuiButton-root': {
//       ...theme.typography.learnButton,
//     },

//     fontSize: '0.9rem',
//     height: 45,
//     width: 160,
//     zIndex: 1,
//     // maxWidth: "30em",
//   },
//   // learnButton: {
//   //   ...theme.typography.learnButton,
//   //   fontSize: "0.7rem",
//   //   height: 35,
//   //   padding: 5,
//   //   [theme.breakpoints.down("sm")]: {
//   //     marginBottom: "2em",
//   //   },
//   // },

//   dashAdTextContainer: {
//     '&.MuiGrid-item': {
//       // marginTop: "-10%",

//       [theme.breakpoints.down('md')]: {
//         marginLeft: '0.2em',
//         marginRight: '0em',
//       },
//       [theme.breakpoints.down('sm')]: {
//         marginTop: 0,
//         marginLeft: 0,
//       },
//     },
//     // minWidth: "21.5em",
//     marginLeft: '1em',
//     marginRight: '1em',
//   },
//   dataDrillAdTextContainer: {
//     '&.MuiGrid-item': {
//       marginTop: '-10%',
//       [theme.breakpoints.down('md')]: {
//         marginLeft: '0.5em',
//         marginRight: '0.5em',
//       },
//       [theme.breakpoints.down('sm')]: {
//         marginLeft: '1em',
//         marginRight: '1em',
//       },
//       marginLeft: '1em',
//       marginRight: '1em',
//     },
//     // minWidth: "21.5em",
//     marginLeft: '1em',
//     marginRight: '1em',
//   },
//   predictiveAdTextContainer: {
//     '&.MuiGrid-item': {
//       // marginTop: "-10%",
//       [theme.breakpoints.down('md')]: {
//         marginLeft: '0em',
//         marginRight: '0em',
//       },
//       [theme.breakpoints.down('sm')]: {
//         marginLeft: 0,
//       },
//     },
//     // minWidth: "21.5em",
//     marginLeft: '1em',
//     marginRight: '1em',
//   },
//   specialText: {
//     fontFamily: 'Pacifico',
//     color: theme.palette.common.orange,
//   },
//   subtitle: {
//     '&.MuiTypography-root': {
//       marginBottom: '1em',
//     },
//   },
//   icon: {
//     marginLeft: '2em',
//     [theme.breakpoints.down('sm')]: {
//       marginLeft: 0,
//     },
//   },
//   serviceContainer: {
//     marginTop: '12em',
//     [theme.breakpoints.down('md')]: {
//       padding: 25,
//     },
//   },
//   // newCertBackground: {
//   //   backgroundImage: `url(${certBackground})`,
//   //   backgroundPosition: 'center',
//   //   // backgroundSize: "cover",
//   //   backgroundRepeat: 'repeated',
//   //   height: '100%',
//   //   width: '100%',
//   // },
//   newCertBackground: {
//     backgroundImage: `url(${certBackground})`,
//     // backgroundColor: '#ffcf9f',
//     backgroundPosition: 'center',
//     // backgroundSize: 'cover',
//     backgroundAttachment: 'fixed',
//     backgroundRepeat: 'repeated', //'no-repeat',
//     // marginTop: -200,
//     height: '60em',
//     width: '100%',
//     // transform: 'rotate(30deg)',
//     [theme.breakpoints.down('sm')]: {
//       backgroundImage: `url(${certBackground})`,
//       backgroundAttachment: 'inherit',
//       backgroundRepeat: 'round', //'no-repeat','round', 'center', 'space'
//       backgroundSize: '60%',
//     },
//     // [theme.breakpoints.down('md')]: {
//     //   backgroundImage: `url(${certBackground})`,
//     //   backgroundAttachment: 'inherit',
//     //   backgroundRepeat: 'round', //'no-repeat','round', 'center', 'space'
//     //   backgroundSize: '30%',
//     // },
//   },
//   newCertCard: {
//     position: 'absolute',
//     boxShadow: theme.shadows[10],
//     borderRadius: 15,
//     padding: '10em',
//     [theme.breakpoints.down('md')]: {
//       paddingTop: '8em',
//       paddingBottom: '8em',
//       paddingLeft: 0,
//       paddingRight: 0,
//       borderRadius: 0,
//       width: '100%',
//     },
//   },
//   // infoBackground: {
//   //   backgroundImage: `url(${infoBackground})`,
//   //   backgroundPosition: "center",
//   //   backgroundSize: "cover",
//   //   backgroundRepeat: "no-repeat",
//   //   height: "100%",
//   //   width: "100%",
//   // },
 
// }));
