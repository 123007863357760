import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  split,
  // NormalizedCacheObject, // need for persisting cache
} from '@apollo/client';
// import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import cache from './cache';
// import { // uncomment to persist cache
//   PersistentStorage,
//   PersistedData,
// } from 'apollo-cache-persist-dev/types';

// import { persistCache } from 'apollo-cache-persist-dev'; // uncomment to persist cache
import { isLoggedIn, getAccessToken } from './auth';
import { resolvers } from './localResolver';
import { typeDefs } from './localSchema';
// import config from "config";
// const httpUrl = 'http://192.168.1.187:9000/graphql';
// const httpUrl = 'http://localhost:62753/graphql';
const httpUrl = 'https://portal.ahrianalytics.org/api/graphql';

// DO NOT DELETE THIS IS FOR FULL STACK
// const httpUrl =
//   process.env.NODE_ENV === 'development'
//     ? 'http://localhost:9000/graphql' // 'http://192.168.1.187:9000/api/graphql' // for development with public IP (npm run dev_start)
//     : 'https://portal.ahrianalytics.org/api/graphql';

// websocket url
const wsUrl = 'ws://portal.ahrianalytics.org/api/graphql';

// DO NOT DELETE THIS IS FOR FULL STACK
// const wsUrl =
//   process.env.NODE_ENV === 'development'
//     ? 'ws://localhost:9000/api/graphql' // 'http://192.168.1.187:9000/api/graphql'
//     : 'ws://portal.ahrianalytics.org/api/graphql';

// for local state management cache can be defined in a variable
// const cache = new InMemoryCache();

// await before instantiating ApolloClient, else queries might run before the cache is persisted
// persistCache({
//   cache,
//   storage: window.localStorage,
//   //as PersistentStorage<PersistedData<NormalizedCacheObject>>,
// });

// middleware/interceptor
const responseLogger = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    let { response } = operation.getContext();
    if (
      response.status === 404 ||
      response.status === 500 ||
      response.status === 503
    ) {
      window.location = '/Apologies';
    }
    return result;
  });
});

// make authorization first by preparing header
const httpLink = new ApolloLink.from([
  responseLogger,
  new ApolloLink((operation, forward) => {
    if (isLoggedIn()) {
      // request.headers['authorization'] = 'Bearer ' + getAccessToken();
      operation.setContext({
        headers: {
          authorization: 'Bearer ' + getAccessToken(),
        },
      });
    }
    return forward(operation);
  }),
  new HttpLink({ uri: httpUrl }),
]);

// CHECK responseLogger affects the connection
// add websocket link
const wsLink = new ApolloLink.from([
  responseLogger,
  new WebSocketLink({
    uri: wsUrl,
    options: {
      // the following has bug. getAccessToken() is loaded at the first script load
      // making accessToken null when non-logged-in.
      // connectionParams: {
      //   accessToken: getAccessToken()
      // },

      // solution: make it on the function form
      connectionParams: () => ({
        accessToken: getAccessToken(),
      }),
      lazy: true,
      reconnect: true,
    },
  }),
]);
// chaining authorization and normal HttpLink
// header is made then HttpLink is made
// export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
//   link: ApolloLink.from([authLink, new HttpLink({ uri: httpUrl })]),
//   cache,
//   resolvers,
//   typeDefs,
// });

function isSubcription(operation) {
  const definition = getMainDefinition(operation.query);
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
}

export const client = new ApolloClient({
  link: split(isSubcription, wsLink, httpLink),
  cache: cache,
  resolvers,
  typeDefs,
});
