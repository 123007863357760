import React, {
  useState,
  useEffect,
  Fragment,
  useMemo,
  useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import HeaderGuide from './HeaderGuide';
// import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';
import { getDirectoryDownloadLink } from '../../../graphql/queries';
import ReactGA from 'react-ga';

// Mui5 and styles
import AppBar from '@mui/material/AppBar';
import ToolBar from '@mui/material/Toolbar';
import Fab from '@mui/material/Fab';
import Toolbar from '@mui/material/Toolbar';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/system';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemText from '@mui/material/ListItemText';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Accordion } from '@mui/material';
// import { AccordionSummary } from '@mui/material';
// import { AccordionDetails } from '@mui/material';
// dependency imports
import logo from '../../../assets/AHRI_WHITE_ONLY_LOGO_ONLY.svg';
import { LogoContainer, ToolbarMargin, useTabStyles } from './headerMakeStyles';
import { a11yProps, ElevationScroll, ScrollTop } from './headerFunctions';
import Disclaimer from '../disclaimer/Disclaimer';

// redux
import { connect } from 'react-redux';
import { logout, loadUser } from '../../../actions/authActions';
import { setSelectedTab, setSelectedIndex } from '../../../actions/pageActions';

import usePageTracking from '../../googleAnalytics/usePageTracking';
import InfoBar from '../InfoBar/InfoBar';
import FilterBar from './filterBar';
import { setNewsValue, setMarginValue } from '../../../actions/pageActions';

import { makeVar } from '@apollo/client';
import { analyticsMenuOptionsLoggedIn } from './analyticsMenuOptions';
import { analyticsMenuOptionsUserSpecific } from './analyticsMenuOptionsUserSpecific';
import { guestRoutes } from './guestRoutes';
import { dashboardMenuOptions } from './dashboardMenuOptions';
import { ahriBlue, ahriOrange, ahriWhite } from '../../theme/Theme';
import {
  TabRoot,
  TabContainer,
  AccordionExpansion,
  AccordionExpansionDetails,
  AccordionExpansionSummary,
  DisclaimerButton,
  PaperMenuTabScrollTop,
  TabTransition,
  TabBox,
  DrawerItem,
  DrawerIconContainer,
  ListItemDrawerItemSelected,
  ListItemTextDrawerItemSelected,
  DrawerIcon,
  LinkExpansionSummaryText,
  Logo,
  SwipeableDrawerStyled,
  expansionSummaryText,
  AccordianSX,
  AccordianSummarySX,
  menuItemSX,
} from './headerMakeStyles';

const uniqueServiceRoutes = [
  '/dashboard',
  '/testresults',
  '/decisions',
  '/performanceAnalysis',
  '/analytictools',
  '/certDB',
  '/scatter',
  '/selectionInfo',
  '/downloads',
];

const yearHiddenIndex = [15]; // 15: selection info

export const filterHelpUsedRV = makeVar(false);

const n_comp_displayed = 4;
const n_chr_comp_sliced = 6;

const indexNoFilterBarDisplayInAnalyticsTab = [3];

const Header = (props) => {
  let active = null;
  let timer = null;
  const {
    // title,
    isAuthenticated,
    user,
    loading,
    // logout,
    loadUser,
    // value,
    selectedTab,
    selectedIndex,
    setSelectedTab,
    setSelectedIndex,
    marginAvailable,
    notiheight,
    filterDashboard,
    filterDataAnalysis,
    filterDrillDown,
    // ...props
  } = props;

  // const {indId} = user;
  // const classes = useTabStyles(props);
  const theme = useTheme();
  const location = useLocation();
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const matchesxs = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const open = Boolean(anchorEl);
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  // var {selectedParent,selectedProgram,selectedYear} = filterDashboard
  // var selectedParent='';
  // var selectedProgram='';
  // var selectedYear='';
  var [filterState, setFilterState] = useState({
    selectedParent: null,
    selectedProgram: null,
    selectedYear: filterDashboard.selectedYear,
  });

  const handleChange = (event, newValue) => {
    setSelectedIndex(null);
    setSelectedTab(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    setOpenMenu(false);
    active = null;
  };

  const onMenuHover = (event) => {
    active = event.currentTarget.id;
  };

  const handleMenuItemClick = (event, index) => {
    setAnchorEl(null);
    setOpenMenu(false);
    setSelectedIndex(index);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  const { data } = useQuery(getDirectoryDownloadLink, {
    pollInterval: 15000000,
    skip: !isAuthenticated,
    // (!others && !requestReady.current) ||
    // !selectedField || (others && !origParticipantHistReady),
  });
  const link =
    data &&
    data.getDirectoryDownloadLink &&
    Array.isArray(data.getDirectoryDownloadLink) &&
    data.getDirectoryDownloadLink.length > 0
      ? data.getDirectoryDownloadLink
      : null;

  // !!link
  // && !!analyticsMenuOptions.findIndex(route => route.name === selectionInfoRouteName)
  // && analyticsMenuOptions.push(downloadCenterRouteInfo);

  const analyticsMenuOptions = !!link
    ? analyticsMenuOptionsUserSpecific
    : analyticsMenuOptionsLoggedIn;
  usePageTracking();

  useEffect(() => {
    if (user && isAuthenticated) {
      if (!window.location.href.includes('localhost')) {
        ReactGA.set({ userId: user.indId });
      }
    }
  }, [user, isAuthenticated]);

  const mouseOutHandler = useCallback(
    () => (href) => {
      timer = setTimeout(() => {
        if (href.indexOf(active) === -1) {
          handleClose();
        }
      }, 101);
    },
    []
  );

  const authRoutes = useMemo(
    () => [
      { name: 'Home', link: '/', activeIndex: 0 },
      {
        name: 'Dashboard',
        link: '/dashboard',
        activeIndex: 'dashboardTab',
        ariaOwns: anchorEl ? 'dashboard-menu' : undefined,
        ariaPopup: anchorEl ? 'true' : undefined,
        id: 'dashboardTab',
        mouseOver: (event) => {
          // setSelectedIndex(null);
          handleClick(event);
        },
        mouseOut: (event) => {
          mouseOutHandler(event.currentTarget.href);
        },
      },
      {
        name: 'Analytic Tools',
        link: '/analytictools',
        activeIndex: 'analyticsTab',
        ariaOwns: anchorEl ? 'simple-menu' : undefined,
        ariaPopup: anchorEl ? 'true' : undefined,
        mouseOver: (event) => {
          // setSelectedIndex(null);
          handleClick(event);
        },
        mouseOut: (event) => {
          mouseOutHandler(event.currentTarget.href);
        },
      },
      // { name: 'Notes/Logs', link: '/notes', activeIndex: 'notesTab' },
    
      { name: 'About', link: '/aboutanalytics', activeIndex: 'aboutTab' },
      // { name: 'Contact Us', link: '/contactus', activeIndex: 'contactTab' },
      {
        name: 'My Account',
        link: '/account',
        id: 'accountTab',
        activeIndex: 'accountTab',
        // onClick: () => logoutUser(), // use PrivateRoute
      },
      {
        name: 'Logout',
        link: '/logout',
        activeIndex: 'logoutTab',
        // onClick: () => logoutUser(), // use PrivateRoute
      },
    ],
    [anchorEl, notiheight]
  );

  // const hasCEOAccessAny = user.hasCEOAccess
  const hasCEOAccess = (x) => x;
  const ceoAccessTest =
    user && user.hasCeoAccess && user.hasCeoAccess.some(hasCEOAccess);
  const accountMenuOptions = ceoAccessTest
    ? [
        {
          name: 'Profile',
          link: '/datadrilldown',
          activeIndex: 'accountTab',
          selectedIndex: 21,
        },
        {
          name: 'Access Setting',
          link: '/accesssetting',
          activeIndex: 'accountTab',
          selectedIndex: 22,
        },
        {
          name: 'Logout',
          link: '/logout',
          activeIndex: 'accountTab',
          selectedIndex: 23,
          // onClick: () => logoutUser(), // use PrivateRoute
        },
      ]
    : [
        {
          name: 'Profile',
          link: '/datadrilldown',
          activeIndex: 'analyticsTab',
          selectedIndex: 21,
        },
        {
          name: 'Logout',
          link: '/logout',
          activeIndex: 'logoutTab',
          selectedIndex: 23,
          // onClick: () => logoutUser(), // use PrivateRoute
        },
      ];

  // TOOD: revise the following
  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated || localStorage.token) {
      loadUser();
    }
  }, [isAuthenticated, localStorage.token]);

  useEffect(() => {
    [
      ...dashboardMenuOptions,
      ...analyticsMenuOptions,
      // ...accountMenuOptions,
      ...authRoutes,
      ...guestRoutes,
    ].forEach((route) => {
      switch (window.location.pathname) {
        case `${route.link}`:
          if (selectedTab !== route.activeIndex) {
            setSelectedTab(route.activeIndex);
            if (route.selectedIndex && route.selectedIndex !== selectedIndex) {
              setSelectedIndex(route.selectedIndex);
            }
          }
          break;
        case '/submit':
          setSelectedTab('submitTab');
          break;
        default:
          break;
      }
    });
  }, [
    selectedTab,
    setSelectedTab,
    accountMenuOptions,
    selectedIndex,
    setSelectedIndex,
    authRoutes,
    notiheight,
  ]);

  // useEffect(() => {
  //   loadUser();
  // }, []);
  // const logoutUser = () => {
  //   logout();
  //   setSelectedTab(undefined);
  // };

  const authLink = useMemo(
    () => (
      <>
        <TabContainer
          value={selectedTab}
          onChange={(event, selectedTab) => {
            handleChange(event, selectedTab);
          }}
          indicatorColor='secondary'
          textColor='white'
          variant='scrollable'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
        >
          {authRoutes.map((route, index) => (
            <TabBox
              key={index}
              component={Link}
              to={route.link}
              label={route.name}
              value={route.activeIndex}
              id={route.id}
              {...a11yProps(route.activeIndex)}
              aria-owns={route.ariaOwns}
              aria-haspopup={route.ariaPopup}
              onTouchStart={route.mouseOver}
              onMouseOver={route.mouseOver}
              onMouseLeave={route.mouseOut}
            />
          ))}
        </TabContainer>
      </>
    ),
    [selectedTab, authRoutes, notiheight]
  );

  const guestLink = useMemo(
    () => (
      <TabContainer
        value={selectedTab}
        onChange={(event, selectedTab) => {
          handleChange(event, selectedTab);
        }}
        indicatorColor='secondary'
        textColor='white'
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable auto tabs example'
      >
        {guestRoutes.map((route, index) => (
          <TabBox
            key={index}
            component={Link}
            to={route.link}
            label={route.name}
            value={route.activeIndex}
            {...a11yProps(route.activeIndex)}
          />
        ))}
      </TabContainer>
    ),
    [selectedTab, guestRoutes, notiheight]
  );

  const dashboardExpansion = useCallback(
    (route) => {
      return (
        <Accordion
          elevation={0}
          key={route.name}
          sx={{
            ...AccordianSX,
          }}
        >
          <AccordionSummary
            sx={{
              ...AccordianSummarySX,
            }}
            expandIcon={<ExpandMoreIcon color='secondary' />}
          >
            <ListItemText
              disableTypography
              onClick={() => {
                // setOpenDrawer(false);
                setSelectedTab(route.activeIndex);
              }}
            >
              <Link
                style={{
                  opacity: selectedTab === 'dashboardTab' ? 1.0 : null,
                  ...theme.typography.tab,
                  color: 'white',
                  opacity: 0.7,

                  textDecoration: 'none',
                }}
                underline='none'
                to={route.link}
              >
                {route.name}
              </Link>
            </ListItemText>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: 0,
              backgroundColor: theme.palette.primary.light,
              zIndex: 3000,
            }}
          >
            <Grid container direction='column'>
              {dashboardMenuOptions.map((route) => (
                <Grid item key={`${route}${route.selectedIndex}`}>
                  <ListItem
                    key={`${route}${route.selectedIndex}`}
                    onClick={() => {
                      setOpenDrawer(false);
                      setSelectedIndex(route.selectedIndex);
                    }}
                    divider
                    button
                    component={Link}
                    to={route.link}
                    selected={
                      selectedIndex === route.selectedIndex &&
                      selectedTab === 'dashboardTab' &&
                      window.location.pathname !== '/dashboard'
                    }
                    {...a11yProps(0)}
                  >
                    <ListItemText
                      sx={{
                        fontFamily: 'Raleway',
                        textTransform: 'none',
                        fontWeight: 700,
                        fontSize: '1.0rem',
                        color: 'white',
                        opacity: 0.7,
                      }}
                      disableTypography
                      // onClick={() => {
                      //   setOpenDrawer(false);
                      //   setSelectedTab(route.activeIndex);
                      // }}
                    >
                      {route.name}
                      <br />
                      <span style={{ fontSize: '0.75rem' }}>Dashboard</span>
                    </ListItemText>
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    },
    [selectedTab, selectedIndex, dashboardMenuOptions, notiheight]
  );

  const analyticsExpansion = useCallback(
    (route) => {
      return (
        <Accordion
          elevation={0}
          key={route.name}
          sx={{
            ...AccordianSX,
          }}
        >
          <AccordionSummary
            sx={{
              ...AccordianSummarySX,
            }}
            expandIcon={<ExpandMoreIcon color='secondary' />}
          >
            <ListItemText
              disableTypography
              onClick={() => {
                // setOpenDrawer(false);
                setSelectedTab(route.activeIndex);
              }}
            >
              <Link
                style={{
                  opacity: selectedTab === 'analyticsTab' ? 1.0 : null,
                  ...theme.typography.tab,
                  color: 'white',
                  opacity: 0.7,

                  textDecoration: 'none',
                }}
                underline='none'
                to={route.link}
              >
                {route.name}
              </Link>
            </ListItemText>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: 0,
              backgroundColor: theme.palette.primary.light,
              zIndex: 3000,
            }}
          >
            <Grid container direction='column'>
              {analyticsMenuOptions.map((route) => (
                <Grid item key={`${route}${route.selectedIndex}`}>
                  <ListItem
                    key={`${route}${route.selectedIndex}`}
                    onClick={() => {
                      setOpenDrawer(false);
                      setSelectedIndex(route.selectedIndex);
                    }}
                    divider
                    button
                    component={Link}
                    to={route.link}
                    selected={
                      selectedIndex === route.selectedIndex &&
                      selectedTab === 'analyticsTab' &&
                      window.location.pathname !== '/analytictools'
                    }
                    {...a11yProps(0)}
                  >
                    <ListItemText
                      sx={{
                        fontFamily: 'Raleway',
                        textTransform: 'none',
                        fontWeight: 700,
                        fontSize: '1.0rem',
                        color: 'white',
                        opacity: 0.7,
                      }}
                      disableTypography
                      // onClick={() => {
                      //   setOpenDrawer(false);
                      //   setSelectedTab(route.activeIndex);
                      // }}
                    >
                      {route.name}
                      {/* {route.name
                    .split(' ')
                    .filter((word) => word !== 'Development').join(' ')} */}
                      {/* <Link hfref={route.link} color='inherit'>
                    {route.name}
                  </Link> */}
                      <br />
                      <span style={{ fontSize: '0.75rem' }}>Analytics</span>
                    </ListItemText>
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    },
    [, analyticsMenuOptions, selectedIndex, selectedTab, notiheight]
  );

  // do not delete is removed find it in the previous git branch

  const renderAuthDrawer = (authRoutes) =>
    authRoutes.map((route, index) =>
      route.name === 'Dashboard' ? (
        dashboardExpansion(route)
      ) : route.name === 'Analytic Tools' ? (
        analyticsExpansion(route)
      ) : (
        // route.name === 'My Account' ? (
        //   accountExpansion(route)
        // ) :
        <ListItemDrawerItemSelected
          // style={{ height: '3rem', '&.Mui-selected': { color: ahriWhite } }}
          key={index}
          onClick={
            route.name === 'Logout'
              ? () => {
                  setOpenDrawer(false);
                  setSelectedTab(route.activeIndex);
                  // logoutUser();
                }
              : () => {
                  setOpenDrawer(false);
                  setSelectedTab(route.activeIndex);
                }
          }
          divider
          button
          component={Link}
          to={route.link}
          selected={selectedTab === route.activeIndex}
          // sx={{  height: '3rem', ''&:hover'': { opacity:1.0, color: ahriWhite }, }}
          {...a11yProps(0)}
        >
          <DrawerItem disableTypography>{route.name}</DrawerItem>
        </ListItemDrawerItemSelected>
      )
    );

  const authDrawer = useMemo(
    () => (
      <Fragment>
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}
          onOpen={() => {
            setOpenDrawer(true);
          }}
          PaperProps={{
            sx: {
              backgroundColor: ahriBlue,
            },
          }}
        >
          <div
            // className={classes.drawerMargin}

            style={{ marginTop: 65 + notiheight }}
          />
          <List disablePadding>{renderAuthDrawer(authRoutes)}</List>
        </SwipeableDrawer>
        <DrawerIconContainer
          onClick={() => setOpenDrawer(!openDrawer)}
          disableRipple
        >
          <DrawerIcon />
        </DrawerIconContainer>
      </Fragment>
    ),
    [openDrawer, authRoutes, selectedTab, iOS, selectedTab, notiheight]
  );

  const guestDrawer = useMemo(
    () => (
      <Fragment>
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}
          onOpen={() => {
            setOpenDrawer(true);
          }}
          PaperProps={{
            sx: {
              backgroundColor: ahriBlue,
            },
          }}
        >
          <div style={{ marginTop: 65 + notiheight }} />
          <List disablePadding>
            {guestRoutes.map((route, index) => (
              <ListItemDrawerItemSelected
                key={index}
                onClick={() => {
                  setOpenDrawer(false);
                  setSelectedTab(route.activeIndex);
                }}
                // sx={{
                //   '&.Mui-selected': {opacity: 1.0,
                //   '& .MuiListItemText-root': {
                //     opacity: 1.0,
                //   },}
                // }}
                sx={{  height: '3rem', '&.Mui-selected': { opacity:1.0, color: ahriWhite }, }}
                divider
                button
                component={Link}
                to={route.link}
                selected={selectedTab === route.activeIndex}
                {...a11yProps(0)}
              >
                <DrawerItem disableTypography>{route.name}</DrawerItem>
              </ListItemDrawerItemSelected>
            ))}
          </List>
        </SwipeableDrawer>
        <DrawerIconContainer
          onClick={() => setOpenDrawer(!openDrawer)}
          disableRipple
        >
          <DrawerIcon />
        </DrawerIconContainer>
      </Fragment>
    ),
    [openDrawer, guestRoutes, notiheight]
  );

  const popoverMenu = useCallback(
    (options) => {
      return options.map((option, i) => (
        <ListItem
          // sx={{
          //   flexDirection: 'column', // Set flexDirection to 'column' for a vertical layout
          // }}
          key={`${option}-${i}`}
          component={Link}
          to={option.link}
          onClick={(event) => {
            handleMenuItemClick(event, i);
            handleClose();
            setSelectedTab(option.activeTab);
          }}
          sx={{
            ...menuItemSX,
            '&.Mui-selected': {
              opacity: 1.0,
              '& .MuiListItemText-root': {
                opacity: 1.0,
              },
            },
          }}
          // style={{
          //   opacity: selectedTab === option.activeIndex ? 1.0 : null,
          // }}
          selected={
            option.selectedIndex === selectedIndex &&
            selectedTab === option.activeIndex
          } // when at 'Dashboard' I do not want to see something here highlighted
        >
          {option.name}
        </ListItem>
      ));
    },
    [selectedTab, selectedIndex, notiheight]
  );

  const [barvisibility, setBarVisibility] = useState(true);
  const [filterVisibility, setFilterVisibility] = useState(false);
  const getCurrentDate = () => {
    var today = new Date();
    var date =
      today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
    return date;
  };

  const findDiff = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const diffTime = Math.abs(d2 - d1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  };

  const saveNewsDecision = () => {
    const today = getCurrentDate();
    localStorage.setItem('newsDecision', today);
    setBarVisibility(false);
    props.setNewsValue(true);
  };

  let participantList = [];
  const [customParticipant, setCustomParticipant] = useState('');

  let [filterHeight, setFilterHeight] = useState(80);
  useEffect(() => {
    const acceptedDate = localStorage.getItem('newsDecision');
    const today = getCurrentDate();
    setBarVisibility(findDiff(acceptedDate, today) > 1);
    props.setNewsValue(findDiff(acceptedDate, today) > 1);

    if (
      selectedTab === 'dashboardTab' ||
      (selectedTab === 'analyticsTab' &&
        !indexNoFilterBarDisplayInAnalyticsTab.includes(selectedIndex)) ||
      selectedIndex === 1
    ) {
      setFilterVisibility(true);
    } else {
      setFilterVisibility(false);
    }

    if (selectedTab === 'dashboardTab') {
      // setFilterState({});
      // if (filterDashboard.selectedParent != null) {
      if (
        filterDashboard.selectedParent != null ||
        filterDashboard.selectedParticipant != null
      ) {
        updateFilterState(filterDashboard);
        // }
      } else {
        setFilterState({
          selectedParent: 'All participants',
          selectedProgram: 'all programs',
          selectedYear: filterDashboard.selectedYear,
        });
      }
    }
    if (selectedTab === 'analyticsTab' && selectedIndex !== 1) {
      // setFilterState({});
      // if (filterDataAnalysis.selectedParent != null) {
      if (
        filterDataAnalysis.selectedParent != null ||
        filterDataAnalysis.selectedParticipant != null
      ) {
        updateFilterState(filterDataAnalysis);
        // }
      } else {
        setFilterState({
          selectedParent: 'All participants',
          selectedProgram: 'program not selected',
          selectedYear: null,
        });
      }
    }

    if (selectedIndex === 1) {
      setFilterState({});
      if (filterDrillDown.selectedParent != null) {
        if (
          filterDrillDown.selectedParent != null ||
          filterDrillDown.selectedParticipant != null
        ) {
          updateFilterState(filterDrillDown);
        }
      } else {
        setFilterState({
          selectedParent: 'All participants',
          selectedProgram: 'All programs',
          selectedYear: filterDashboard.selectedYear,
        });
      }
    }
    if (barvisibility) {
      setFilterHeight(notiheight + 65);
    } else {
      setFilterHeight(63);
    }
  }, [
    filterDrillDown,
    filterDashboard,
    filterDataAnalysis,
    selectedTab,
    notiheight,
    barvisibility,
    filterHeight,
    customParticipant,
    selectedIndex,
  ]);

  const updateFilterState = (filterData) => {
    if (Array.isArray(filterData.selectedParticipant)) {
      if (filterData.selectedParticipant > n_comp_displayed) {
        setCustomParticipant(
          filterData.selectedParticipant
            .slice(0, n_comp_displayed)
            .map((cmp) => cmp.slice(0, n_chr_comp_sliced))
            .join(' , ') + ', etc.'
        );
      } else if (filterData.selectedParticipant.length >= 2) {
        setCustomParticipant(
          filterData.selectedParticipant
            .map((cmp) => cmp.slice(0, n_chr_comp_sliced))
            .join(' , ')
        );
      } else {
        setCustomParticipant(filterData.selectedParticipant);
      }
      filterData.selectedParticipant.slice(0, n_comp_displayed).map((cmp) => {
        if (filterData.selectedParticipant.length > 1) {
          participantList.push(cmp.slice(0, n_chr_comp_sliced));
          if (filterData.selectedParticipant.length > n_comp_displayed) {
            setCustomParticipant(participantList.join(' , ') + ', etc.');
          } else {
            setCustomParticipant(participantList.join(' , '));
          }
        } else {
          setCustomParticipant(cmp);
        }
      });
    } else {
      setCustomParticipant(filterData.selectedParticipant);
    }

    setFilterState({
      selectedParent: customParticipant,
      selectedProgram:
        selectedTab === 'analyticsTab' && selectedIndex !== 1
          ? !!filterData.selectedProgram
            ? filterData.selectedProgram
            : 'program not selected'
          : 'all programs',
      selectedYear:
        selectedTab === 'dashboardTab' ||
        (selectedTab === 'analyticsTab' && selectedIndex === 1)
          ? filterData.selectedYear
          : null,
    });
  };

  return (
    <Fragment>
      <ElevationScroll {...props}>
        <div>
          {' '}
          {barvisibility && (
            <InfoBar
              style={{ zIndex: theme.zIndex.modal + 1 }}
              barVisibility={barvisibility}
              appBarPosition='fixed'
              appBarStyle={{
                zIndex: theme.zIndex.modal + 2,
                backgroundColor: theme.palette.common.black,
              }}
              toolBarStyle={{ minHeight: 20, color: 'white' }}
              typographyVariant={matchesSM ? 'caption' : 'body2'}
              typographyStyle={{
                flexGrow: 1,
                color: 'white',
              }}
              text='topbartext'
              buttonSize='small'
              onClickFun={saveNewsDecision}
              buttonStyle={{
                marginRight: matchesMD ? '3px' : '0px',
                marginTop: matchesMD ? '4px' : '4px',
                marginBottom: matchesMD ? '4px' : '4px',
                marginLeft: matchesMD ? '0px' : '10rem',
              }}
              buttonText='Close'
              buttonVariant='button'
              buttonOutline='outlined'
              buttonIcon={true}
              topBar={true}
            ></InfoBar>
          )}
          <AppBar position='fixed' elevation={0} style={{ backgroundColor: ahriBlue}}>
            <ToolBar
              disableGutters={false}
              style={{
                marginTop:
                  barvisibility && matchesSM && !marginAvailable
                    ? notiheight
                    : !barvisibility || marginAvailable
                    ? 0
                    : notiheight,
              }}
            >
              <LogoContainer
                component={Link}
                to='/'
                disableRipple={true}
                onClick={() => setSelectedTab(0)}
              >
                <Logo alt='AHRI logo' src={logo}></Logo>
              </LogoContainer>
              {/* <Typography variant='h3'>{props.title}</Typography> */}
              {loading && !matches ? (
                <TabTransition>
                  <Typography
                    variant='h5'
                    style={{ fontSize: '1.2rem', color: 'white' }}
                  >
                    Loading User Page Layout...
                  </Typography>
                </TabTransition>
              ) : matches ? (
                isAuthenticated && user !== null ? (
                  authDrawer
                ) : (
                  guestDrawer
                )
              ) : isAuthenticated && user !== null ? (
                authLink
              ) : (
                guestLink
              )}

              {isAuthenticated && user !== null && (
                <>
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement='bottom-start'
                    // role={undefined}
                    transition
                    style={{ zIndex: 1310 }}
                    // disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: 'top left',

                          // placement === 'bottom'
                          //   ? 'center top'
                          //   : 'center bottom',
                        }}
                      >
                        <Paper
                          style={{
                            backgroundColor: theme.palette.common.blue,
                            color: 'white',
                            borderRadius: '0px',
                            zIndex: 1310,
                          }}
                          elevation={0}
                        >
                          <ClickAwayListener
                            touchEvent='true'
                            onClickAway={handleClose}
                          >
                            <MenuList
                              onMouseEnter={onMenuHover}
                              onMouseLeave={handleClose}
                              disablePadding
                              autoFocusItem={openMenu}
                              id={
                                anchorEl !== null &&
                                anchorEl.pathname.replace('/', '')
                              }
                              onKeyDown={handleListKeyDown}
                            >
                              {anchorEl !== null &&
                                anchorEl.pathname === '/dashboard' &&
                                popoverMenu(dashboardMenuOptions)}
                              {anchorEl !== null &&
                                anchorEl.pathname === '/analytictools' &&
                                popoverMenu(analyticsMenuOptions)}
                              {anchorEl !== null &&
                                anchorEl.pathname === '/account' &&
                                popoverMenu(accountMenuOptions)}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              )}
            </ToolBar>
          </AppBar>
          {filterState.selectedParent != null && filterVisibility && (
            <div
              style={{
                marginTop: filterHeight,
                backgroundColor: theme.palette.common.black,
                opacity: 0.7,
                width: '100%',
                position: 'fixed',
                zIndex: 1309, // header expanded menu z: 1310
              }}
            >
              <FilterBar
                selectedParent={filterState.selectedParent}
                selectedProgram={filterState.selectedProgram}
                selectedYear={filterState.selectedYear}
                hideYear={
                  yearHiddenIndex.includes(selectedIndex) ? true : false
                } // need to filter out selection info because it needs all years
              />
            </div>
          )}
        </div>
      </ElevationScroll>
      {isAuthenticated && uniqueServiceRoutes.includes(location.pathname) && (
        <Grid
          container
          item
          justifyContent='center'
          style={{ marginBottom: filterState.selectedParent ? '2rem' : '0rem' }}
        >
          <div
            style={{
              position: 'fixed',
              zIndex: 1330,
              // marginTop:
              //   filterState.selectedParent != null
              //     ? notiheight + 89
              //     : notiheight + 63,
              marginTop: filterVisibility ? filterHeight + 25 : filterHeight,
            }}
          >
            <Typography
              variant='body1'
              // color='danger'
              // making on top of all components
              style={{ fontSize: '1rem', color: '#C15050' }}
            >
              Confidential and for Internal Use Only.{' '}
              <DisclaimerButton
                size='small'
                variant='outlined'
                // color='secondary'

                onClick={() => setDisclaimerOpen(true)}
              >
                <Typography
                  variant='body1'
                  // color='danger'
                  style={{ fontSize: '0.8rem', color: '#fff' }}
                >
                  {matchesxs ? 'Disclaimer' : 'Open Disclaimer'}
                </Typography>
              </DisclaimerButton>
            </Typography>
          </div>
        </Grid>
      )}
      <Toolbar id='back-to-top-anchor' />
      {/* !uniqueServiceRoutes.includes(location.pathname) && */}
      {isAuthenticated && !loading && (
        <HeaderGuide ceoAccessTest={ceoAccessTest} />
      )}
      {/* <ToolbarMargin/> */}
      {/* <div className={classes.toolbarMargin}></div> */}
      {/* <ScrollTop {...props}>
        <Fab
          size='small'
          aria-label='scroll back to top'
          variant='round'
          style={{ backgroundColor: ahriOrange }}
        >
          <KeyboardArrowUpIcon style={{ color: '#fff' }} />
        </Fab>
      </ScrollTop> */}
      {disclaimerOpen && <Disclaimer setDisclaimerOpen={setDisclaimerOpen} />}
    </Fragment>
  );
};

const mapStatetoProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    selectedTab: state.page.selectedTab,
    getNews: state.page.getNews,
    selectedIndex: state.page.selectedIndex,
    loading: state.auth.loading,
    pageAccess: state.auth.pageAccess,
    marginAvailable: state.page.marginAvailable,
    newsAvailable: state.page.newsAvailable,
    notiheight: state.page.notiheight,
    filterDashboard: state.filter.dashboard,
    filterDataAnalysis: state.filter.vizTest,
    filterDrillDown: state.filter.drilldown,
  };
};
export default React.memo(
  connect(mapStatetoProps, {
    logout,
    loadUser,
    setSelectedTab,
    setSelectedIndex,
    setNewsValue,
    setMarginValue,
  })(Header)
);
