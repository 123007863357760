import React, { useState, useEffect, Fragment } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import './cookieStyles.css';
import { useTheme } from '@mui/material/styles';
// import { connect } from 'react-redux';
// import { setNewsValue } from '../../../actions/pageActions';

const CookieBar = () => {
  const theme = useTheme();
  const DAYS_IN_6_MONTHS = 182;

  const [barvisibility, setBarVisibility] = useState(true);
  const matchesMD = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  const matchesXS = useMediaQuery(theme.breakpoints.down('sm'));

  const getCurrentDate = () => {
    var today = new Date();
    var date =
      today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
    return date;
  };

  const findDiff = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const diffTime = Math.abs(d2 - d1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  };

  const saveDecision = () => {
    const today = getCurrentDate();
    localStorage.setItem('cookieDecision', today);
    setBarVisibility(false);
  };

  useEffect(() => {
    const acceptedDate = localStorage.getItem('cookieDecision');
    const today = getCurrentDate();
    setBarVisibility(findDiff(acceptedDate, today) >= DAYS_IN_6_MONTHS);
  });
  return (
    <Fragment>
      {barvisibility && (
        <AppBar
          position='sticky'
          style={{
            top: 'auto',
            bottom: 0,
            backgroundColor: theme.palette.common.black,
          }}
          elevation={0}
        >
          <Toolbar style={{ marginTop: matchesSM ? 4 : 0 }}>
            <Grid
              container
              direction={matchesSM ? 'column' : 'row'}
              justifyContent='center'
              // justify='center'
              alignItems='center'
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                style={{ marginBottom: matchesMD ? '3px' : '0px' }}
              >
                <div
                  className='textAnimi'
                  style={{
                    textAlign: matchesSM ? 'center' : 'left',
                    color: 'white',
                    marginRight: matchesMD ? '3px' : 0,
                  }}
                >
                  {' '}
                  {}
                  <p>
                    By clicking “I Agree“, you agree to our Analytics App cookie
                    usage.
                  </p>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                style={{
                  textAlign: 'center',
                  marginBottom: matchesSM ? 10 : 0,
                }}
              >
                <Button
                  color='inherit'
                  size={matchesSM ? 'small' : 'medium'}
                  variant='outlined'
                  onClick={saveDecision}
                  style={{
                    marginRight: matchesXS ? '8rem' : '3rem',
                    marginTop: matchesMD ? 0 : 0,
                  }}
                >
                  <Typography variant='h7' className='textAnimi'>
                    I Agree
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
    </Fragment>
  );
};

export default CookieBar;
