export const dashboardMenuOptions = [
  // {
  //   name: "Analytic Tools",
  //   // link: "/analytictools",
  //   activeIndex: "analyticsTab",
  //   selectedIndex: 0,
  // },
  // {
  //   name: 'Test Progress',
  //   link: '/testprogress',
  //   activeIndex: 'dashboardTab',
  //   selectedIndex: 11,
  // },
  {
    name: 'Test Failure Rate Analysis',
    link: '/testresults',
    activeIndex: 'dashboardTab',
    selectedIndex: 12,
  },
  {
    name: 'Selection Information',
    link: '/selectionInfo',
    activeIndex: 'dashboardTab',
    selectedIndex: 15,
  },
  {
    name: 'Rerates/Failure Decisions',
    link: '/decisions',
    activeIndex: 'dashboardTab',
    selectedIndex: 14,
  },
  {
    name: 'Performance/Risk Analysis',
    link: '/performanceAnalysis',
    activeIndex: 'dashboardTab',
    selectedIndex: 13,
  },
];
