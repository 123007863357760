export const analyticsMenuOptionsLoggedIn = [
  // {
  //   name: "Analytic Tools",
  //   // link: "/analytictools",
  //   activeIndex: "analyticsTab",
  //   selectedIndex: 0,
  // },
  {
    name: 'Audit Test DB',
    link: '/certDB',
    activeIndex: 'analyticsTab',
    selectedIndex: 1,
  },
  {
    name: 'Data Analysis/Screening',
    link: '/scatter',
    activeIndex: 'analyticsTab',
    selectedIndex: 2,
  },
  // {
  //   name: 'Download Center',
  //   link: '/downloads',
  //   activeIndex: 'analyticsTab',
  //   selectedIndex: 3,
  // },
  // {
  //   name: 'Predictive Analysis',
  //   link: '/predictiveanalysis',
  //   activeIndex: 'analyticsTab',
  //   selectedIndex: 3,
  // },
];
