import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
} from '@mui/material';
import React from 'react';
import { TooltipRenderProps } from 'react-joyride';

export const Tooltip = React.memo(
  ({
    continuous,
    index,
    isLastStep,
    step,
    backProps,
    primaryProps,
    skipProps,
    tooltipProps,
  }: TooltipRenderProps) => (
    <Card {...tooltipProps}>
      <CardContent>
        <Typography gutterBottom variant='h5' component='h2'>
          {step.title}
        </Typography>
        <Typography variant='body2'>{step.content}</Typography>
      </CardContent>
      <CardActions>
        {continuous && index > 0 && (
          <Button color='secondary' variant='text' {...backProps}>
            Back
          </Button>
        )}
        <Button color='secondary' variant='text' {...skipProps}>
          Skip
        </Button>
        {
          <Button color='primary' variant='text' {...primaryProps}>
            {continuous ? 'Next' : 'Close'}
          </Button>
        }
      </CardActions>
    </Card>
  )
);
