import {setAuthTokenToNewInstance} from '../utils/setAuthToken'; //setAuthToken
import axios from 'axios';
import { Dispatch } from 'redux'; // to investigate the dispaych type
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_PENDING,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  SET_LOADING,
} from './types';

import {
  loadUserCompanyAccess,
} from "../graphql/requests";

// when 'proxy' in package.json is not working properly..
// const URI = process.env.NODE_ENV === 'development'
//   ? "http://localhost:9000"
//   : "https://portal.ahrianalytics.org";

// const URI = "http://10.1.0.11:9000/"
// const URI = 'http://10.6.16.5:9000/';
// const URI = "http://ahritestetl1.eastus.cloudapp.azure.com:9000"
const URI = "https://portal.ahrianalytics.org";
// const URI = "http://localhost:9000/"; // DEV
const instance = axios.create({baseURL: URI})

export const setLoading = () => {
  return {
    type: SET_LOADING,
  };
};

export const loadUser = () => async (dispatch: Dispatch) => {
  if (localStorage.token) {
    setAuthTokenToNewInstance(localStorage.token, instance);
  }
  try {
    // setLoading();
    const restRoute = URI.includes('9000') ? '/api/auth' : '/api/api/auth'
    // const res = await instance.get('/api/api/auth');
    const res = await instance.get(restRoute);
    res.data.company = res.data.company
        .map((each:string) => each.trim())
        .filter((each: any, idx: number) => 
          res.data.hasCeoAccess[idx])
    res.data.hasCeoAccess = res.data.hasCeoAccess
      .filter((each:Boolean) => each)
      try {      
        const {getUserCompanyAccess} = await loadUserCompanyAccess(res.data.username)
        // taking only ones with CEOAccess
        // console.log('getUserCompanyAccess', getUserCompanyAccess)
        
        const additionalAccess = getUserCompanyAccess
        .filter((each: any, idx: number) => 
        each.companyAccess.access || res.data.company.includes(each.companyAccess.orgName))
        .map((each: any) => each.companyAccess.orgName)
        .map((each:string) => each.trim())
        
        const additionalCEOAccess = []
        for (var i = 0; i < additionalAccess.length; i++) {
          additionalCEOAccess.push(false);
        }
        res.data.hasCeoAccess.push(...additionalCEOAccess)
        
        res.data.company.push(...additionalAccess);
        res.data.programAccess = getUserCompanyAccess[0] && getUserCompanyAccess[0].programAccess
        res.data.pageAccess = getUserCompanyAccess[0] && getUserCompanyAccess[0].pageAccess
        
    } catch (e) {
      console.error(e)
    }
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

interface RegistrationFormData {
  username: string;
  password: string;
  email: string;
}

// Register User (runs loadUser after success)
export const register = (formData: RegistrationFormData) => async (
  dispatch: Dispatch
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const restRoute = URI.includes('9000') ? '/api/auth' : '/api/api/auth'
    // const res = await instance.post('/api/api/users', formData, config);
    const res = await instance.post(restRoute, formData, config);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });

    loadUser();
  } catch (error:any) {
    dispatch({
      type: REGISTER_FAIL,
      payload: error.response.data.msg,
    });
    return null;
  }
};

interface LoginFormData {
  username: string;
  password: string;
}

// Login User
export const login = (formData: LoginFormData) => async (
  dispatch: Dispatch
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // IMPORTANT!!!!!!!!!!!!!!
  // This is for the production
  try {
    dispatch({
      type: LOGIN_PENDING,
    });
    const restRoute = URI.includes('9000') ? '/api/auth' : '/api/api/auth'
    // const res = await instance.post('/api/api/auth', formData, config); // k8s cluster has another /api route
    const res = await instance.post(restRoute, formData, config); // k8s cluster has another /api route // dev route
    if (res.data && res.data.token) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: LOGIN_FAIL,
        payload: res && res.data.msg ? res.data.msg : 'Credential not matching',
      });
    }
  } catch (error:any) {
    console.error(error);
    dispatch({
      type: LOGIN_FAIL,
      payload: error.response ? error.response.data.msg : error,
    });
  }
};

// Logout
export const logout = () => {
  return { type: LOGOUT };
};

// Clear Errors
export const clearErrors = () => {
  return { type: CLEAR_ERRORS };
};
